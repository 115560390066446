import item0 from '!file-loader!./favicon-16x16.png';
import item1 from '!file-loader!./favicon-32x32.png';
import item2 from '!file-loader!./favicon-48x48.png';
import item3 from '!file-loader!./favicon.ico';
import item4 from '!file-loader!./apple-touch-icon-57x57.png';
import item5 from '!file-loader!./apple-touch-icon-60x60.png';
import item6 from '!file-loader!./apple-touch-icon-72x72.png';
import item7 from '!file-loader!./apple-touch-icon-76x76.png';
import item8 from '!file-loader!./apple-touch-icon-114x114.png';
import item9 from '!file-loader!./apple-touch-icon-120x120.png';
import item10 from '!file-loader!./apple-touch-icon-144x144.png';
import item11 from '!file-loader!./apple-touch-icon-152x152.png';
import item12 from '!file-loader!./apple-touch-icon-167x167.png';
import item13 from '!file-loader!./apple-touch-icon-180x180.png';
import item14 from '!file-loader!./apple-touch-icon-1024x1024.png';
import item15 from '!file-loader!./mstile-144x144.png';
import item16 from '!file-loader!./manifest.json';
import item17 from '!file-loader!./browserconfig.xml';

const favicons = `<link rel='shortcut icon' href='${item3}' /><link rel='icon' type='image/png' sizes='16x16' href='${item0}' /><link rel='icon' type='image/png' sizes='32x32' href='${item1}' /><link rel='icon' type='image/png' sizes='48x48' href='${item2}' /><link rel='manifest' href='${item16}' /><meta name='mobile-web-app-capable' content='yes' /><meta name='theme-color' content='#fff' /><meta name='application-name' /><link rel='apple-touch-icon' sizes='57x57' href='${item4}' /><link rel='apple-touch-icon' sizes='60x60' href='${item5}' /><link rel='apple-touch-icon' sizes='72x72' href='${item6}' /><link rel='apple-touch-icon' sizes='76x76' href='${item7}' /><link rel='apple-touch-icon' sizes='114x114' href='${item8}' /><link rel='apple-touch-icon' sizes='120x120' href='${item9}' /><link rel='apple-touch-icon' sizes='144x144' href='${item10}' /><link rel='apple-touch-icon' sizes='152x152' href='${item11}' /><link rel='apple-touch-icon' sizes='167x167' href='${item12}' /><link rel='apple-touch-icon' sizes='180x180' href='${item13}' /><link rel='apple-touch-icon' sizes='1024x1024' href='${item14}' /><meta name='apple-mobile-web-app-capable' content='yes' /><meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' /><meta name='apple-mobile-web-app-title' /><meta name='msapplication-TileColor' content='#fff' /><meta name='msapplication-TileImage' content='${item15}' /><meta name='msapplication-config' content='${item17}' />`;

export default favicons;
