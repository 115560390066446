export default {
  app: {
    common: {
      backToMenu: 'Zpět do menu',
      exploreButton: 'Prozkoumat',
      homepageBreadcrumb: 'Domů',
      language: 'Jazyk',
      loadMore: 'Více',
      opensInNewWindow: 'Otevřít v novém okně',
      pageTypes: {
        article: 'Článek',
        category: 'Kategorie',
        event: 'Událost',
        page: 'Stránka',
        place: 'Místo',
        region: 'Region',
      },
      readMore: 'Číst více…',
      selectLanguage: 'Vybrat jazyk',
      showMore: 'Zobrazit více',
      source: 'Zdroj',
      translationVoting: 'Jak se vám líbí tento překlad?',
      translationVotingSuccess: 'Děkujeme za Vaši zpětnou vazbu!',
    },
  },
};
