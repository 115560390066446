import { Action, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';

declare global {
  interface Window {
    FB: any;
  }
}

@Module({
  name: 'FacebookSdkModule',
  stateFactory: true,
  namespaced: true,
})
export default class FacebookSdkModule extends AbstractModule {
  public sdkShouldLoad: boolean = false;
  public sdkLoaded: boolean = false;

  public sdkPromise: Promise<boolean> | null = null;

  @Action({ rawError: true })
  public loadSdk(): Promise<boolean> {
    if (this.sdkPromise) {
      return this.sdkPromise;
    }
    const promise = new Promise<boolean>((resolve) => {
      if (!this.sdkShouldLoad) {
        resolve(false);
        return;
      }
      resolve(true);
    })
      .then((result) => {
        if (!result) {
          return false;
        }
        if (this.sdkLoaded) {
          if (
            typeof window !== 'undefined' &&
            typeof window.FB !== 'undefined' &&
            typeof window.FB.XFBML !== 'undefined' &&
            typeof window.FB.XFBML.parse !== 'undefined'
          ) {
            window.FB.XFBML.parse();
          }
          return true;
        }
        if (typeof document === 'undefined') {
          return false;
        }
        const lastScript = document.getElementsByTagName('script');
        if (lastScript.length < 1) {
          return false;
        }
        const lastScriptElement = lastScript[lastScript.length - 1];
        if (!lastScriptElement || !lastScriptElement.parentNode) {
          return false;
        }

        const sdkScript = document.createElement('script');
        sdkScript.async = true;
        sdkScript.defer = true;
        sdkScript.src =
          'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
        lastScriptElement.parentNode.insertBefore(sdkScript, lastScriptElement);
        this.setSdkLoaded(true);
        return true;
      })
      .finally(() => {
        this.setSdkPromise(null);
      });
    this.setSdkPromise(promise);
    return promise;
  }

  @Mutation
  public setSdkShouldLoad(state: boolean) {
    this.sdkShouldLoad = state;
  }

  @Mutation
  protected setSdkLoaded(state: boolean) {
    this.sdkLoaded = state;
  }

  @Mutation
  protected setSdkPromise(promise: Promise<boolean> | null) {
    this.sdkPromise = promise;
  }
}
