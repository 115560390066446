import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class PauseIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--pause'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path d='M7.6 17.2c0 1.5-1.2 2.8-2.8 2.8A2.7 2.7 0 012 17.2V2.8C2 1.2 3.2 0 4.8 0c1.5 0 2.8 1.2 2.8 2.8v14.4zM18 17.2a2.8 2.8 0 11-5.6 0V2.8c0-1.5 1.2-2.8 2.8-2.8C16.8 0 18 1.2 18 2.8v14.4z' />
        </svg>
      </i>
    );
  }
}
