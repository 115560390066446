import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class SearchIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--search'>
        <svg
          viewBox='0 0 32 32'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M22.785 24.106a13.324 13.324 0 01-9.466 3.19 13.35 13.35 0 01-4.507-1.062 13.418 13.418 0 01-3.594-2.286 13.476 13.476 0 01-2.85-3.563 13.306 13.306 0 01-1.253-3.184 13.473 13.473 0 01-.221-5.464 13.329 13.329 0 013.74-7.256 13.439 13.439 0 014.555-2.992 13.394 13.394 0 018.165-.527c1.109.278 2.182.701 3.184 1.253a13.443 13.443 0 015.849 6.444 13.35 13.35 0 011.062 4.507 13.324 13.324 0 01-3.19 9.466l-.028.032 6.763 6.762-1.415 1.415-6.762-6.763-.032.028zM14.092 2.561c6.28 0 11.379 5.098 11.379 11.378s-5.099 11.379-11.379 11.379c-6.28 0-11.378-5.099-11.378-11.379 0-6.28 5.098-11.378 11.378-11.378z'
            fill='currentColor'
          />
        </svg>
      </i>
    );
  }
}
