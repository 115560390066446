import { Component, Prop } from 'vue-property-decorator';

import { BasePage } from '~/app/core/apiClient/api';
import { VueComponent } from '~/utils/vue-component';
import ImageFilterTypeEnum = BasePage.ImageFilterTypeEnum;

import { ImageFilterMixinInterface } from './';

@Component
export default class ImageFilterMixin
  extends VueComponent<ImageFilterMixinInterface>
  implements ImageFilterMixinInterface {
  @Prop()
  public imageFilter!: ImageFilterTypeEnum;
}
