import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class InstagramWhiteIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--social czt-icon--instagram-white'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 30 30'
        >
          <defs />
          <path
            fill='#FFFFFF'
            d='M5.562 2.606h18.876c1.792 0 3.259 1.327 3.259 3.259V24.74c0 1.932-1.467 3.259-3.26 3.259H5.563c-1.793 0-3.26-1.327-3.26-3.26V5.866c0-1.932 1.467-3.26 3.26-3.26zm15.24 2.821c-.63 0-1.144.514-1.144 1.143v2.737c0 .629.514 1.143 1.143 1.143h2.87c.63 0 1.144-.514 1.144-1.143V6.57c0-.629-.515-1.143-1.143-1.143h-2.87zm4.025 7.918h-2.235c.211.69.325 1.421.325 2.178 0 4.224-3.534 7.648-7.893 7.648s-7.893-3.424-7.893-7.648c0-.757.114-1.488.326-2.178H5.125v10.727c0 .555.454 1.01 1.01 1.01h17.683c.555 0 1.01-.455 1.01-1.01V13.345zm-9.803-3.039c-2.817 0-5.1 2.212-5.1 4.942s2.283 4.941 5.1 4.941c2.816 0 5.1-2.212 5.1-4.941 0-2.73-2.283-4.942-5.1-4.942z'
          />
        </svg>
      </i>
    );
  }
}
