export enum ROBOTS {
  INDEX_FOLLOW = 'index,follow',
  INDEX_NOFOLLOW = 'index,nofollow',
  NOINDEX = 'noindex',
  NOINDEX_NOFOLLOW = 'none',
}

export enum TYPE {
  ARTICLE = 'article',
  WEBSITE = 'website',
}
export default function(
  title: string,
  description: string,
  canonicalUrl: string,
  type: TYPE = TYPE.ARTICLE,
  image?: string,
  robots: ROBOTS = ROBOTS.INDEX_FOLLOW
): string {
  let content = `
<meta name="robots" content="${robots}" />
<link rel="canonical" href="${canonicalUrl}" />
${canonicalUrl ? `<link rel="canonical" href="${canonicalUrl}" />` : ''}
${canonicalUrl ? `<meta property="og:url" content="${canonicalUrl}" />` : ''}
<meta property="og:type" content="${type}" />
<meta name="author" content="CzechTourism" />
<meta name="twitter:creator" content="CzechTourism" />
<meta name="twitter:title" content="${title}" />
<meta property="og:title" content="${title}" />
<meta name="description" content="${description}" />
<meta name="twitter:description" content="${description}" />
<meta property="og:description" content="${description}" />
`;

  if (image) {
    image = `${image.split('?')[0]}?width=768`;
    content += '<meta name="twitter:card" content="summary_large_image">\n';
    content += `<meta name="twitter:image" content="${image}">\n`;
    content += `<meta property="og:image" content="${image}">\n`;
  } else {
    content += '<meta name="twitter:card" content="summary">';
  }

  return content;
}
