import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { HtmlContentWidget } from '~/app/core/apiClient/api';
import { Headline, Image } from '~/components/atoms';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { Align } from '~/components/atoms/headline/Headline';
import { CztImage } from '~/utils/atoms/image';
import { CztWidgets } from '~/utils/views/widgets';
import ThemeStyleEnum = HtmlContentWidget.ThemeStyleEnum;

import style from './ContentBlock.scss';

export enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

export function isPositionEnum(data: any): data is Position {
  for (const value in Position) {
    if (Position.hasOwnProperty(value)) {
      if (data === (Position as any)[value]) {
        return true;
      }
    }
  }

  return false;
}

export interface ContentBlockInterface {
  caption?: string;
  className: CztWidgets;
  content: string;
  image?: CztImage;
  isBottomSpacingCollapsed?: boolean;
  isTopSpacingCollapsed?: boolean;
  position?: Position;
  side?: boolean;
  themeStyle: ThemeStyleEnum;
  title: string;
}

export function isContentBlock(data: any): data is ContentBlockInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.HTML &&
    typeof data.content !== 'undefined'
  );
}

const rootClass: string = 'czt-content-block';

@Component({
  style,
})
export default class ContentBlock extends VueComponent<ContentBlockInterface>
  implements ContentBlockInterface {
  @Prop({ default: '', type: String })
  public caption!: string;

  @Prop({ required: true, type: String })
  public content!: string;

  @Prop()
  public image?: CztImage;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ default: Position.LEFT })
  public position!: Position;

  @Prop({ default: false, type: Boolean })
  public side!: boolean;

  @Prop({ default: ThemeStyleEnum.None })
  public themeStyle!: ThemeStyleEnum;

  @Prop({ required: true, type: String })
  public title!: string;

  public className = CztWidgets.HTML;

  protected get isDark() {
    return this.themeStyle === ThemeStyleEnum.Dark;
  }

  public render() {
    const rootClasses: string[] = [rootClass, 'czt-spacer'];
    if (this.isBottomSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-bottom');
    }
    if (this.isTopSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-top');
    }
    if (this.isDark) {
      rootClasses.push(`${rootClass}--dark`);
    }

    return (
      <div class={rootClasses.join(' ')}>
        <v-container>
          <v-row justify='center'>
            <v-col cols={12} class={`${rootClass}__headline`}>
              <Headline
                level={2}
                underscore
                align={Align.LEFT}
                light={this.isDark}
              >
                {this.title}
              </Headline>
            </v-col>
            {this.position === Position.LEFT && this.renderSideContent()}
            <v-col cols={12} md={12} lg={8} class='pt-md-0'>
              <HtmlRenderer
                content={this.content}
                side={this.side}
                light={this.isDark}
              />
            </v-col>
            {this.position === Position.RIGHT && this.renderSideContent()}
          </v-row>
        </v-container>
      </div>
    );
  }

  protected renderSideContent() {
    if (!this.image) {
      return;
    }
    const sizes = [
      `(min-width: 1264px) ${(1150 / 12) * 4}px`,
      `(min-width: 1008px) ${(1000 / 12) * 5}px`,
      `(min-width: 768px) ${(710 / 12) * 6}px`,
      `100vw`,
    ];
    // If side content column width should ever be changed, sizes need to be adjusted too
    return (
      <v-col class='pt-md-0' cols={12} md={6} lg={5} xl={4} tag='figure'>
        <Image
          src={this.image.src}
          alt={this.image.alt}
          sizes={sizes.join(', ')}
        />
        <figcaption
          class={{
            'czt-description': true,
            'czt-description--light': this.isDark,
          }}
        >
          {this.caption ? this.caption : this.image.alt}
        </figcaption>
      </v-col>
    );
  }
}
