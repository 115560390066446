import { Component, Prop } from 'vue-property-decorator';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { VueComponentMixin } from '~/utils/vue-component';
import { CztWidgets } from '~/utils/views/widgets';

import { getModule } from 'vuex-module-decorators';
import { PageLoader } from '~/components/templates/common';
import CertifiedSubjectsListFilter, {
  FilterEmit,
} from './CertifiedSubjectsFilter';
import { Link } from '~/components/molecules';
import CertifiedSubjectsModule from '~/app/core/store/modules/CertifiedSubjectsModule';
import { ItemData } from '~/components/atoms/multiselect/Multiselect';

import style from './CertifiedSubjectsList.scss';

export interface CertifiedSubjectsListItem {
  id: string;
  name: string;
  link: string;
  areas: ItemData[];
  regions: ItemData[];
}

export interface CertifiedSubjectsListInterface {
  className: CztWidgets;
  guid: string;
  isBottomSpacingCollapsed?: boolean;
  isTopSpacingCollapsed?: boolean;
  title: string;
}

export function isCertifiedSubjectsList(
  data: any
): data is CertifiedSubjectsListInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.CERTIFIED_SUBJECTS &&
    typeof data.guid === 'string'
  );
}

const rootClass = 'czt-certified-subjects-list';

@Component({ style })
export default class CertifiedSubjectsList
  extends VueComponentMixin<CertifiedSubjectsListInterface, PrefetchComponent>(
    Prefetch
  )
  implements CertifiedSubjectsListInterface {
  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({ required: true, type: String })
  public guid!: string;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  public className = CztWidgets.SAFE_TRAVELS;

  protected filterData: FilterEmit = {
    areas: [],
    regions: [],
    fulltext: '',
  };

  protected get certifiedSubjectsModule(): CertifiedSubjectsModule {
    return getModule(CertifiedSubjectsModule, this.$store);
  }

  protected get items() {
    let items: CertifiedSubjectsListItem[] = this.certifiedSubjectsModule.items;
    if (this.filterData.areas.length > 0) {
      items = items.filter((item) => {
        return (
          item.areas.filter(
            (area) =>
              this.filterData.areas.indexOf(area.value.toString()) !== -1
          ).length > 0
        );
      });
    }
    if (this.filterData.regions.length > 0) {
      items = items.filter((item) => {
        return (
          item.regions.filter(
            (region) =>
              this.filterData.regions.indexOf(region.value.toString()) !== -1
          ).length > 0
        );
      });
    }
    if (this.filterData.fulltext && this.filterData.fulltext.trim() !== '') {
      items = items.filter((item) => {
        return (
          item.name
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[^\w\s.-_\/]/g, '')
            .indexOf(
              this.filterData.fulltext
                .toLowerCase()
                .normalize('NFKD')
                .replace(/[^\w\s.-_\/]/g, '')
            ) !== -1
        );
      });
    }
    return items;
  }

  protected get areas() {
    return this.certifiedSubjectsModule.areas;
  }

  protected get regions() {
    return this.certifiedSubjectsModule.regions;
  }

  public prefetch() {
    const certifiedSubjectsModule =
      this.certifiedSubjectsModule ||
      getModule(CertifiedSubjectsModule, this.$store);
    return certifiedSubjectsModule.getCertifiedSubjects();
  }

  public render() {
    return <v-sheet class={rootClass}>{this.renderContent()}</v-sheet>;
  }

  protected renderContent(): JSX.Element {
    const containerClasses = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container class={containerClasses.join(' ')}>
        <v-row>{this.getHeadline()}</v-row>
        <CertifiedSubjectsListFilter
          onFilter={this.filter}
          loading={this.certifiedSubjectsModule.loading}
          filterOptions={{ areas: this.areas, regions: this.regions }}
        />
        <v-row>
          <v-col>
            <v-simple-table>
              <thead>
                <tr>
                  <th class='text-left'>
                    {this.$t('app.certifiedSubjects.table.name')}
                  </th>
                  <th class='text-left'>
                    {this.$t('app.certifiedSubjects.table.category')}
                  </th>
                  <th class='text-left'>
                    {this.$t('app.certifiedSubjects.table.area')}
                  </th>
                </tr>
              </thead>
              <tbody>{this.getTradeOfferItems()}</tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    );
  }

  protected getHeadline(): JSX.Element | undefined {
    if (this.items.length === 0) {
      return;
    }

    return (
      <v-col class='py-0'>
        <Headline underscore align={Align.LEFT} level={2}>
          {this.title}
        </Headline>
      </v-col>
    );
  }

  protected getTradeOfferItems(): JSX.Element | JSX.Element[] {
    if (this.items.length < 1) {
      if (this.certifiedSubjectsModule.loading) {
        return (
          <v-col cols='12'>
            <PageLoader />
          </v-col>
        );
      }

      return (
        <v-col cols='12'>
          <Headline align={Align.CENTER} level={3}>
            {this.$t('app.search.noResults')}
          </Headline>
        </v-col>
      );
    }

    return this.items.map((item) => {
      return (
        <tr
          class={`${rootClass}__item`}
          key={`${item.id}__certified-subjects__item`}
        >
          <td>
            {item.link ? <Link url={item.link}>{item.name}</Link> : item.name}
          </td>
          <td>
            {item.areas
              .map((area) => {
                return this.$te(
                  `app.certifiedSubjects.areaTranslations.${area.value}`
                )
                  ? this.$t(
                      `app.certifiedSubjects.areaTranslations.${area.value}`
                    ).toString()
                  : area.text;
              })
              .join(', ')}
          </td>
          <td>
            {item.regions
              .map((region) => {
                return this.$te(
                  `app.certifiedSubjects.regionTranslations.${region.value}`
                )
                  ? this.$t(
                      `app.certifiedSubjects.regionTranslations.${region.value}`
                    ).toString()
                  : region.text;
              })
              .join(', ')}
          </td>
        </tr>
      );
    });
  }

  protected filter(data: FilterEmit): void {
    this.filterData.areas = data.areas;
    this.filterData.regions = data.regions;
    this.filterData.fulltext = data.fulltext;
  }
}
