import apiUrl from './apiUrl';
import { Configuration } from './api/Configuration';
import {
  CategoriesApi,
  CertifiedSubjectsOfQualityApi,
  EventtiaEventsApi,
  MeatSpaceWrapperApi,
  NavigationsApi,
  NewsletterApi,
  PagesApi,
  SafeTravelStampsApi,
  SearchApi,
  TranslationsApi,
  WidgetsApi,
} from './api';

export default class {
  protected categoriesApi: CategoriesApi;

  protected certifiedSubjectsApi: CertifiedSubjectsOfQualityApi;

  protected eventsApi: EventtiaEventsApi;

  protected pagesApi: PagesApi;

  protected meatspaceApi: MeatSpaceWrapperApi;

  protected navigationsApi: NavigationsApi;

  protected newsletterApi: NewsletterApi;

  protected safeTravelsApi: SafeTravelStampsApi;

  protected searchApi: SearchApi;

  protected translationsApi: TranslationsApi;

  protected widgetsApi: WidgetsApi;

  constructor() {
    const configuration = new Configuration({ basePath: apiUrl });

    this.categoriesApi = new CategoriesApi(configuration);
    this.certifiedSubjectsApi = new CertifiedSubjectsOfQualityApi(
      configuration
    );
    this.eventsApi = new EventtiaEventsApi(configuration);
    this.pagesApi = new PagesApi(configuration);
    this.meatspaceApi = new MeatSpaceWrapperApi(configuration);
    this.newsletterApi = new NewsletterApi(configuration);
    this.navigationsApi = new NavigationsApi(configuration);
    this.safeTravelsApi = new SafeTravelStampsApi(configuration);
    this.searchApi = new SearchApi(configuration);
    this.translationsApi = new TranslationsApi(configuration);
    this.widgetsApi = new WidgetsApi(configuration);
  }

  public categories() {
    return this.categoriesApi;
  }

  public certifiedSubjects() {
    return this.certifiedSubjectsApi;
  }

  public events() {
    return this.eventsApi;
  }

  public navigations() {
    return this.navigationsApi;
  }

  public newsletters() {
    return this.newsletterApi;
  }

  public pages() {
    return this.pagesApi;
  }

  public safeTravels() {
    return this.safeTravelsApi;
  }

  public search() {
    return this.searchApi;
  }

  public translations() {
    return this.translationsApi;
  }

  public venueFinder() {
    return this.meatspaceApi;
  }

  public widgets() {
    return this.widgetsApi;
  }
}
