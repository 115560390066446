import { Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';

@Module({
  name: 'YoutubeVideosModule',
  stateFactory: true,
  namespaced: true,
})
export default class YoutubeVideosModule extends AbstractModule {
  public videoIds: { [key: string]: string } = {};

  @Mutation
  public setVideoId({ url, id }: { url: string; id: string }) {
    this.videoIds[url] = id;
  }
}
