import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import { getModule } from 'vuex-module-decorators';
import CookieSettingsModule from '~/app/core/store/modules/CookieSettingsModule';
import NavigationModule from '~/app/core/store/modules/NavigationModule';
import { Link, ScrollToTop } from '~/components/molecules';
import { NavigationFooterColumn } from '~/components/organisms';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import style from './Footer.scss';

@Component({
  style,
})
export default class Footer extends VueComponentMixin<{}, PrefetchComponent>(
  Prefetch
) {
  protected get cookieSettings(): CookieSettingsModule {
    return getModule(CookieSettingsModule, this.$store);
  }

  protected get navigationModule(): NavigationModule {
    return getModule(NavigationModule, this.$store);
  }

  protected rootClass: string = 'czt-footer';

  public render() {
    return (
      <v-footer padless class={this.rootClass}>
        <v-sheet class={this.rootClass + '__row'}>
          <v-container>
            <v-row>
              {this.navigationModule.footer.map((menu) => (
                <NavigationFooterColumn menu={menu} />
              ))}
            </v-row>
          </v-container>
        </v-sheet>
        <v-sheet class={this.rootClass + '__bottom'}>
          <v-container class={this.rootClass + '__bottom__container'}>
            <v-row>
              <v-col cols={12} sm={12} md='auto'>
                Copyright &copy; {new Date().getFullYear()} CzechTourism
              </v-col>
              <v-col class={this.rootClass + '__bottom__right'}>
                <Link url='~/GDPR'>{this.$t('app.footer.privacyPolicy')}</Link>
                <Link url='~/Cookies'>{this.$t('app.footer.cookies')}</Link>
                <a class='czt-link' onClick={this.handleClick}>
                  {this.$t('app.footer.cookieSettings')}
                </a>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
        <ScrollToTop />
      </v-footer>
    );
  }

  protected handleClick(e: Event) {
    e.preventDefault();
    this.cookieSettings.setCookieBarVisible(true);
  }
}
