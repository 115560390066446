import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import NavigationModule from '~/app/core/store/modules/NavigationModule';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { VueComponentMixin } from '~/utils/vue-component';
import { Image } from '~/components/atoms';
import { MobileNavigation } from '~/components/templates/common';
import { Link, SearchBar } from '~/components/molecules';
import NavigationGroup from '~/components/molecules/navigationGroup/NavigationGroup';
import NavigationLink from '~/components/molecules/navigationLink/NavigationLink';

import style from './Header.scss';
import cztLogo from '~/assets/czt-logo.svg';
import {
  isNavigationGroup,
  isNavigationLink,
  NavigationElement,
  NavigationLink as NavigationLinkInterface,
} from '~/utils/navigation';
import LanguageSelector from '~/components/molecules/languageSelector/LanguageSelector';
import { getCurrentLocale } from '~/utils/localization';

@Component({
  style,
})
export default class Header extends VueComponentMixin<{}, PrefetchComponent>(
  Prefetch
) {
  protected rootClass = 'czt-header';

  protected get navigationModule(): NavigationModule {
    return getModule(NavigationModule, this.$store);
  }

  protected get headerImages(): NavigationLinkInterface[] {
    return this.navigationModule.header
      .filter(isNavigationLink)
      .filter((item) => item.image !== undefined);
  }

  public render() {
    return (
      <v-app-bar class={this.rootClass} app height='100px'>
        <v-toolbar-title>
          <v-layout align-center>
            <Link url={'/'}>
              <Image
                src={cztLogo}
                class={`${this.rootClass}__logo mr-3`}
                alt='CzechTourism Logo'
              />
            </Link>
          </v-layout>
        </v-toolbar-title>

        <v-toolbar-items class={`${this.rootClass}__menu-items`}>
          {this.navigationModule.main.map(
            (item: NavigationElement, key: number) => {
              return (
                <div class={`${this.rootClass}__menu-item`} key={key}>
                  {isNavigationGroup(item) ? (
                    <NavigationGroup group={item} />
                  ) : (
                    <NavigationLink link={item} />
                  )}
                </div>
              );
            }
          )}
        </v-toolbar-items>

        <v-spacer />

        {this.headerImages.length > 0 && (
          <div class={`${this.rootClass}__header-image-links`}>
            {this.headerImages.map(
              (item) =>
                item.image && (
                  <Link
                    url={item.url}
                    class={`${this.rootClass}__header-image-links__item`}
                    target={item.target}
                  >
                    <Image src={item.image.src} alt={item.image.alt} />
                  </Link>
                )
            )}
          </div>
        )}

        <v-toolbar-items class={`${this.rootClass}__right`}>
          <SearchBar />
          <LanguageSelector
            currentLanguage={getCurrentLocale(this.$i18n.locale)}
          />
          <MobileNavigation items={this.navigationModule.main} />
        </v-toolbar-items>
      </v-app-bar>
    );
  }
}
