import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class YoutubeIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--social czt-icon--youtube'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 35 35'
        >
          <defs />
          <path fill='#ff0b00' d='M.027 0h35.032v35.032H.027z' />
          <path
            fill='#fff'
            fill-rule='nonzero'
            d='M10.812 6.456h1.325l.91 3.405.842-3.405h1.337l-1.531 5.063v3.455h-1.317V11.52l-1.566-5.063zm6.066 2.182c-1.03 0-1.713.68-1.713 1.688v3.08c0 1.108.579 1.684 1.713 1.684.94 0 1.679-.628 1.679-1.684v-3.08c0-.983-.733-1.688-1.68-1.688zm.488 4.716c0 .342-.174.594-.49.594-.322 0-.51-.263-.51-.594V10.43c0-.344.159-.6.487-.6.36 0 .513.248.513.6v2.923zm4.35-4.662v4.769c-.143.178-.46.471-.688.471-.249 0-.31-.172-.31-.424V8.692h-1.17v5.25c0 .621.191 1.123.817 1.123.354 0 .844-.184 1.35-.784v.692h1.168V8.692h-1.168zm2.026 12.745c-.413 0-.498.29-.498.702v.605h.983v-.606c.001-.405-.085-.7-.485-.7zm-4.326.036a.843.843 0 00-.23.183v3.729c.09.097.178.167.264.211.181.092.446.098.57-.063.063-.083.096-.22.096-.412v-3.09c0-.201-.04-.355-.119-.46-.135-.177-.386-.195-.58-.098zm4.44-4.778c-2.395-.163-10.179-.163-12.57 0-2.588.176-2.894 1.74-2.912 5.856.018 4.11.322 5.68 2.912 5.857 2.391.162 10.175.162 12.57 0 2.588-.177 2.893-1.742 2.912-5.857-.019-4.108-.322-5.68-2.913-5.856zM12.52 26.523h-1.253V19.59H9.97v-1.177h3.847v1.177H12.52v6.934zm4.458 0h-1.113v-.66c-.206.244-.418.43-.64.557-.6.344-1.423.336-1.423-.878V20.54h1.111v4.588c0 .24.058.402.297.402.217 0 .519-.279.654-.447V20.54h1.113v5.983zm4.283-1.24c0 .74-.277 1.317-1.018 1.317-.407 0-.747-.149-1.056-.537v.46h-1.123v-8.111h1.123v2.612c.251-.307.592-.56.99-.56.815 0 1.085.69 1.085 1.5v3.32zm4.112-1.61h-2.129v1.129c0 .45.04.837.486.837.47 0 .498-.317.498-.837v-.416h1.145v.45c0 1.153-.495 1.851-1.668 1.851-1.061 0-1.605-.774-1.605-1.85v-2.688c0-1.038.685-1.76 1.69-1.76 1.067 0 1.583.679 1.583 1.76v1.523z'
          />
        </svg>
      </i>
    );
  }
}
