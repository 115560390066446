import { Component, Emit, Prop, Watch } from 'vue-property-decorator';

import { ImageInterface } from '~/components/atoms/image/Image';
import { Icons, Image as CztImage } from '~/components/atoms';
import { VueComponent } from '~/utils/vue-component';

export interface LightboxInterface {
  images: ImageInterface[];
  onClose?: () => any;
  showIndexOnOpen: number;
  shown: boolean;
}

@Component
export default class Lightbox extends VueComponent<LightboxInterface>
  implements LightboxInterface {
  @Prop({ required: true })
  public images!: ImageInterface[];

  @Prop({ default: 0 })
  public showIndexOnOpen!: number;

  @Prop({ required: true })
  public shown!: boolean;

  protected index: number = 0;

  public render() {
    return (
      <v-dialog value={this.shown} onInput={this.emitClose} fullscreen>
        <v-card style='position:relative;'>
          <v-card-title>
            <v-spacer />
            <v-btn icon onClick={this.emitClose}>
              <Icons.common.Close size={2} />
            </v-btn>
          </v-card-title>
          <v-card-text class='px-0'>
            <v-carousel
              continuous
              height='85vh'
              hide-delimiters
              show-arrows-on-hover={true}
              v-model={this.index}
            >
              {this.images.map((image) => {
                return (
                  <v-carousel-item>
                    <v-row
                      no-gutters
                      class='fill-height'
                      align='center'
                      justify='center'
                    >
                      <CztImage
                        src={image.src}
                        srcset={image.srcset}
                        alt={image.alt}
                        sizes='100vw'
                      />
                    </v-row>
                  </v-carousel-item>
                );
              })}
            </v-carousel>
          </v-card-text>
        </v-card>
      </v-dialog>
    );
  }

  @Watch('showIndexOnOpen', { immediate: true })
  protected setIndex() {
    this.index = this.showIndexOnOpen;
  }

  @Emit('close')
  protected emitClose() {
    return;
  }
}
