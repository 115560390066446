import { Component, Prop } from 'vue-property-decorator';

import {
  Carousel,
  GridList,
  Slider,
  Gallery,
  SearchForm,
  IframeBlock,
} from '~/components/organisms';
import ContentBlock, {
  isContentBlock,
} from '~/components/organisms/contentBlock/ContentBlock';
import TabsWidget, { isTabs } from '~/components/templates/common/TabsWidget';
import { isGrid } from '~/components/organisms/gridList/GridList';
import { isCarousel } from '~/components/organisms/carousel/Carousel';
import { isSlider } from '~/components/organisms/slider/Slider';
import { isGallery } from '~/components/organisms/gallery/Gallery';
import { VueComponent } from '~/utils/vue-component';
import { Widget } from '~/utils/views/widgets';
import { isSearchForm } from '~/components/organisms/searchForm/SearchForm';
import NewsletterForm from '../common/NewsletterForm';
import { isNewsletterForm } from '../common/NewsletterForm';
import { isIframeBlock } from '~/components/organisms/iframeBlock/IframeBlock';
import EventtiaList, {
  isEventtiaList,
} from '~/components/organisms/eventtiaList/EventtiaList';
import SafeTravelsList, {
  isSafeTravelsList,
} from '../safeTravels/SafeTravelsList';
import Panorama, { isPanorama } from '~/components/organisms/panorama/Panorama';
import VenueFinder, { isVenueFinder } from '../common/VenueFinder';
import CertifiedSubjectsList, {
  isCertifiedSubjectsList,
} from '../certifiedSubjects/CertifiedSubjectsList';
import GalleryGrid from '~/components/organisms/gallery/GalleryGrid';

interface WidgetRendererInterface {
  widgets: Widget[];
  side?: boolean;
}

@Component
export default class WidgetRenderer
  extends VueComponent<WidgetRendererInterface>
  implements WidgetRendererInterface {
  @Prop({ required: true })
  public widgets!: Widget[];

  @Prop({ default: false, type: Boolean })
  public side!: boolean;

  public render() {
    return (
      <div class='czt-rendered-widgets'>
        {this.widgets.map(this.getMappedComponent)}
      </div>
    );
  }

  protected getMappedComponent(widget: Widget): JSX.Element | null {
    if (isGrid(widget)) {
      return (
        <GridList
          className={widget.className}
          guid={widget.guid}
          image={widget.image}
          imageAspectRatio={widget.imageAspectRatio}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          items={widget.items}
          link={widget.link}
          title={widget.title}
          maxItems={widget.maxItems}
          nextPageCount={widget.nextPageCount}
          pageSize={widget.pageSize}
          taxonomies={widget.taxonomies}
          taxonomyLists={widget.taxonomyLists}
          type={widget.type}
        />
      );
    } else if (isSlider(widget)) {
      return (
        <Slider
          className={widget.className}
          items={widget.items}
          delay={widget.delay}
          contentWidth={widget.contentWidth}
          ratio={widget.ratio}
        />
      );
    } else if (isCarousel(widget)) {
      return (
        <Carousel
          className={widget.className}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          items={widget.items}
          title={widget.title}
        />
      );
    } else if (isGallery(widget)) {
      if (widget.galleryStyle === 'grid') {
        return (
          <GalleryGrid
            className={widget.className}
            items={widget.items}
            title={widget.title}
          />
        );
      }
      return (
        <Gallery
          className={widget.className}
          items={widget.items}
          galleryStyle={widget.galleryStyle}
          ratio={widget.ratio}
          useContentWidth={widget.useContentWidth}
        />
      );
    } else if (isContentBlock(widget)) {
      return (
        <ContentBlock
          caption={widget.caption}
          className={widget.className}
          content={widget.content}
          image={widget.image}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          position={widget.position}
          side={this.side}
          themeStyle={widget.themeStyle}
          title={widget.title}
        />
      );
    } else if (isTabs(widget)) {
      return (
        <TabsWidget
          className={widget.className}
          image={widget.image}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          items={widget.items}
        />
      );
    } else if (isIframeBlock(widget)) {
      return (
        <IframeBlock
          className={widget.className}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          title={widget.title}
          url={widget.url}
          height={widget.height}
        />
      );
    } else if (isSearchForm(widget)) {
      return (
        <v-container class='czt-spacer'>
          <h3 class='czt-search-form__widget-title mb-4'>
            {this.$t('app.search.widgetTitle')}
          </h3>
          <v-row justify='center' no-gutters>
            <v-col cols='12' lg='8'>
              <SearchForm className={widget.className} term={widget.term} />
            </v-col>
          </v-row>
        </v-container>
      );
    } else if (isEventtiaList(widget)) {
      return (
        <EventtiaList
          className={widget.className}
          guid={widget.guid}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          items={widget.items}
          link={widget.link}
          title={widget.title}
          maxItems={widget.maxItems}
          nextPageCount={widget.nextPageCount}
          pageSize={widget.pageSize}
          foreignReps={widget.foreignReps}
          packageOptions={widget.packageOptions}
          showFilter={widget.showFilter}
          themeOptions={widget.themeOptions}
          typeOptions={widget.typeOptions}
        />
      );
    } else if (isSafeTravelsList(widget)) {
      return (
        <SafeTravelsList
          className={widget.className}
          guid={widget.guid}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          title={widget.title}
        />
      );
    } else if (isCertifiedSubjectsList(widget)) {
      return (
        <CertifiedSubjectsList
          className={widget.className}
          guid={widget.guid}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          title={widget.title}
        />
      );
    } else if (isPanorama(widget)) {
      return (
        <Panorama
          className={widget.className}
          caption={widget.caption}
          guid={widget.guid}
          image={widget.image}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          rotate={widget.rotate}
          title={widget.title}
        />
      );
    } else if (isVenueFinder(widget)) {
      return (
        <VenueFinder
          className={widget.className}
          guid={widget.guid}
          title={widget.title}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
        />
      );
    } else if (isNewsletterForm(widget)) {
      return <NewsletterForm className={widget.className} guid={widget.guid} />;
    }

    return null;
  }
}
