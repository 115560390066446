import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { ImageTile } from '~/components/molecules';
import { ImageInterface } from '~/components/atoms/image/Image';
import { ThemeRatios } from '~/utils/theme';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected image: ImageInterface = {
    src: 'http://picsum.photos/1920/1080',
    alt: 'Wide Picture',
  };
  protected ratio: ThemeRatios = ThemeRatios['16x9'];

  public getContent() {
    return [
      <div style='padding: 20px;'>
        <ImageTile image={this.image} ratio={this.ratio} />
      </div>,
    ];
  }
  public getSettings() {
    return [
      <v-select label='Ratio' items={this.getRatios()} v-model={this.ratio} />,
    ];
  }

  protected getRatios() {
    const ratios = [];
    for (const ratio in ThemeRatios) {
      if (isNaN((ratio as unknown) as number)) {
        ratios.push({ text: ratio, value: ThemeRatios[ratio] });
      }
    }
    return ratios;
  }
}
