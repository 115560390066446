import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './DateRangePicker.scss';

@Component({
  style,
})
export default class DateRangePicker extends VueComponent<{}> {
  public render() {
    return;
  }
}
