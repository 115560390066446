import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import Icon, { IconName } from '~/components/atoms/icons/Icon';
import style from './IconText.scss';

export enum IconPosition {
  PREPEND = 'prepend',
  APPEND = 'append',
}

interface IconTextInterface {
  bordered?: boolean;
  icon: IconName;
  iconSize?: number;
  iconPosition?: IconPosition;
  iconMargin?: string;
}

@Component({
  style,
})
export default class IconText extends VueComponent<IconTextInterface>
  implements IconTextInterface {
  @Prop({ type: Boolean })
  public bordered?: boolean;

  @Prop({ required: true })
  public icon!: IconName;

  @Prop({ default: 1, type: Number })
  public iconSize!: number;

  @Prop({ default: IconPosition.PREPEND, type: String })
  public iconPosition!: IconPosition;

  @Prop({ type: String })
  public iconMargin?: string;

  public render() {
    return (
      <div
        class={'czt-icon-text czt-icon-text--' + this.iconPosition}
        onClick={() => this.$emit('click')}
      >
        <Icon
          name={this.icon}
          size={this.iconSize}
          bordered={this.bordered}
          style={
            this.iconMargin
              ? `${
                  this.iconPosition === IconPosition.APPEND
                    ? 'margin-left: '
                    : 'margin-right: '
                }${this.iconMargin}`
              : undefined
          }
        />
        <span>{this.$slots.default}</span>
      </div>
    );
  }
}
