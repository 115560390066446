export default {
  app: {
    cookies: {
      cookieBarText:
        'This site uses cookies. By continuing to browse the site, you are agreeing to our use of cookies. <a href="~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies">More info</a>.',
      buttons: {
        accept: 'I understand, accept all',
        save: 'Save selected',
      },
      settings: {
        button: 'Settings',
        required: 'Required (for the proper functioning of the site)',
        statistics: 'Statistics (to understand how visitors use the site)',
        marketing: 'Marketing (to track visitors to show relevant ads)',
        reject: 'Reject all',
        accept: 'Accept all',
      },
    },
  },
};
