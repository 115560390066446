export enum ContentWidgets {
  VIDEO = 'YouTubeVideo',
  FACEBOOK = 'FacebookPost',
  INSTAGRAM = 'InstagramPost',
}

interface ObjectData {
  [key: string]: unknown;
}

export interface ContentWidgetData extends ObjectData {
  name: string;
}

export function isContentWidgetData(data: any): data is ContentWidgetData {
  return data && typeof data === 'object' && typeof data.name === 'string';
}

export function getDataFromString(data: string): ObjectData {
  const dataObj: ObjectData = {};
  const results = data.split('|');
  if (!results) return dataObj;
  results.forEach((result) => {
    const regex = /(?:\()(.*?)(?:\))(.+)/g;
    const resultData = regex.exec(result);
    if (resultData && resultData[1] && resultData[2])
      dataObj[resultData[1]] = resultData[2];
  });
  return dataObj;
}
