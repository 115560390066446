import cheerio from 'cheerio';
import { Component, Prop } from 'vue-property-decorator';
import {
  compileToFunctions,
  ssrCompileToFunctions,
} from 'vue-template-compiler';
import style from './HtmlRenderer.scss';
import { Video, Button } from '~/components/atoms';
// Must be imported directly, does not work when importing from molecules index
import Link, { LinkInterface } from '~/components/molecules/link/Link';
import TextClamp from '~/components/molecules/textClamp/TextClamp';
import RightArrowText from '~/components/htmlRenderer/RightArrowText';
import { Target as LinkTarget, isTargetEnum } from '~/utils/molecules/link';
import { VueComponent } from '~/utils/vue-component';
import { ButtonInterface, Style } from '~/components/atoms/button/Button';
import { LocaleMessage } from 'vue-i18n';
import {
  ContentWidgetData,
  getDataFromString,
  isContentWidgetData,
} from '~/utils/htmlRenderer/types';
import {
  getVideoComponentString,
  isContentVideoData,
  isVideoContentWidgetData,
} from '~/utils/htmlRenderer/video';
import {
  getFacebookComponentString,
  isFacebookContentWidgetData,
} from '~/utils/htmlRenderer/facebook';
import {
  getInstagramComponentString,
  isInstagramContentWidgetData,
} from '~/utils/htmlRenderer/instagram';
import FacebookSdkModule from '~/app/core/store/modules/FacebookSdkModule';
import { getModule } from 'vuex-module-decorators';

interface HTMLRenderer {
  content: string | LocaleMessage;
  light?: boolean;
  side?: boolean;
  whitespaces?: boolean;
}

const rootClass = 'czt-content';

@Component({
  style,
  components: {
    'arrow-right': RightArrowText,
    'button-component': Button,
    'link-component': Link,
    'text-clamp': TextClamp,
    'video-component': Video,
  },
})
export default class HtmlRenderer extends VueComponent<HTMLRenderer>
  implements HTMLRenderer {
  @Prop({ required: true })
  public content!: string | LocaleMessage;

  @Prop({ default: false, type: Boolean })
  public light!: boolean;

  @Prop({ default: false, type: Boolean })
  public side!: boolean;

  @Prop({ default: false, type: Boolean })
  public whitespaces!: boolean;

  protected get sdkModule(): FacebookSdkModule {
    return getModule(FacebookSdkModule, this.$store);
  }

  protected templateRenderer: any;

  protected transformedContent: string = '';

  /**
   * Recompiles the content on the client side
   */
  public created() {
    if (typeof this.content !== 'string') {
      return;
    }
    this.transformedContent = this.content;

    this.transformLinks();
    this.transformHtmlWidgets();
    this.transformVideos();
    this.transformScript();
    this.transformTextClamp();
    if (this.transformedContent.trim() === '') {
      return;
    }

    const classes: string[] = [rootClass];
    if (this.light) {
      classes.push(`${rootClass}--light`);
    }
    if (this.side) {
      classes.push(`${rootClass}--side`);
    }

    const complile = this.$ssrContext
      ? ssrCompileToFunctions
      : compileToFunctions;
    const { render, staticRenderFns } = complile(
      `<div class='${classes.join(' ')}'>${this.transformedContent}</div>`
    );
    this.templateRenderer = render;
    this.$options.staticRenderFns = staticRenderFns;
  }

  public mounted() {
    this.sdkModule.loadSdk();
  }

  public render() {
    const classes: string[] = [rootClass];
    if (this.light) {
      classes.push(`${rootClass}--light`);
    }
    if (this.side) {
      classes.push(`${rootClass}--side`);
    }
    if (this.whitespaces) {
      classes.push(`${rootClass}--whitespace`);
    }
    if (this.templateRenderer) {
      return this.templateRenderer();
    }
    return <div class={classes.join(' ')}>{this.transformedContent}</div>;
  }

  protected transformTextClamp() {
    const dom = cheerio.load(this.transformedContent);
    dom('.text-clamp').each((_, element) => {
      const el = dom(element);
      el.replaceWith(`<text-clamp>${el.html()}</text-clamp>`);
    });
    this.transformedContent = cheerio.html(dom('body').contents());
  }

  protected transformLinks() {
    const dom = cheerio.load(this.transformedContent);

    dom('ol.czt-linked-list a, ul.czt-linked-list a').each((index, element) => {
      const el = dom(element);
      el.html(`<arrow-right>${el.contents()}</arrow-right>`);
    });

    dom('a').each((index, element) => {
      const el = dom(element);
      const url = el.attr('href');
      if (!url) {
        el.replaceWith(`<span>${el.contents()}</span>`);
        return;
      }

      let target: LinkTarget | undefined;

      const elementTarget = el.attr('target');
      if (isTargetEnum(elementTarget)) {
        target = elementTarget;
      }

      const elClass = el.attr('class');
      const editorElement = el.data('editor-element');

      if (elClass === 'czt-button' || editorElement === 'button') {
        const props: ButtonInterface = {
          url,
          target,
          buttonStyle: this.light ? Style.SECONDARY : Style.PRIMARY,
        };

        el.replaceWith(
          `<button-component url='${props.url}'${
            props.target ? ` target='${props.target}'` : ''
          } button-style='${
            props.buttonStyle
          }'>${el.contents()}</button-component>`
        );
      } else {
        const props: LinkInterface = {
          url,
          target,
        };

        el.replaceWith(
          `<link-component url='${props.url}'${
            props.target ? ` target='${props.target}'` : ''
          }>${el.contents()}</link-component>`
        );
      }
    });
    this.transformedContent = cheerio.html(dom('body').contents());
  }

  protected transformHtmlWidgets() {
    const matchRegex = /(?:\{\^(?:widget)\|)(.+?)(?:\^\})/gi;
    const widgets = this.transformedContent.match(matchRegex);
    if (!widgets) {
      return;
    }
    widgets.forEach((widget) => {
      const data = this.getWidgetData(widget);
      const replaceWith = data ? this.getWidgetComponentString(data) : '';
      this.transformedContent = this.transformedContent.replace(
        widget,
        replaceWith
      );
    });
  }

  protected transformScript() {
    const dom = cheerio.load(this.transformedContent);

    dom('script').each((index, element) => {
      const el = dom(element);
      el.attr('type', 'application/javascript');
      el.attr('is', 'script');
    });

    this.transformedContent = cheerio.html(dom('body').contents());
  }

  protected getWidgetData(widget: string): ContentWidgetData | null {
    const videoRegex = /(?:\{\^(?:widget)\|)(.+?)(?:\^\})/gi;
    const data = videoRegex.exec(widget);
    if (data && data[1]) {
      const widgetData = getDataFromString(data[1]);
      return isContentWidgetData(widgetData) ? widgetData : null;
    }
    return null;
  }

  protected getWidgetComponentString(data: ContentWidgetData): string {
    switch (true) {
      case isFacebookContentWidgetData(data):
        if (!this.sdkModule.sdkShouldLoad) {
          this.sdkModule.setSdkShouldLoad(true);
        }
        return getFacebookComponentString(data);
      case isInstagramContentWidgetData(data):
        return getInstagramComponentString(data);
      case isVideoContentWidgetData(data):
        return getVideoComponentString(data);
      default:
        return '';
    }
  }

  protected transformVideos() {
    const matchRegex = /(?:\{\^(?:youtubevideo|media)\|)(.+?)(?:\^\})/gi;
    const videos = this.transformedContent.match(matchRegex);
    if (!videos) {
      return;
    }
    videos.forEach((video) => {
      const videoRegex = /(?:\{\^(?:youtubevideo|media)\|)(.+?)(?:\^\})/gi;
      const data = videoRegex.exec(video);
      if (data && data[1]) {
        const videoData = getDataFromString(data[1]);
        if (isContentVideoData(videoData)) {
          this.transformedContent = this.transformedContent.replace(
            video,
            getVideoComponentString(videoData)
          );
        }
      } else {
        this.transformedContent = this.transformedContent.replace(video, '');
      }
    });
  }
}
