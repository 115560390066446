import { VueComponent } from '~/utils/vue-component';
import { Component, Prop } from 'vue-property-decorator';

import style from './IframeBlock.scss';
import { CztWidgets } from '~/utils/views/widgets';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';

export interface IframeBlockInterface {
  className: CztWidgets;
  isBottomSpacingCollapsed?: boolean;
  isTopSpacingCollapsed?: boolean;
  title: string;
  url: string;
  height?: number;
}

interface IframeMessage {
  origin: string;
  data: any;
}

function isIframeMessage(input: any): input is IframeMessage {
  return input && typeof input === 'object' && typeof input.origin === 'string';
}

interface IframeFormHeightMessage extends IframeMessage {
  data: {
    clientHeight: number;
    id: 'CZT.Form.Height';
  };
}

function isIframeFormHeightMessage(
  input: any
): input is IframeFormHeightMessage {
  if (!isIframeMessage(input)) {
    return false;
  }

  return (
    input.data &&
    typeof input.data === 'object' &&
    typeof input.data.clientHeight === 'number' &&
    input.data.id === 'CZT.Form.Height'
  );
}

const rootClass = 'czt-iframe-block';

const defaultHeight: number = 450;

@Component(style)
export default class IframeBlock extends VueComponent<IframeBlockInterface>
  implements IframeBlockInterface {
  @Prop({ required: true })
  public className!: CztWidgets;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ default: '' })
  public title!: string;

  @Prop({ type: String, required: true })
  public url!: string;

  @Prop({ type: Number, default: defaultHeight })
  public height!: number;

  protected currentHeight: number = defaultHeight;

  public created() {
    this.currentHeight = this.height;
    if (typeof window !== 'undefined') {
      window.addEventListener('message', this.setHeight, false);
    }
  }

  public beforeDestroy() {
    window.removeEventListener('message', this.setHeight);
  }

  public render() {
    const spacerClasses: string[] = ['czt-spacer'];
    if (this.isBottomSpacingCollapsed) {
      spacerClasses.push('czt-spacer--collapse-bottom');
    }
    if (this.isTopSpacingCollapsed) {
      spacerClasses.push('czt-spacer--collapse-top');
    }

    return (
      <div class={rootClass}>
        <div class='container py-0'>
          <v-row justify='center'>
            <v-col cols={12} md={12} lg={8}>
              <div class={spacerClasses.join(' ')}>
                {this.title && (
                  <Headline level={2} align={Align.LEFT} underscore>
                    {this.title}
                  </Headline>
                )}
                <iframe
                  src={this.url}
                  title={this.title}
                  scrolling='yes'
                  style={{
                    height: `${this.currentHeight}px`,
                    overflow: 'auto',
                    minHeight: `${this.currentHeight}px`,
                  }}
                >
                  Your web-browser does not support iframe.
                </iframe>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    );
  }

  protected setHeight(event: Event): void {
    if (isIframeFormHeightMessage(event)) {
      this.currentHeight = event.data.clientHeight;
    }
  }
}

export function isIframeBlock(data: any): data is IframeBlockInterface {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.IFRAME
  );
}
