import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class VkontakteIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--social czt-icon--vkontakte'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6.35 6.35'>
          <path fill='#fff' d='M.35.38h5.64v5.59H.35z' />
          <path
            fill='#5181b8'
            d='M0 0v6.35h6.35V0zm5.1 4.5h-.57c-.32 0-.37-.18-.64-.45-.23-.23-.37-.05-.36.29 0 .08-.04.16-.15.16-.33 0-.81.04-1.3-.48A6.1 6.1 0 011.06 2.3c0-.07.05-.1.14-.1h.57c.15 0 .17.08.2.18.18.4.6 1.2.72.76.06-.26.1-.86-.19-.91-.15-.03.12-.2.52-.2.1 0 .21 0 .32.03.2.05.2.14.2.28 0 .5-.07.84.07.92.12.09.46-.45.63-.86.05-.12.06-.2.2-.2h.7c.37 0-.05.53-.37.95-.25.33-.24.33.06.61.21.2.46.47.46.62 0 .07-.05.12-.2.12z'
          />
        </svg>
      </i>
    );
  }
}
