import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';

import { ImageInterface } from '~/components/atoms/image/Image';
import { SearchItem } from '~/components/molecules';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected title: string = 'Article';
  protected subtitle: string = 'Ten gems of Prague';
  protected text: string =
    'Get to know the unique story of a city which history atrributed nicknames to such as "Stone Dream" or the "Golden City" and get to know its greatest jewels';
  protected image: ImageInterface = {
    src: 'http://picsum.photos/1080/1920',
    alt: 'Tall Picture',
  };
  protected path: string = 'relative/path_01';

  public getContent() {
    return (
      <SearchItem
        title={this.title}
        subtitle={this.subtitle}
        text={this.text}
        image={this.image}
        path={this.path}
      />
    );
  }

  public getSettings() {
    return [
      <v-text-field label='Subtitle' clearable v-model={this.subtitle} />,
      <v-text-field label='Text' clearable v-model={this.text} />,
    ];
  }
}
