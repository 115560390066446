import Facebook from './Facebook';
import Instagram from './Instagram';
import InstagramWhite from './InstagramWhite';
import Pinterest from './Pinterest';
import Twitter from './Twitter';
import Vkontakte from './Vkontakte';
import Youtube from './Youtube';
import YoutubeSimple from './YoutubeSimple';
import LinkedIn from './LinkedIn';

export default {
  Facebook,
  Instagram,
  InstagramWhite,
  Pinterest,
  Twitter,
  Vkontakte,
  Youtube,
  YoutubeSimple,
  LinkedIn,
};
