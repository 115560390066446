import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { Icons } from '~/components/atoms';
import style from './LanguageSelector.scss';
import { LanguageLocaleInterface } from '~/utils/localization';
import { supportedLocales } from '~/app/localization';

export interface LanguageSelectorInterface {
  currentLanguage: LanguageLocaleInterface | null;
}

const rootClass = 'czt-language-selector';

@Component({
  style,
})
export default class LanguageSelector
  extends VueComponent<LanguageSelectorInterface>
  implements LanguageSelectorInterface {
  @Prop({ type: Object })
  public currentLanguage!: LanguageLocaleInterface | null;

  protected get availableLocales() {
    return supportedLocales.filter(
      (item) => item.public && item.slug !== this.$i18n.locale
    );
  }

  public render() {
    if (this.availableLocales.length < 1) {
      return (
        <div class={rootClass}>
          <a
            class={`${rootClass}__button ${rootClass}__button--none-available`}
            aria-disabled={true}
          >
            <Icons.common.Language size={2} />
            <span class={`${rootClass}__label`}>
              {this.$t('app.common.language')}
            </span>
            {this.currentLanguage && (
              <span class={`${rootClass}__current`}>
                {this.currentLanguage.name}
              </span>
            )}
          </a>
        </div>
      );
    }

    return (
      <div class={rootClass}>
        <v-menu
          eager
          offset-y
          scopedSlots={{
            activator: (scope: any) => {
              return (
                <a
                  class={`${rootClass}__button`}
                  title={this.$t('app.common.selectLanguage')}
                  {...{
                    on: scope.on,
                  }}
                >
                  <Icons.common.Language size={2} />
                  <span class={`${rootClass}__label`}>
                    {this.$t('app.common.language')}
                  </span>
                  {this.currentLanguage && (
                    <span class={`${rootClass}__current`}>
                      {this.currentLanguage.name}
                    </span>
                  )}
                </a>
              );
            },
          }}
        >
          <v-list>
            {this.availableLocales.map((lang) => (
              <v-list-item>
                <router-link to={`/${lang.slug}`} class={`${rootClass}__item`}>
                  {lang.name}
                </router-link>
              </v-list-item>
            ))}
          </v-list>
        </v-menu>
      </div>
    );
  }
}
