export default {
  app: {
    menu: {
      about: 'O nás',
      contacts: 'Kontakty',
      homepage: 'Domů',
      news: 'Aktuality',
    },
  },
};
