import { Component, Emit, Prop } from 'vue-property-decorator';
import { Button, InputField, Multiselect } from '~/components/atoms';
import { ItemData } from '~/components/atoms/select/Select';
import { VueComponent } from '~/utils/vue-component';

import style from './CertifiedSubjectsFilter.scss';
import { ThemeColors } from '~/utils/theme';

const rootClass = 'czt-certified-subjects-filter';

export type FilterEmit = FilterData;

interface FilterData {
  fulltext: string;
  areas: string[];
  regions: string[];
}
export interface CertifiedSubjectsListFilterOptions {
  areas: ItemData[];
  regions: ItemData[];
}

interface CertifiedSubjectsListFilterInterface {
  filterOptions: CertifiedSubjectsListFilterOptions;
  loading?: boolean;
  onFilter?: (data: FilterEmit) => void;
}

@Component({
  style,
})
export default class CertifiedSubjectsListFilter
  extends VueComponent<CertifiedSubjectsListFilterInterface>
  implements CertifiedSubjectsListFilterInterface {
  @Prop({ required: true })
  public filterOptions!: CertifiedSubjectsListFilterOptions;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  protected get areaOptions(): ItemData[] {
    return this.filterOptions.areas.map((area) => {
      return {
        text: this.$te(`app.certifiedSubjects.areaTranslations.${area.value}`)
          ? this.$t(
              `app.certifiedSubjects.areaTranslations.${area.value}`
            ).toString()
          : area.text,
        value: area.value,
      };
    });
  }

  protected get regionOptions(): ItemData[] {
    return this.filterOptions.regions.map((region) => {
      return {
        text: this.$te(
          `app.certifiedSubjects.regionTranslations.${region.value}`
        )
          ? this.$t(
              `app.certifiedSubjects.regionTranslations.${region.value}`
            ).toString()
          : region.text,
        value: region.value,
      };
    });
  }

  protected search: string = '';
  protected areas: string[] = [];
  protected regions: string[] = [];

  public render() {
    return (
      <v-sheet class={`${rootClass} mb-3`} color={ThemeColors.PRIMARY}>
        <form onSubmit={this.emitFilter}>
          <v-col class='py-0'>
            <v-row>
              <v-col class='py-0'>
                <v-row no-gutters>
                  <v-col cols='12'>
                    <v-row>
                      <v-col cols='12' xl='4'>
                        <InputField
                          class={`${rootClass}__input`}
                          v-model={this.search}
                          clearable
                          placeholder={this.$t('app.certifiedSubjects.search')}
                        />
                      </v-col>
                      <v-col cols='12' md='6' xl='4'>
                        <Multiselect
                          disabled={this.areaOptions.length < 2}
                          items={this.areaOptions}
                          v-model={this.areas}
                          placeholder={this.$t('app.certifiedSubjects.areas')}
                        />
                      </v-col>
                      <v-col cols='12' md='6' xl='4'>
                        <Multiselect
                          disabled={this.regionOptions.length < 2}
                          items={this.regionOptions}
                          v-model={this.regions}
                          placeholder={this.$t('app.certifiedSubjects.regions')}
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' md='auto' class='text-center'>
                <Button loading={this.loading} submit>
                  {this.$t('app.certifiedSubjects.button')}
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </form>
      </v-sheet>
    );
  }

  @Emit('filter')
  protected emitFilter(e?: Event): FilterEmit {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    return {
      areas: this.areas,
      regions: this.regions,
      fulltext: this.search,
    };
  }
}
