import { supportedLocales } from '~/app/localization';

export default function isLocaleSupported(locale: string): boolean {
  for (const supportedLocale of supportedLocales) {
    if (supportedLocale.slug === locale) {
      return true;
    }
  }

  return false;
}
