import { Component, Prop } from 'vue-property-decorator';

import { GridItem } from '~/components/organisms/gridList/GridList';
import { VueComponent } from '~/utils/vue-component';
import { MeatspaceVenueSearch } from '~/utils/venueFinder/types';
import { createImage } from '~/utils/views/components';
import ClassicGridItem from '~/components/molecules/gridItem/Classic';
import { IconText } from '~/components/molecules';

const rootClass = 'czt-venue-finder__item';

interface VenueFinderItemInterface {
  venue: MeatspaceVenueSearch;
}

@Component
export default class VenueFinderItem
  extends VueComponent<VenueFinderItemInterface>
  implements VenueFinderItemInterface {
  @Prop({ required: true })
  public venue!: MeatspaceVenueSearch;

  protected get item(): GridItem {
    return {
      description: this.venue.perex,
      image:
        this.venue.images.length > 0
          ? createImage(
              `https://meatapi.com/mapi/v4/system/image?image=${this.venue.images[0]}`,
              this.venue.title
            )
          : createImage('', ''),
      link: `~/venue/${this.venue.id}`,
      linkText: this.$t('app.venues.detailButton').toString(),
      title: this.venue.title,
      perex: this.venue.perex,
      location: { regionName: this.venue.location },
    };
  }

  public render() {
    return (
      <ClassicGridItem class={rootClass} item={this.item}>
        <IconText icon={['common', 'User']}>{this.venue.capacity}</IconText>
      </ClassicGridItem>
    );
  }
}
