import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import { Category } from '~/app/core/apiClient/api';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  public categories: Category[] = [];

  public loading: boolean = false;

  public errors: string[] = [];

  public prefetch() {
    this.loadingPrefetchData = true;

    return this.loadCategories().finally(() => {
      this.loadingPrefetchData = false;
    });
  }

  public getContent() {
    return (
      <v-card loading={this.loading}>
        <v-card-title>Category API test</v-card-title>
        <v-card-text>
          {this.errors.map((error) => {
            return <v-alert type='error'>{error}</v-alert>;
          })}
          {this.categories.map((category) => {
            return <div>{category.categoryName}</div>;
          })}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color='blue'
            dark
            loading={this.loading}
            onClick={this.loadCategories}
          >
            Load
          </v-btn>
        </v-card-actions>
      </v-card>
    );
  }

  protected loadCategories() {
    this.loading = true;
    this.errors = [];

    return this.$api
      .categories()
      .categoriesGetCategories(this.$i18n.locale)
      .then((data) => {
        this.categories = data;
      })
      .catch(() => {
        this.errors.push('Could not fetch categories');
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
