export default {
  app: {
    event: {
      noResult: 'Event not found',
      link: 'Link to the event',
      info: 'Information',
      virtual: 'This event is virtual',
    },
  },
};
