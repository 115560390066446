import { Component, Emit, Prop } from 'vue-property-decorator';
import { Button, InputField, Multiselect } from '~/components/atoms';
import { ItemData } from '~/components/atoms/select/Select';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { VueComponentMixin } from '~/utils/vue-component';
import { format, parse } from '~/utils/date-fns';

import style from './EventtiaFilter.scss';
import { defaultDateFormat } from '~/utils/dateTime';
import { ThemeColors } from '~/utils/theme';

const rootClass = 'czt-eventtia-filter';

export type FilterEmit = FilterData;

interface FilterData {
  from: string | null;
  to: string | null;
  foreignReps: string[];
  packages: string[];
  themes: string[];
  types: string[];
}

interface PresetData {
  from?: string;
  packages?: string[];
  to?: string;
  themes?: string[];
  types?: string[];
  foreignReps?: string[];
}

interface EventtiaFilterInterface {
  filterPreset?: PresetData;
  foreignRepOptions: ItemData[];
  loading?: boolean;
  onFilter?: (data: FilterEmit) => void;
  packageOptions: ItemData[];
  themeOptions: ItemData[];
  typeOptions: ItemData[];
}

@Component({
  style,
})
export default class EventtiaFilter
  extends VueComponentMixin<EventtiaFilterInterface, PrefetchComponent>(
    Prefetch
  )
  implements EventtiaFilterInterface {
  @Prop()
  public filterPreset?: PresetData;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  @Prop({ required: true })
  public foreignRepOptions!: ItemData[];

  @Prop({ required: true })
  public packageOptions!: ItemData[];

  @Prop({ required: true })
  public themeOptions!: ItemData[];

  @Prop({ required: true })
  public typeOptions!: ItemData[];

  protected from: string | null = null;
  protected to: string | null = null;
  protected foreignReps: string[] = [];
  protected packages: string[] = [];
  protected themes: string[] = [];
  protected types: string[] = [];

  protected fromMenuState: boolean = false;
  protected toMenuState: boolean = false;

  protected get formattedFrom(): string {
    if (this.from && this.from !== '') {
      return format(parse(this.from), defaultDateFormat);
    }
    return '';
  }

  protected get formattedTo(): string {
    if (this.to && this.to !== '') {
      return format(parse(this.to), defaultDateFormat);
    }
    return '';
  }

  public created() {
    if (this.filterPreset) {
      this.to = this.filterPreset.to || null;
      this.from = this.filterPreset.from || null;
      this.foreignReps = this.filterPreset.foreignReps || [];
      this.packages = this.filterPreset.packages || [];
      this.themes = this.filterPreset.themes || [];
      this.types = this.filterPreset.types || [];
    }
  }

  public render() {
    return (
      <v-sheet class={`${rootClass} mb-3`} color={ThemeColors.PRIMARY}>
        <form onSubmit={this.emitFilter}>
          <v-col class='py-0'>
            <v-row>
              <v-col class='py-0'>
                <v-row no-gutters>
                  <v-col cols='12'>
                    <v-row>
                      <v-col cols='12' sm='6' md='4'>
                        <v-menu
                          close-on-content-click={false}
                          offset-y
                          nudge-left={25}
                          min-width='auto'
                          max-width='300px'
                          scopedSlots={{
                            activator: (data: any) => {
                              return (
                                <div
                                  {...{
                                    on: data.on,
                                  }}
                                >
                                  <InputField<string>
                                    class={`${rootClass}__input`}
                                    value={this.formattedFrom}
                                    read-only
                                    clearable
                                    onInput={(value) => {
                                      if (!value || value === '') {
                                        this.from = null;
                                      }
                                    }}
                                    placeholder={this.$t(
                                      'app.events.placeholder.dateFrom'
                                    )}
                                  />
                                </div>
                              );
                            },
                          }}
                          transition='scale-transition'
                          v-model={this.fromMenuState}
                        >
                          <v-date-picker
                            onChange={(value: string) => {
                              this.from = value;
                              this.fromMenuState = false;
                            }}
                            no-title
                            value={this.from}
                          />
                        </v-menu>
                      </v-col>
                      <v-col cols='12' sm='6' md='4'>
                        <v-menu
                          close-on-content-click={false}
                          offset-y
                          nudge-left={25}
                          min-width='auto'
                          max-width='300px'
                          scopedSlots={{
                            activator: (data: any) => {
                              return (
                                <div
                                  {...{
                                    on: data.on,
                                  }}
                                >
                                  <InputField<string>
                                    class={`${rootClass}__input`}
                                    value={this.formattedTo}
                                    read-only
                                    clearable
                                    onInput={(value) => {
                                      if (!value || value === '') {
                                        this.to = null;
                                      }
                                    }}
                                    placeholder={this.$t(
                                      'app.events.placeholder.dateTo'
                                    )}
                                  />
                                </div>
                              );
                            },
                          }}
                          transition='scale-transition'
                          v-model={this.toMenuState}
                        >
                          <v-date-picker
                            onChange={(value: string) => {
                              this.to = value;
                              this.toMenuState = false;
                            }}
                            no-title
                            value={this.to}
                          />
                        </v-menu>
                      </v-col>
                      <v-col cols='12' md='4'>
                        <Multiselect
                          items={this.foreignRepOptions}
                          v-model={this.foreignReps}
                          placeholder={this.$t(
                            'app.events.placeholder.foreignReps'
                          )}
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols='12'>
                    <v-row>
                      <v-col cols='12' md='4'>
                        <Multiselect
                          items={this.typeOptions}
                          v-model={this.types}
                          placeholder={this.$t('app.events.placeholder.type')}
                        />
                      </v-col>
                      <v-col cols='12' md='4'>
                        <Multiselect
                          items={this.themeOptions}
                          v-model={this.themes}
                          placeholder={this.$t('app.events.placeholder.theme')}
                        />
                      </v-col>
                      <v-col cols='12' md='4'>
                        <Multiselect
                          items={this.packageOptions}
                          v-model={this.packages}
                          placeholder={this.$t(
                            'app.events.placeholder.package'
                          )}
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' md='auto' class='text-center'>
                <Button loading={this.loading} submit>
                  {this.$t('app.events.filter')}
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </form>
      </v-sheet>
    );
  }

  @Emit('filter')
  protected emitFilter(e?: Event): FilterEmit {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    return {
      from: this.from,
      to: this.to,
      foreignReps: this.foreignReps,
      packages: this.packages,
      themes: this.themes,
      types: this.types,
    };
  }
}
