import friendlyUrl from './friendlyUrl';
import guid from './guid';
import homepage from './homepage';
import preview from './preview';
import search from './search';
import venue from './venue';

const routes = {
  homepage,
  search,
  preview,
  venue,
  guid,
  friendlyUrl,
};

export { routes };

export default [homepage, search, preview, venue, guid, friendlyUrl];
