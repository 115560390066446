import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import { PrefetchComponent, Prefetch } from '~/mixins/prefetch';

export interface ComponentBaseInterface {
  getContent(): JSX.Element | JSX.Element[];
  getSettings?(): JSX.Element | JSX.Element[];
}

function callGetContent(vm: ComponentBase): JSX.Element | JSX.Element[] {
  const { getContent } = vm;
  return typeof getContent === 'function' ? getContent() : [];
}

function callGetSettings(
  vm: ComponentBaseInterface
): JSX.Element | JSX.Element[] {
  const { getSettings } = vm;
  return typeof getSettings === 'function' ? getSettings() : [];
}

export type ComponentBaseType = ComponentBaseInterface & PrefetchComponent;

@Component
export default class ComponentBase
  extends VueComponentMixin<ComponentBaseInterface, PrefetchComponent>(Prefetch)
  implements ComponentBaseInterface {
  public showSettings = false;

  public getContent() {
    return [];
  }

  public render() {
    const settings = callGetSettings(this);
    return (
      <v-row class='fill-height pa-0 ma-0'>
        <v-col class='pa-0'>{callGetContent(this)}</v-col>
        {(() => {
          if (
            (Array.isArray(settings) && settings.length > 0) ||
            (settings && !Array.isArray(settings))
          ) {
            return [
              <v-navigation-drawer
                color='secondary'
                dark
                stateless
                value={this.showSettings}
                right
                fixed={!this.showSettings}
              >
                <v-row class='fill-height ma-0'>
                  <v-col>{settings}</v-col>
                </v-row>
              </v-navigation-drawer>,
              <v-btn
                color='accent'
                fab
                dark
                small
                fixed
                bottom
                right
                onClick={() => {
                  this.showSettings = !this.showSettings;
                }}
                style={{ 'z-index': '1000' }}
              >
                <v-icon>mdi-tune</v-icon>
              </v-btn>,
            ];
          }
        })()}
      </v-row>
    );
  }
}
