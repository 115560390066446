import { Dictionary } from 'vue-router/types/router';
import { Action, Module, Mutation } from 'vuex-module-decorators';

import {
  BaseNavigationItem,
  NavigationFolder,
  NavigationItem,
} from '~/app/core/apiClient/api';
import toBoolean from '~/utils/toBoolean';
import { NavigationElement } from '~/utils/navigation';

import AbstractModule from './AbstractModule';
import { LinkTarget } from '~/utils/molecules';
import { isTargetEnum } from '~/utils/molecules/link';
import { ImageInterface } from '~/components/atoms/image/Image';

interface LoadNavigationInput {
  force?: boolean;
  locale: string;
  query?: Dictionary<string | (string | null)[]>;
}

type NavigationCommit = NavigationElement[];

enum NavigationLocation {
  FOOTER = '/NAVIGATION/FOOTER',
  HEADER = '/NAVIGATION/HEADER',
  MAIN = '/NAVIGATION/MAIN',
}

function isNavigationFolder(
  item: BaseNavigationItem
): item is NavigationFolder {
  return item.className === 'CZT.NavigationFolder';
}

function isNavigationItem(item: BaseNavigationItem): item is NavigationItem {
  return item.className === 'CZT.NavigationItem';
}

function isNavigationFolderOrItem(
  item: BaseNavigationItem
): item is NavigationFolder | NavigationItem {
  return isNavigationFolder(item) || isNavigationItem(item);
}

function recursivelyCreateNavigation(
  item: NavigationItem | NavigationFolder,
  parent?: NavigationFolder
): NavigationElement {
  if (isNavigationFolder(item)) {
    return {
      inline: item.isHorizontalMenu,
      items:
        item.items
          .filter(isNavigationFolderOrItem)
          .map((subItem) => recursivelyCreateNavigation(subItem, item)) || [],
      title: item.title,
    };
  }

  let target: LinkTarget | undefined;

  if (isTargetEnum(item.target)) {
    target = item.target;
  }

  let image: ImageInterface | undefined;
  if (item.image && (!parent || parent.isImageMenu)) {
    image = {
      src: item.image,
      alt: item.title,
    };
  }

  return {
    image,
    title: item.title,
    target,
    url: item.alternativeUrls.length > 0 ? item.alternativeUrls[0] : item.url,
  };
}

@Module({
  name: 'NavigationModule',
  stateFactory: true,
  namespaced: true,
})
export default class NavigationModule extends AbstractModule {
  public footer: NavigationElement[] = [];

  public header: NavigationElement[] = [];

  public loading: boolean = false;

  public main: NavigationElement[] = [];

  protected loadingPromise: Promise<void> | null = null;

  /**
   * Method to help us prevent menu reload on client side
   * @protected
   */
  protected get hasLoaded(): boolean {
    return this.main.length > 0 || this.footer.length > 0;
  }

  @Action({ rawError: true })
  public load(input: LoadNavigationInput): Promise<void> {
    if (this.loadingPromise) {
      return this.loadingPromise;
    }

    if (input.force !== true && this.hasLoaded) {
      return Promise.resolve();
    }

    this.setLoading(true);

    const { locale, query } = input;

    const isPreview =
      query &&
      query.hasOwnProperty('isPreview') &&
      typeof query.isPreview === 'string'
        ? toBoolean(query.isPreview)
        : undefined;

    const promise = this.$api
      .navigations()
      .navigationsGetNavigations('', locale, isPreview)
      .then((result) => {
        result.forEach((data) => {
          data.items.filter(isNavigationFolder).forEach((navigation) => {
            const menu = navigation.items
              .filter(isNavigationFolderOrItem)
              .map((item) => recursivelyCreateNavigation(item, navigation));

            switch (navigation.nodeAliasPath) {
              case NavigationLocation.MAIN:
                this.setMainNavigation(menu);
                break;
              case NavigationLocation.FOOTER:
                this.setFooterNavigation(menu);
                break;
              case NavigationLocation.HEADER:
                this.setHeaderNavigation(menu);
                break;
            }
          });
        });
      })
      .finally(() => {
        this.setLoading(false);
        this.setLoadingPromise(null);
      });

    this.setLoadingPromise(promise);
    return promise;
  }

  @Mutation
  protected setFooterNavigation(commit: NavigationCommit): void {
    this.footer = commit;
  }

  @Mutation
  protected setHeaderNavigation(commit: NavigationCommit): void {
    this.header = commit;
  }

  @Mutation
  protected setLoading(state: boolean): void {
    this.loading = state;
  }

  @Mutation
  protected setLoadingPromise(promise: Promise<void> | null): void {
    this.loadingPromise = promise;
  }

  @Mutation
  protected setMainNavigation(commit: NavigationCommit): void {
    this.main = commit;
  }
}
