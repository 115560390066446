import { Component, Ref, Vue } from 'vue-property-decorator';

import style from './SearchBar.scss';
import { getLocaleFromRouter } from '~/app/core/router';
import searchRoute from '~/app/core/router/routes/search';
import { VueComponent } from '~/utils/vue-component';
import { Button, Icons } from '~/components/atoms';

const rootClass = 'czt-search-bar';

@Component({
  style,
})
export default class SearchBar extends VueComponent<{}> {
  @Ref('searchbarSubmitBtn')
  private searchbarSubmitButton?: Vue;

  protected searchTerm: string = '';

  protected isOpened: boolean = false;

  public render() {
    return (
      <v-form class={rootClass} onSubmit={this.submitSearch}>
        <v-menu
          class='v-menu--attached'
          v-model={this.isOpened}
          content-class={`${rootClass}__dialog`}
          offset-y
          close-on-content-click={false}
          min-width='296px'
          attach={rootClass}
          left={true}
          nudge-left={35}
          scopedSlots={{
            activator: (scope: any) => {
              return (
                <a
                  class={`${rootClass}__button`}
                  title={this.$t('app.search.title')}
                  {...{
                    on: scope.on,
                  }}
                >
                  <Icons.common.Search size={2} />
                  <span class={`${rootClass}__label`}>
                    {this.$t('app.search.title')}
                  </span>
                </a>
              );
            },
          }}
        >
          <v-text-field
            class={`${rootClass}__input`}
            solo
            placeholder={this.$t('app.search.title')}
            v-model={this.searchTerm}
            autofocus
          >
            <Icons.common.Search slot='prepend-inner' />
          </v-text-field>

          <Button
            ref='searchbarSubmitBtn'
            class={`${rootClass}__submit`}
            thin
            submit
          >
            {this.$t('app.search.button')}
          </Button>
        </v-menu>
      </v-form>
    );
  }

  protected submitSearch(e: Event) {
    e.preventDefault();

    this.isOpened = false;

    if (this.searchTerm.trim() === '') {
      return;
    }

    const locale = getLocaleFromRouter(this.$router);

    this.$router.push({
      path: `/${locale}/${searchRoute.path.replace(':term', this.searchTerm)}`,
    });

    this.searchTerm = '';
  }
}
