import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import Arrow, { IconDirection } from './ArrowBase';

@Component
export default class ArrowRightIcon extends VueComponent<{}> {
  public render() {
    return <Arrow direction={IconDirection.RIGHT} />;
  }
}
