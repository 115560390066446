import APIClient from './Client';
import {
  CarouselWidget,
  GalleryWidget,
  GridWidget,
  HtmlContentWidget,
  IframeWidget,
  SliderWidget,
  SocialChannelWidget,
  CarouselWidgetItem,
  SliderWidgetItem,
  GridWidgetItem,
  EventtiaEventsWidget,
  SafeTravelsWidget,
  PanoramicPhotoWidget,
  NewsletterFormWidget,
  VenueFinderWidget,
  CertifiedSubjectsWidget,
} from '~/app/core/apiClient/api';
import { CztWidgets } from '~/utils/views/widgets';
import TabsWidget from '~/components/templates/common/TabsWidget';

export { APIClient };

export function isCarouselWidget(data: any): data is CarouselWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.CAROUSEL
  );
}

export function isGalleryWidget(data: any): data is GalleryWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.GALLERY
  );
}

export function isGridWidget(data: any): data is GridWidget {
  return data && typeof data === 'object' && data.className === CztWidgets.GRID;
}

export function isGridWidgetItem(data: any): data is GridWidgetItem {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.GRID_ITEM
  );
}

export function isHtmlWidget(data: any): data is HtmlContentWidget {
  return data && typeof data === 'object' && data.className === CztWidgets.HTML;
}

export function isIframeWidget(data: any): data is IframeWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.IFRAME
  );
}

export function isSocialWidget(data: any): data is SocialChannelWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.SOCIAL
  );
}

export function isSliderWidget(data: any): data is SliderWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.SLIDER
  );
}

export function isEventtiaEventsWidget(
  data: any
): data is EventtiaEventsWidget {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.EVENTTIA_EVENTS
  );
}

export function isCarouselWidgetItem(data: any): data is CarouselWidgetItem {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.CAROUSEL_ITEM
  );
}

export function isCarouselItem(data: any): data is CarouselWidgetItem {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.CAROUSEL_ITEM
  );
}

export function isNewsletterWidget(data: any): data is NewsletterFormWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.NEWSLETTER
  );
}

export function isSafeTravelsWidget(data: any): data is SafeTravelsWidget {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.SAFE_TRAVELS
  );
}

export function isCertifiedSubjectsWidget(
  data: any
): data is CertifiedSubjectsWidget {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.CERTIFIED_SUBJECTS
  );
}

export function isSliderWidgetItem(data: any): data is SliderWidgetItem {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.SLIDER_ITEM
  );
}

export function isSliderItem(data: any): data is SliderWidgetItem {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.SLIDER_ITEM
  );
}

export function isPanoramaWidget(data: any): data is PanoramicPhotoWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.PANORAMA
  );
}

export function isTabsWidget(data: any): data is TabsWidget {
  return data && typeof data === 'object' && data.className === CztWidgets.TABS;
}

export function isVenueFinderWidget(data: any): data is VenueFinderWidget {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.VENUE_FINDER
  );
}
