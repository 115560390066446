import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './List.scss';

interface ListInterface {
  items: string[];
}

@Component({
  style,
})
export default class List extends VueComponent<ListInterface>
  implements ListInterface {
  @Prop({ required: true, type: Array })
  public items!: string[];

  public render() {
    return <ul class='czt-list'>{this.getItems()}</ul>;
  }

  protected getItems(): JSX.Element[] {
    return this.items.map((item: string, key) => {
      return <li key={key.toString() + item}>{item}</li>;
    });
  }
}
