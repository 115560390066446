import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class UserIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--user'>
        <svg
          viewBox='0 0 25 25'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='12.5' cy='7' r='5' />
          <path d='M13.56 13h-2.12A9.47 9.47 0 0 0 2 22.5a.5.5 0 0 0 .5.5h20a.55.55 0 0 0 .5-.56A9.46 9.46 0 0 0 13.56 13z' />
        </svg>
      </i>
    );
  }
}
