export default {
  app: {
    panorama: {
      loadingText: 'Nahrávám panorama...',
      autorotate: 'Automatická rotace',
      zoom: 'Zvětšení',
      zoomOut: 'Oddálit',
      zoomIn: 'Přiblížít',
      move: 'Posunout',
      download: 'Stáhnout',
      fullscreen: 'Plná obrazovka',
      menu: 'Menu',
      twoFingers: 'K navigaci použijte dva prsty',
      ctrlZoom: 'Použijte ctrl + kolečko pro zvětšení',
      loadError: 'Panoramu se nepodařilo nahrát',
    },
  },
};
