import { Component, Prop } from 'vue-property-decorator';
import {
  isNavigationGroup,
  NavigationGroup as NavigationGroupModel,
} from '~/utils/navigation';
import { VueComponent } from '~/utils/vue-component';
import NavigationLink from '~/components/molecules/navigationLink/NavigationLink';
import style from './NavigationGroup.scss';
import { Icons } from '~/components/atoms';

interface NavigationGroupInterface {
  group: NavigationGroupModel;
  isNested?: boolean;
}

const rootClass = 'czt-menu-group';

@Component({ style })
export default class NavigationGroup
  extends VueComponent<NavigationGroupInterface>
  implements NavigationGroupInterface {
  @Prop({ required: true })
  public readonly group!: NavigationGroupModel;

  @Prop({ default: false })
  public readonly isNested!: boolean;

  public render() {
    return (
      <v-menu
        eager
        offset-y
        nudge-right={this.isNested ? 25 : undefined}
        nudge-bottom={this.isNested ? 10 : undefined}
        scopedSlots={{
          activator: (scope: any) => {
            return (
              <a
                class={rootClass}
                {...{
                  attrs: scope.attrs,
                  on: scope.on,
                }}
              >
                {this.group.title}
                {this.isNested && (
                  <Icons.common.ArrowRight
                    class={`${rootClass}__submenu-icon`}
                  />
                )}
              </a>
            );
          },
        }}
      >
        <v-list class={`${rootClass}__submenu`}>
          {this.group.items.map((child) => (
            <v-list-item>
              {isNavigationGroup(child) ? (
                <NavigationGroup group={child} isNested={true} />
              ) : (
                <NavigationLink link={child} />
              )}
            </v-list-item>
          ))}
        </v-list>
      </v-menu>
    );
  }
}
