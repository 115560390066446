import { RouteConfig } from 'vue-router';

import Search from '~/views/Search';

const config: RouteConfig = {
  component: Search,
  path: 'search/:term',
};

export default config;
