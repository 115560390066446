import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import HeadManagement from '~/mixins/HeadManagement';
import { Banner, SearchForm } from '~/components/organisms';
import imageHero from '~/assets/images/hero_search.jpg';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import SearchModule from '~/app/core/store/modules/SearchModule';
import { getModule } from 'vuex-module-decorators';
import { SearchItem } from '~/components/molecules';
import { CztWidgets } from '~/utils/views/widgets';
import { Button } from '~/components/atoms';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';

@Component
export default class Search extends VueComponentMixin<{}, PrefetchComponent>(
  HeadManagement,
  Prefetch
) {
  protected searchModule!: SearchModule;

  protected get bannerTitle(): string {
    return this.$t('app.search.title').toString();
  }

  protected get searchItems() {
    return this.searchModule.results;
  }

  protected get routeTerm() {
    return this.$route.params.term ? this.$route.params.term : '';
  }

  public created() {
    this.searchModule = getModule(SearchModule, this.$store);
  }

  public headTags(): string {
    return createSeoTags(
      `${this.routeTerm} · #VisitCzechRepublic`,
      '',
      '',
      TYPE.WEBSITE
    );
  }

  public prefetch() {
    return this.searchModule.search({
      fulltext: this.routeTerm,
      locale: this.$i18n.locale,
    });
  }

  public render() {
    return (
      <v-container fluid class='pa-0'>
        <Banner
          backgroundImage={{
            src: imageHero,
            alt: 'Search',
          }}
          title={this.bannerTitle}
          titleLevel={1}
          slim={true}
        >
          <v-row justify='center' no-gutters>
            <v-col cols='12' lg='8'>
              <SearchForm className={CztWidgets.SEARCH} term={this.routeTerm} />
            </v-col>
          </v-row>
        </Banner>
        <v-container class='czt-spacer'>
          <v-row justify='center'>
            <v-col cols='12' lg='8'>
              {(() => {
                let items: JSX.Element[] = [];

                if (this.searchItems.length > 0) {
                  items = items.concat(
                    this.searchItems.map((item) => {
                      return (
                        <SearchItem
                          key={
                            `search-item-${item.guid}` ||
                            `search-item-${item.subtitle}`
                          }
                          dates={item.dates}
                          image={item.image}
                          location={item.location}
                          path={item.path}
                          subtitle={item.subtitle}
                          text={item.text}
                          title={item.title}
                          type={item.type}
                        />
                      );
                    })
                  );
                  if (this.searchModule.hasNextPage) {
                    items.push(
                      <div class='text-center pt-3'>
                        <Button
                          onClick={this.loadMore}
                          loading={this.searchModule.loading}
                        >
                          {this.$t('app.common.loadMore')}
                        </Button>
                      </div>
                    );
                  }
                } else if (this.searchModule.loading) {
                  items.push(
                    <v-row justify='center'>
                      <v-col cols='12' md='8'>
                        <v-skeleton-loader type='article' />
                      </v-col>
                      <v-col cols='10' sm='7' md='4'>
                        <v-skeleton-loader type='image' />
                      </v-col>
                    </v-row>
                  );
                } else if (this.routeTerm !== '') {
                  items.push(<span>{this.$t('app.search.noResults')}</span>);
                }

                return items;
              })()}
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    );
  }

  protected loadMore() {
    this.searchModule.loadMore();
  }
}
