import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class StarIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--star'>
        <svg
          viewBox='0 0 30 30'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M15.041 3.83l3.787 7.786 8.577 1.197-6.236 6.008 1.514 8.525-7.642-4.073-7.642 4.073 1.514-8.525-6.236-6.008 8.577-1.197L15.04 3.83zm-2.455 9.62l-5.56.775 4.043 3.896-.982 5.526 4.954-2.64 4.954 2.64-.982-5.526 4.042-3.896-5.559-.775-2.455-5.048-2.455 5.048z'
            fill='currentColor'
          />
        </svg>
      </i>
    );
  }
}
