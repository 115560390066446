import Vuex from 'vuex';
import Vue from 'vue';
import { modules } from '~/app/core/store/modules';

Vue.use(Vuex);

export default function createStore() {
  return new Vuex.Store({
    modules,
  });
}
