import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { Link } from '~/components/molecules';
import { hostname } from 'os';
import { LinkTarget } from '~/utils/molecules';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  public getContent() {
    return [
      <div style='text-align: center'>
        <Link url='http://www.google.com'>
          This is link to external content
        </Link>
        <br />
        <Link url={'https://' + hostname() + '/Destinations/Third'}>
          This is link to internal content
        </Link>
        <br />
        <Link url='~/Destinations/Prvni' target={LinkTarget.PARENT}>
          This is internal link with target
        </Link>
        <br />
        <Link url='~/Destinations/Druha'>
          This is internal link without target
        </Link>
        <br />
        <Link url={'http://www.idnes.cz?utm_medium=' + hostname()}>
          This is external link with internal domain as parameter
        </Link>
      </div>,
    ];
  }
  public getSettings() {
    return [];
  }
}
