import { Component, Prop } from 'vue-property-decorator';
import { NavigationLink as NavigationLinkModel } from '~/utils/navigation';
import { VueComponent } from '~/utils/vue-component';
import { Link } from '~/components/molecules';
import style from './NavigationLink.scss';

interface NavigationLinkInterface {
  link: NavigationLinkModel;
}

const rootClass = 'czt-menu-link';

@Component({ style })
export default class NavigationLink
  extends VueComponent<NavigationLinkInterface>
  implements NavigationLinkInterface {
  @Prop({ required: true })
  public link!: NavigationLinkModel;

  public render() {
    return (
      <Link
        class={rootClass}
        url={`${this.link.url}`}
        target={this.link.target}
      >
        {this.link.title}
      </Link>
    );
  }
}
