import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class LocationIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--location'>
        <svg
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M9.667 1.73a6.559 6.559 0 016.556 6.556c0 3.416-1.444 4.724-6.556 9.984-5.495-5.665-6.555-6.366-6.555-9.984A6.558 6.558 0 019.667 1.73zm0 14.39c1.457-1.497 2.553-2.631 3.36-3.608 1.243-1.506 1.696-2.55 1.696-4.226A5.058 5.058 0 009.667 3.23a5.058 5.058 0 00-5.055 5.056c0 1.757.313 2.663 1.521 4.098.808.959 1.947 2.111 3.534 3.736zm0-10.961a3.128 3.128 0 013.127 3.127 3.128 3.128 0 01-3.127 3.126 3.128 3.128 0 01-3.126-3.126 3.128 3.128 0 013.126-3.127zm0 1.5a1.628 1.628 0 010 3.253 1.627 1.627 0 010-3.253z'
            fill='currentColor'
          />
        </svg>
      </i>
    );
  }
}
