import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class CaptionsOnIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--captions-on'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 17.9'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path d='M17.8 0H2.2C.9 0-.1 1-.1 2.2v13.4c0 1.2 1 2.2 2.2 2.2h15.7c1.2 0 2.2-1 2.2-2.2V2.2C20.1 1 19.1 0 17.8 0zM8.9 7.8H7.2v-.5H5v3.4h2.2v-.6h1.7v1.1c0 .7-.4 1.1-1.1 1.1H4.4c-.7 0-1.1-.4-1.1-1.1V6.7c0-.7.4-1.1 1.1-1.1h3.4c.7 0 1.1.4 1.1 1.1v1.1zm7.8 0H15v-.5h-2.2v3.4H15v-.6h1.7v1.1c0 .7-.4 1.1-1.1 1.1h-3.4c-.7 0-1.1-.4-1.1-1.1V6.7c0-.7.4-1.1 1.1-1.1h3.4c.7 0 1.1.4 1.1 1.1v1.1z' />
        </svg>
      </i>
    );
  }
}
