import { RouteConfig } from 'vue-router';

import DynamicResource from '~/views/DynamicResource';

const config: RouteConfig = {
  component: DynamicResource,
  path: '*',
  meta: {
    friendlyUrl: true,
  },
};

export default config;
