export default {
  app: {
    certifiedSubjects: {
      search: 'Name',
      areas: 'Area',
      regions: 'Region',
      button: 'Search',
      table: {
        name: 'Subject name',
        category: 'Tourism area',
        area: 'Region',
      },
      areaTranslations: {
        cestovní_kancelář: 'Tour Operator',
        cestovní_agentura: 'Travel Agent',
        informační_centrum: 'Information Center',
        'kemp/chatová_osada': 'Campsite/Cottage Settlement',
        'ski_areál/lanová_dráha': 'Ski Resort/Cable Car',
        horská_průvodcovská_činnost: 'Mountain Leader Service',
        'bazén/sauna': 'Swimming pool/Sauna',
        fitness: 'Fitness',
        ubytovací_zařízení: 'Accommodation',
      },
      regionTranslations: {
        ústecky_kraj: 'Ústí Region',
        praha: 'Prague',
        jihočeský_kraj: 'South Bohemian Region',
        středočeský_kraj: 'Central Bohemian Region',
        plzeňský_kraj: 'Plzeň Region',
        karlovarský_kraj: 'Karlovy Vary Region',
        liberecký_kraj: 'Liberec Region',
        královéhradecký_kraj: 'Hradec Králové Region',
        pardubický_kraj: 'Pardubice Region',
        kraj_vysočina: 'Vysočina Region',
        jihomoravský_kraj: 'South Moravian Region',
        olomoucký_kraj: 'Olomouc Region',
        zlínský_kraj: 'Zlín Region',
        moravskoslezský_kraj: 'Moravian-Silesian Region',
      },
    },
  },
};
