import ArrowDown from '~/components/atoms/icons/common/ArrowDown';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ArrowUp from '~/components/atoms/icons/common/ArrowUp';
import CaptionsOff from './CaptionsOff';
import CaptionsOn from './CaptionsOn';
import Calendar from './Calendar';
import Close from './Close';
import Dropdown from './Dropdown';
import Language from './Language';
import Location from './Location';
import MapPin from './MapPin';
import Menu from './Menu';
import Mute from './Mute';
import Pause from './Pause';
import Play from './Play';
import Search from './Search';
import SliderIndicator from './SliderIndicator';
import SliderIndicatorActive from './SliderIndicatorActive';
import Star from './Star';
import ThumbDown from './ThumbDown';
import ThumbUp from './ThumbUp';
import Unmute from './Unmute';
import User from './User';

export default {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Calendar,
  CaptionsOff,
  CaptionsOn,
  Close,
  Dropdown,
  Language,
  Location,
  MapPin,
  Menu,
  Mute,
  Pause,
  Play,
  Search,
  SliderIndicator,
  SliderIndicatorActive,
  Star,
  ThumbDown,
  ThumbUp,
  Unmute,
  User,
};
