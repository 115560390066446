import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { SliderItemInterface } from '~/components/organisms/slider/Slider';
import { Slider } from '~/components/organisms';
import { CztWidgets } from '~/utils/views/widgets';
import { ThemeRatios } from '~/utils/theme';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected ratio: ThemeRatios | null = null;
  protected contentWidth: boolean = false;

  protected get bannerItems(): SliderItemInterface[] {
    return [
      {
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan mauris leo, sed bibendum elit blandit eget. Aliquam erat volutpat. Donec sollicitudin libero eu faucibus finibus.',
        title: 'First title',
        backgroundImage: {
          src: 'http://picsum.photos/1920/1080',
          alt: 'Wide Picture',
        },
        socialButtons: 'SHARE ON SOCIAL',
      },
      {
        content:
          'Cras blandit in tellus quis imperdiet. Etiam sollicitudin, nisi ac sollicitudin facilisis, nunc lectus condimentum libero, et feugiat justo nisl at metus.',
        title: 'Second title',
        backgroundImage: {
          src: 'http://picsum.photos/1920/1080',
          alt: 'Wide Picture',
        },
        sideImage: {
          src: 'http://picsum.photos/1920/1080',
          alt: 'Wide Picture',
        },
        superTitle: 'PLACES TO VISIT',
      },
    ];
  }

  public getContent() {
    return [
      <Slider
        className={CztWidgets.SLIDER}
        items={this.bannerItems}
        contentWidth={this.contentWidth}
        ratio={this.ratio || undefined}
      />,
    ];
  }
  public getSettings() {
    return [
      <v-select items={this.getRatios()} v-model={this.ratio} />,
      <v-switch
        label='Content Width'
        color='accent'
        v-model={this.contentWidth}
      />,
    ];
  }

  protected getRatios() {
    const ratios = [];
    for (const ratio in ThemeRatios) {
      if (isNaN((ratio as unknown) as number)) {
        ratios.push({ text: ratio, value: ThemeRatios[ratio] });
      }
    }
    ratios.push({ text: 'none', value: null });
    return ratios;
  }
}
