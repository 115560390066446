export default {
  app: {
    cookies: {
      cookieBarText:
        'K personalizaci obsahu, analýze návštěvnosti a poskytování funkcí sociálních médií využíváme soubory cookie. Informace o tom, jak náš web používáte, sdílíme se svými partnery pro sociální média a analýzy. Partneři tyto údaje mohou zkombinovat s dalšími informacemi, které jste jim poskytli nebo které získali v důsledku toho, že používáte jejich služby. <a href="~/cookies">Více informací</a>.',
      buttons: {
        accept: 'Souhlasím, přijímám vše',
        save: 'Uložit vybrané',
      },
      settings: {
        button: 'Nastavení',
        required: 'Nutné (ke správné funkci stránek)',
        statistics:
          'Statistické (k porozumění, jak návštěvníci používají webové stránky)',
        marketing:
          'Marketingové (ke sledování návštěvníků za účelem zobrazit reklamu)',
        reject: 'Odmítnout vše',
        accept: 'Přijmout vše',
      },
    },
  },
};
