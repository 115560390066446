import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

interface SliderIndicatorInterface {
  active?: boolean;
}

@Component
export default class SliderIndicatorBaseIcon
  extends VueComponent<SliderIndicatorInterface>
  implements SliderIndicatorInterface {
  @Prop({ default: false, type: Boolean })
  public active!: boolean;

  public render() {
    const fillOpacity = this.active ? 1 : 0;
    return (
      <i class={'czt-slider-indicator'}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 16 16'
        >
          <defs />
          <path
            fill='currentColor'
            d='M7.508.984a7.511 7.511 0 017.508 7.508A7.511 7.511 0 017.508 16 7.511 7.511 0 010 8.492 7.511 7.511 0 017.508.984zm0 1.5a6.01 6.01 0 016.008 6.008A6.01 6.01 0 017.508 14.5 6.01 6.01 0 011.5 8.492a6.01 6.01 0 016.008-6.008z'
          />
          <circle
            cx='420'
            cy='412'
            r='4.508'
            fill='currentColor'
            fill-opacity={fillOpacity}
            transform='translate(-412.492 -403.508)'
          />
        </svg>
      </i>
    );
  }
}
