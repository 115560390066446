import CookieSettingsModule from './CookieSettingsModule';
import CertifiedSubjectsModule from './CertifiedSubjectsModule';
import DomainModule from './DomainModule';
import EventCategoriesModule from './EventCategoriesModule';
import FacebookSdkModule from './FacebookSdkModule';
import LanguageModule from './LanguageModule';
import NavigationModule from './NavigationModule';
import RecaptchaModule from './RecaptchaModule';
import RouterModule from './RouterModule';
import SafeTravelsModule from './SafeTravelsModule';
import SearchModule from './SearchModule';
import TranslationModule from './TranslationModule';
import VenueModule from './VenueModule';
import VenueFinderModule from './VenueFinderModule';
import YoutubeVideosModule from './YoutubeVideosModule';

const modules: { [s: string]: any } = {
  CookieSettingsModule,
  CertifiedSubjectsModule,
  DomainModule,
  EventCategoriesModule,
  FacebookSdkModule,
  LanguageModule,
  NavigationModule,
  RecaptchaModule,
  RouterModule,
  SafeTravelsModule,
  SearchModule,
  TranslationModule,
  VenueModule,
  VenueFinderModule,
  YoutubeVideosModule,
};

export { modules };
