import { Component, Prop } from 'vue-property-decorator';
import { Headline, Image } from '~/components/atoms';
import { Link } from '~/components/molecules';
import { Align } from '~/components/atoms/headline/Headline';
import { isNavigationGroup, NavigationElement } from '~/utils/navigation';
import { VueComponent } from '~/utils/vue-component';
import style from './NavigationMenu.scss';
import { TranslateResult } from 'vue-i18n';

interface NavigationFooterMenuInterface {
  inline?: boolean;
  items: NavigationElement[];
  title: string | TranslateResult;
}

const rootClass = 'czt-navigation-menu';

@Component({ style })
export default class NavigationMenu
  extends VueComponent<NavigationFooterMenuInterface>
  implements NavigationFooterMenuInterface {
  @Prop({ default: false })
  public readonly inline!: boolean;

  @Prop({ required: true })
  public readonly items!: NavigationElement[];

  @Prop({ required: true })
  public readonly title!: string | TranslateResult;

  protected get classNames() {
    const inlineClassName = `${rootClass}--inline`;

    return {
      [rootClass]: true,
      [inlineClassName]: this.inline,
    };
  }

  public render() {
    return (
      <div class={this.classNames}>
        <Headline underscore align={Align.LEFT}>
          {this.title}
        </Headline>
        <ul class={`${rootClass}__list`}>
          {this.items.map((item, index) => {
            if (isNavigationGroup(item)) {
              return (
                <NavigationMenu
                  inline={item.inline}
                  items={item.items}
                  title={item.title}
                  key={index}
                />
              );
            }

            return (
              <li key={index}>
                <Link url={item.url} target={item.target}>
                  {item.image ? (
                    <Image src={item.image.src} alt={item.image.alt} />
                  ) : (
                    item.title
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
