import { Component, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './NewsletterForm.scss';

import { MediaBackground, Link } from '~/components/molecules';
import imageHeroDefault from '~/assets/images/hero_default.jpg';
import { Headline, InputField, Button } from '~/components/atoms';
import { InputFieldState } from '~/components/atoms/inputField/InputField';
import { Align } from '~/components/atoms/headline/Headline';
import HtmlRenderer from '../base/HtmlRenderer';
import { CztWidgets } from '~/utils/views/widgets';
import Checkbox from '~/components/atoms/checkbox/Checkbox';
import { PageLoader } from '.';
import { LocaleMessage } from 'vue-i18n';
import appEnv from '~/app/core/appEnv';
import RecaptchaWrapper from '../recaptchaWrapper/RecaptchaWrapper';

interface Newsletter {
  name: string;
  guid: string;
  title: string;
}

export interface NewsletterFormInterface {
  className: CztWidgets;
  guid: string;
}

export function isNewsletterForm(data: any): data is NewsletterFormInterface {
  return data && data.className === CztWidgets.NEWSLETTER;
}

const rootClass = 'czt-newsletter';

@Component({
  style,
})
export default class NewsletterForm
  extends VueComponent<NewsletterFormInterface>
  implements NewsletterFormInterface {
  @Prop({ required: true })
  public className!: CztWidgets;

  @Prop({ required: true })
  public guid!: string;

  @Ref('newsletterInput')
  public readonly newsletterInput!: InputField;

  protected value: string = '';

  protected loading: boolean = false;

  protected error: string | LocaleMessage = '';

  protected success: string | LocaleMessage = '';

  protected dialog: boolean = false;

  protected newsletters: Newsletter[] = [];

  protected selectedNewsletters: string[] = [];

  protected newslettersLoading: boolean = false;

  public get state(): InputFieldState {
    if (this.loading) {
      return InputFieldState.LOADING;
    }
    if (this.error !== '') {
      return InputFieldState.ERROR;
    }
    if (this.success !== '') {
      return InputFieldState.SUCCESS;
    }
    return InputFieldState.DEFAULT;
  }

  public get title(): LocaleMessage {
    return this.$t('app.newsletter.title');
  }

  public render() {
    return (
      <MediaBackground
        class={rootClass}
        image={{
          src: imageHeroDefault,
          alt: this.title,
        }}
        key={this.guid}
      >
        <v-container class='czt-spacer'>
          <v-row>
            <v-col cols='12'>
              <Headline underscore level={2} light align={Align.CENTER}>
                {this.title}
              </Headline>
            </v-col>
            <v-col class='text-center' cols='12'>
              <HtmlRenderer content={this.$t('app.newsletter.topText')} light />
            </v-col>
            <v-col cols='12'>
              <v-form class={`${rootClass}__form`} onSubmit={this.handleSubmit}>
                <v-row>
                  <v-col>
                    <InputField
                      errorMessage={this.error}
                      placeholder={this.$t('app.newsletter.inputPlaceholder')}
                      v-model={this.value}
                      state={this.state}
                      successMessage={this.success}
                      type='email'
                      ref='newsletterInput'
                      required
                    />
                  </v-col>
                  <v-col class='text-center' cols='12' md='auto'>
                    <Button
                      submit
                      loading={this.loading}
                      style='height: 56px !important'
                    >
                      {this.$t('app.newsletter.button')}
                    </Button>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col class='text-center' cols='12'>
              <HtmlRenderer
                content={this.$t('app.newsletter.bottomText')}
                light
              />
            </v-col>
          </v-row>
        </v-container>
        <v-dialog content-class={`${rootClass}__dialog`} v-model={this.dialog}>
          <v-card>
            <v-container>
              <v-row>
                <v-col cols='12'>
                  <Headline underscore align={Align.CENTER}>
                    {this.$t('app.newsletter.dialog.headline')}
                  </Headline>
                </v-col>
                <v-col cols='12'>{this.createNewsletterList()}</v-col>
                <v-col class={`${rootClass}__dialog__notice`}>
                  <i18n tag='div' path='app.newsletter.dialog.notice'>
                    <Link url={this.$t('app.newsletter.dialog.url.privacy')}>
                      {this.$t('app.newsletter.dialog.link.privacy')}
                    </Link>
                  </i18n>
                  <i18n
                    class='mt-2'
                    tag='div'
                    path='app.newsletter.dialog.company'
                  >
                    <Link url={this.$t('app.newsletter.dialog.url.controller')}>
                      {this.$t('app.newsletter.dialog.link.controller')}
                    </Link>
                    <Link url={this.$t('app.newsletter.dialog.url.web')}>
                      {this.$t('app.newsletter.dialog.link.web')}
                    </Link>
                  </i18n>
                </v-col>
                <v-col class='text-center' cols='auto'>
                  <RecaptchaWrapper
                    {...{
                      scopedSlots: {
                        default: (scope: any) => {
                          return (
                            <Button
                              disabled={this.selectedNewsletters.length < 1}
                              loading={this.loading}
                              {...{
                                on: scope.on,
                              }}
                            >
                              {this.$t('app.newsletter.button')}
                            </Button>
                          );
                        },
                      },
                    }}
                    onRecaptchaCallback={this.sendSubscription}
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </MediaBackground>
    );
  }

  protected handleSubmit(e: Event) {
    e.preventDefault();
    if (!this.newsletterInput.checkValidity()) {
      this.error = this.$t('app.newsletter.validityError');
      return;
    }
    this.error = '';
    this.openDialog();
  }

  protected openDialog() {
    if (this.newsletters.length === 0) {
      this.newslettersLoading = true;
      this.$api
        .newsletters()
        .newsletterGetNewsletters()
        .then((response) => {
          const newsletters: Newsletter[] = [];
          response.forEach((newsletter) => {
            if (
              newsletter.newsletterDisplayName &&
              newsletter.newsletterGUID &&
              newsletter.newsletterName
            ) {
              newsletters.push({
                title: newsletter.newsletterDisplayName,
                guid: newsletter.newsletterGUID,
                name: newsletter.newsletterName,
              });
            }
          });
          this.newsletters = newsletters;
        })
        .finally(() => {
          this.newslettersLoading = false;
        });
    }
    this.dialog = true;
  }

  protected closeDialog() {
    this.dialog = false;
  }

  protected getNewsletters() {
    if (this.newsletters.length > 0) {
      return this.newsletters.map((newsletter) =>
        this.createNewsletterButton(newsletter)
      );
    } else {
      return;
    }
  }

  protected createNewsletterList() {
    if (this.newslettersLoading) {
      return <PageLoader />;
    }
    if (this.newsletters.length < 1) {
      return <div>{this.$t('app.newsletter.newsletterError')}</div>;
    } else {
      return (
        <v-item-group multiple v-model={this.selectedNewsletters}>
          <v-row no-gutters>{this.getNewsletters()}</v-row>
        </v-item-group>
      );
    }
  }

  protected createNewsletterButton(newsletter: Newsletter) {
    return (
      <v-col class='pa-1' cols='6' md='4' lg='3' key={newsletter.guid}>
        <v-item
          value={newsletter.name}
          {...{
            scopedSlots: {
              default: ({
                active,
                toggle,
              }: {
                active: boolean;
                toggle: () => void;
              }) => {
                const itemClasses: string[] = [
                  `${rootClass}__dialog__item`,
                  'd-flex',
                  'align-center',
                ];
                if (active) {
                  itemClasses.push(`${rootClass}__dialog__item--active`);
                }
                return (
                  <div
                    class={itemClasses.join(' ')}
                    onClick={() => {
                      toggle();
                    }}
                  >
                    <Checkbox
                      readOnly
                      value={active}
                      label={newsletter.title}
                    />
                  </div>
                );
              },
            },
          }}
        />
      </v-col>
    );
  }

  protected sendSubscription(token: string) {
    this.loading = true;
    return this.$api
      .newsletters()
      .newsletterSubscribe(
        {
          email: this.value,
          newsletterNames: this.selectedNewsletters,
          reCaptchaToken: token,
        },
        {
          headers: {
            'X-API-Key': appEnv.NEWSLETTER_API_KEY,
          },
        }
      )
      .then((response) => {
        this.error = '';
        this.success = this.$t('app.newsletter.success');
      })
      .catch(() => {
        this.error = this.$t('app.newsletter.serverError');
      })
      .finally(() => {
        this.loading = false;
        this.closeDialog();
      });
  }
}
