import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { ImageWrapper } from '~/components/molecules';
import { ImageInterface } from '~/components/atoms/image/Image';
import { ThemeRatios } from '~/utils/theme';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';

const sizes: Sizes[] = [
  {
    size: 100,
    unit: 'vw',
  },
];

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected image: ImageInterface = {
    src: 'http://picsum.photos/1920/1080',
    alt: 'Wide Picture',
  };

  protected ratio: ThemeRatios = ThemeRatios['16x9'];

  public getContent() {
    return <ImageWrapper image={this.image} ratio={this.ratio} sizes={sizes} />;
  }
  public getSettings() {
    return [<v-select items={this.getRatios()} v-model={this.ratio} />];
  }

  protected getRatios() {
    const ratios = [];
    for (const ratio in ThemeRatios) {
      if (isNaN((ratio as unknown) as number)) {
        ratios.push({ text: ratio, value: ThemeRatios[ratio] });
      }
    }
    return ratios;
  }
}
