export default {
  app: {
    certifiedSubjects: {
      search: 'Název',
      areas: 'Oblast',
      regions: 'Region',
      button: 'Hledat',
      table: {
        name: 'Název subjektu',
        category: 'Oblast v cestovním ruchu',
        area: 'Region',
      },
    },
  },
};
