import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { GalleryItem } from '~/components/molecules';
import { ImageInterface } from '~/components/atoms/image/Image';
import { IconName } from '~/components/atoms/icons/Icon';
import { Icons } from '~/components/atoms';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected title: string = 'Czech Switzerland';
  protected subtitle?: string = 'Ústecký kraj';
  protected iconName?: IconName = ['common', 'Location'];
  protected showIcon?: boolean = true;

  protected image: ImageInterface = {
    src: 'http://picsum.photos/1920/1080',
    alt: 'Wide Picture',
  };

  public getContent() {
    return [
      <v-card>
        <GalleryItem
          title={this.title}
          subtitle={this.subtitle}
          image={this.image}
          icon={this.showIcon ? this.iconName : undefined}
        />
      </v-card>,
    ];
  }

  public getSettings() {
    const iconsList = [];
    for (const category in Icons) {
      if (!Icons.hasOwnProperty(category)) {
        continue;
      }
      const categoryIcons = (Icons as { [key: string]: any })[category];
      for (const iconKey in categoryIcons) {
        if (!categoryIcons.hasOwnProperty(iconKey)) {
          continue;
        }
        iconsList.push({ value: [category, iconKey], text: iconKey });
      }
    }

    return [
      <v-select
        items={iconsList}
        filled
        label='Generic icon type'
        v-model={this.iconName}
      />,
      <v-switch label='Disabled icon' color='accent' v-model={this.iconName} />,
      <v-text-field
        label='title'
        v-model={this.title}
        hide-details='auto'
        clearable
      />,
      <v-text-field
        label='subtitle'
        v-model={this.subtitle}
        hide-details='auto'
        clearable
      />,
    ];
  }
}
