import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VueComponent } from '~/utils/vue-component';

import YoutubeVideosModule from '~/app/core/store/modules/YoutubeVideosModule';
import style from './Video.scss';
import youtubeApiLoader from '~/utils/youtubeApiLoader';
import Player = YT.Player;
import PlayerOptions = YT.PlayerOptions;
import uuid from '~/utils/uuid';

export interface VideoInterface {
  videoId: string;
  height?: number;
  autoPlay?: boolean;
  showControls?: boolean;
  src?: string;
  loop?: boolean;
  modestBranding?: boolean;
  fullScreenButton?: boolean;
  disableKeyboard?: boolean;
  mute?: boolean;
  onPlaying?: () => void;
  onPaused?: () => void;
  onEnded?: () => void;
  onMuted?: () => void;
  onUnmuted?: () => void;
}

const rootClass = 'czt-video';

@Component({
  style,
})
export default class Video extends VueComponent<VideoInterface>
  implements VideoInterface {
  @Prop({ required: true, type: String })
  public videoId!: string;

  @Prop({ default: true, type: Boolean })
  public autoPlay!: boolean;

  @Prop({ default: true, type: Boolean })
  public showControls!: boolean;

  @Prop({ type: String })
  public src?: string;

  @Prop({ default: false, type: Boolean })
  public loop!: boolean;

  @Prop({ default: false, type: Boolean })
  public modestBranding!: boolean;

  @Prop({ default: true, type: Boolean })
  public fullScreenButton!: boolean;

  @Prop({ default: false, type: Boolean })
  public disableKeyboard!: boolean;

  @Prop({ type: Number })
  public height?: number;

  @Prop({ default: false, type: Boolean })
  public mute?: boolean;

  protected elementId: string = '';

  protected player: Player | null = null;

  protected playerOptions!: PlayerOptions;

  /**
   * For now we will only use 16/9 aspect ratio.
   * It can be eventually calculated using youtube oembed endpoint
   */
  protected aspectRatio: number = 16 / 9;

  protected isMuted: boolean = false;

  protected isPlaying: boolean = false;

  public created() {
    const youtubeVideoModule = getModule(YoutubeVideosModule, this.$store);
    this.elementId = youtubeVideoModule.videoIds[this.videoId] || uuid();
    youtubeVideoModule.setVideoId({ url: this.videoId, id: this.elementId });
    this.setPlayerOptions();
  }

  public mounted() {
    youtubeApiLoader()
      .then(() => {
        if (typeof window.YT !== 'undefined') {
          this.player = new YT.Player(this.elementId, this.playerOptions);
        }
      })
      .catch(() => {
        // TODO: We should show an error message to the user about an unsupported video or smth
      });
  }

  public beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  }

  public render() {
    return (
      <div class={rootClass} data-element-id={this.elementId}>
        <div id={this.elementId} />
      </div>
    );
  }

  public toggleMute() {
    if (this.isMuted) {
      this.unmuteVideo();
    } else {
      this.muteVideo();
    }
  }

  public togglePlayback() {
    if (this.isPlaying) {
      this.pauseVideo();
    } else {
      this.playVideo();
    }
  }

  @Watch('height')
  protected setDimensions() {
    if (this.player) {
      let width;
      let height;

      if (!this.height) {
        // Calculate the size from the wrapper
        // First set a 100% iframe width
        this.player.getIframe().style.width = '100%';

        // Now lets take the width in pixels and calculate height using AR
        width = this.player.getIframe().clientWidth;
        height = width / this.aspectRatio;
      } else {
        // If the height is provided from Prop, calculate the width to keep AR
        width = this.height * this.aspectRatio;
        height = this.height;
      }

      // Set the size in youtube player so it knows correct quality to use
      this.player.setSize(width, height);
      // Set the iframe size to overload the css
      this.player.getIframe().style.width = `${width}px`;
      this.player.getIframe().style.height = `${height}px`;
    } else {
      if (this.height) {
        this.playerOptions.width = this.height * this.aspectRatio;
        this.playerOptions.height = this.height;
      }
    }
  }

  protected setPlayerOptions() {
    this.playerOptions = {
      width: this.height ? this.height * this.aspectRatio : '100%',
      height: this.height ? this.height : undefined,
      videoId: this.videoId,
      host: 'https://www.youtube-nocookie.com',
      playerVars: {
        autoplay: this.autoPlay ? 1 : 0,
        controls: this.showControls ? 1 : 0,
        disablekb: this.disableKeyboard ? 1 : 0,
        fs: this.fullScreenButton ? 1 : 0,
        loop: this.loop ? 1 : 0,
        modestbranding: this.modestBranding ? 1 : 0,
        playsinline: 1,
        showinfo: 0,
      },
      events: {
        onStateChange: (e) => {
          switch (e.data) {
            case -1:
              this.unstarted();
              break;
            case 0:
              this.ended();
              break;
            case 1:
              this.playing();
              break;
            case 2:
              this.paused();
              break;
          }
        },
        onReady: this.ready,
      },
    };
  }

  /**
   * The video is loaded
   */
  protected ready() {
    if (!this.player) {
      return;
    }

    this.setDimensions();

    if (this.autoPlay || this.mute) {
      this.muteVideo();
    }

    if (this.autoPlay) {
      this.playVideo();
    }
  }

  /**
   * Request for the playback was made, but the video has not started yet
   */
  protected unstarted() {
    // Before video plays
  }

  /**
   * Video started playing/was resumed after being paused
   */
  protected playing() {
    // When the playback actually starts
    this.$emit('playing');
  }

  /**
   * When the video finishes
   */
  protected ended() {
    if (this.loop && this.player) {
      // Lazy looping without using playlist hack
      this.player.seekTo(0, false);
      this.player.playVideo();
    } else {
      this.$emit('ended');
    }
  }

  /**
   * When the video gets paused
   */
  protected paused() {
    // Video stopped playing on pause request
    this.$emit('paused');
  }

  protected muteVideo() {
    if (this.player) {
      this.isMuted = true;
      this.player.mute();
      this.$emit('muted');
    }
  }

  protected unmuteVideo() {
    if (this.player) {
      this.isMuted = false;
      this.player.unMute();
      this.$emit('unmuted');
    }
  }

  protected playVideo() {
    if (this.player) {
      this.isPlaying = true;
      this.player.playVideo();
    }
  }

  protected pauseVideo() {
    if (this.player) {
      this.isPlaying = false;
      this.player.pauseVideo();
    }
  }
}
