import { Action, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import { ItemData } from '~/components/atoms/select/Select';

type GetTypesResultCommit = ItemData[];
type GetThemesResultCommit = ItemData[];
type GetPackagesResultCommit = ItemData[];

@Module({
  name: 'EventCategoriesModule',
  stateFactory: true,
  namespaced: true,
})
export default class EventCategoriesModule extends AbstractModule {
  public typesLoading: boolean = false;
  public themesLoading: boolean = false;
  public packagesLoading: boolean = false;

  public types: ItemData[] = [];
  public themes: ItemData[] = [];
  public packages: ItemData[] = [];

  @Action({ commit: 'setTypes', rawError: true })
  public getTypes(locale: string): Promise<GetTypesResultCommit> {
    this.setTypesLoading(true);

    return this.$api
      .events()
      .eventtiaEventsGetEventtiaEventTypes(locale)
      .then((response) => {
        return response.map((item) => {
          return {
            value: item.eventtiaEventTypeGuid || '',
            text: item.eventtiaEventTypeName || '',
          };
        });
      })
      .catch(() => {
        return [];
      })
      .finally(() => {
        this.setTypesLoading(false);
      });
  }

  @Action({ commit: 'setThemes', rawError: true })
  public getThemes(locale: string): Promise<GetThemesResultCommit> {
    this.setThemesLoading(true);

    return this.$api
      .events()
      .eventtiaEventsGetEventtiaEventThemes(locale)
      .then((response) => {
        return response.map((item) => {
          return {
            value: item.eventtiaEventThemeGuid || '',
            text: item.eventtiaEventThemeName || '',
          };
        });
      })
      .catch(() => {
        return [];
      })
      .finally(() => {
        this.setThemesLoading(false);
      });
  }

  @Action({ commit: 'setPackages', rawError: true })
  public getPackages(locale: string): Promise<GetPackagesResultCommit> {
    this.setPackagesLoading(true);

    return this.$api
      .events()
      .eventtiaEventsGetEventtiaEventPackages(locale)
      .then((response) => {
        return response.map((item) => {
          return {
            value: item.eventtiaEventPackageGuid || '',
            text: item.eventtiaEventPackageName || '',
          };
        });
      })
      .catch(() => {
        return [];
      })
      .finally(() => {
        this.setPackagesLoading(false);
      });
  }

  @Mutation
  public setTypesLoading(state: boolean) {
    this.typesLoading = state;
  }
  @Mutation
  public setThemesLoading(state: boolean) {
    this.themesLoading = state;
  }
  @Mutation
  public setPackagesLoading(state: boolean) {
    this.packagesLoading = state;
  }

  @Mutation
  public setTypes(data: GetTypesResultCommit) {
    this.types = data;
  }
  @Mutation
  public setThemes(data: GetThemesResultCommit) {
    this.themes = data;
  }
  @Mutation
  public setPackages(data: GetPackagesResultCommit) {
    this.packages = data;
  }
}
