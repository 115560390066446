import { RouteConfig } from 'vue-router';

import DynamicResource from '~/views/DynamicResource';

const config: RouteConfig = {
  component: DynamicResource,
  path: ':guid(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})/*',
};

export default config;
