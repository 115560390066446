import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { ThemeColors } from '~/utils/theme';

@Component
export default class PageLoader extends VueComponent<{}> {
  public render() {
    return (
      <v-container fill-height>
        <v-row class='fill-height' justify='center' align='center'>
          <v-progress-circular color={ThemeColors.ACCENT} indeterminate />
        </v-row>
      </v-container>
    );
  }
}
