export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'Souhlasím s {0}',
          link: 'zpracování osobních údajů.',
          error: 'Musíte souhlasit se zpracováním osobních údajů.',
        },
      },
      send: 'Odeslat',
      sent: 'Formulář byl úspěšně odeslán.',
    },
  },
};
