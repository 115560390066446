import { LocaleMessage } from 'vue-i18n';
import { Component, Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import VenueFinderModule from '~/app/core/store/modules/VenueFinderModule';
import VenueModule, {
  CreateNewBookingRequestPayload,
} from '~/app/core/store/modules/VenueModule';
import { Button, Headline, InputField, Select } from '~/components/atoms';
import Checkbox from '~/components/atoms/checkbox/Checkbox';
import { Align, LineColor } from '~/components/atoms/headline/Headline';
import { Link } from '~/components/molecules';
import Dialog from '~/components/organisms/dialog/Dialog';
import differenceInHours from '~/utils/date-fns/differenceInHours';
import { Colors } from '~/utils/theme/colors';
import { VenueFinderLanguage } from '~/utils/venueFinder';
import { VueComponent } from '~/utils/vue-component';
import HtmlRenderer from '../base/HtmlRenderer';
import RecaptchaWrapper from '../recaptchaWrapper/RecaptchaWrapper';
import style from './VenueReservationForm.scss';

interface CreateVenueBookingClient {
  company: string | null;
  email: string | null;
  phone: string | null;
  lang: VenueFinderLanguage;
  name: string | null;
}

interface CreateVenueBookingEvent {
  date: string | null;
  dateTo: string | null;
  dateFlexible: boolean;
  type: string;
  pax: number | null;
  note: string | null;
  length: number | null;
}

export interface CreateVenueBookingFormData {
  client: CreateVenueBookingClient;
  event: CreateVenueBookingEvent;
  source: string;
  test: boolean;
  venueId: string;
}

const rootClass = 'czt-venue-reservation-form';

@Component({ style })
export default class VenueReservationForm extends VueComponent<{}> {
  @Ref('emailInput')
  public readonly emailInput!: InputField;

  /** Field values */

  protected email: string | null = null;

  protected phone: string | null = null;

  protected dateStart: string | null = null;

  protected dateEnd: string | null = null;

  protected timeStart: string | null = null;

  protected timeEnd: string | null = null;

  protected fluidDate: boolean = false;

  protected selectedType: string = '';

  protected numberOfHosts: number | null = null;

  protected companyName: string | null = null;

  protected note: string | null = null;

  protected name: string | null = null;

  /** Functional values */

  protected error: string | LocaleMessage = '';

  protected success: string | LocaleMessage = '';

  protected showAdvancedForm: boolean = false;

  protected showDateStartMenu: boolean = false;

  protected showDateEndMenu: boolean = false;

  protected showTimeStartMenu: boolean = false;

  protected showTimeEndMenu: boolean = false;

  protected get venueStore() {
    return getModule(VenueModule, this.$store);
  }

  protected get venueFinderStore() {
    return getModule(VenueFinderModule, this.$store);
  }

  protected get booking() {
    return this.venueStore.bookingLoading;
  }

  public created() {
    const venueFinderStore =
      this.venueFinderStore || getModule(VenueFinderModule, this.$store);
    return venueFinderStore.getOptions();
  }

  public render() {
    return (
      <v-sheet color={Colors.PRIMARY}>
        <v-container fluid>
          {this.renderForm([
            <v-col cols={12} class='text-center'>
              <Button
                disabled={this.booking}
                loading={this.booking}
                onClick={() => {
                  this.showAdvancedForm = true;
                }}
              >
                {this.$t('app.venues.booking.continue')}
              </Button>
            </v-col>,
          ])}
        </v-container>
        <Dialog
          contentClass={`${rootClass}__dialog`}
          active={this.showAdvancedForm}
          onCloseDialog={this.handleClose}
          backgroundColor={Colors.PRIMARY}
          persist={!this.success}
          maxWidth={650}
        >
          <v-fade-transition group hide-on-leave>
            {this.success === '' ? (
              <div key={`${rootClass}__dialog__form`}>
                {this.renderAdvancedForm()}
              </div>
            ) : (
              <div key={`${rootClass}__success`}>
                <v-row>
                  <v-col class='text-center' cols='12'>
                    <HtmlRenderer content={this.success} light />
                  </v-col>
                </v-row>
              </div>
            )}
          </v-fade-transition>
        </Dialog>
      </v-sheet>
    );
  }

  protected renderForm(content?: JSX.Element[]): JSX.Element {
    return (
      <v-row justify='center'>
        <v-col cols={12}>
          <Headline
            underscore
            lineColor={LineColor.RED}
            align={Align.CENTER}
            level={4}
            light
            class='mb-0'
          >
            {this.$t('app.venues.booking.title')}
          </Headline>
        </v-col>
        <v-col cols={12}>
          <v-row no-gutters>
            <v-col cols={12} md={6}>
              <v-menu
                value={
                  (this.showAdvancedForm && this.showDateStartMenu) ||
                  (!this.showAdvancedForm && this.showDateStartMenu)
                }
                onInput={() => {
                  this.showDateStartMenu = false;
                }}
                transition='scale-transition'
                offset-y
                min-width='300px'
                {...{
                  scopedSlots: {
                    activator: (scope: any) => (
                      <v-text-field
                        class={`czt-input-field ${rootClass}__input`}
                        v-model={this.dateStart}
                        label={this.$t('app.venues.booking.startDate')}
                        readonly
                        solo
                        required
                        {...{
                          attrs: scope.attrs,
                          on: scope.on,
                        }}
                      />
                    ),
                  },
                }}
              >
                <v-date-picker
                  v-model={this.dateStart}
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols={12} md={6}>
              <v-menu
                value={
                  (this.showAdvancedForm && this.showTimeStartMenu) ||
                  (!this.showAdvancedForm && this.showTimeStartMenu)
                }
                onInput={() => {
                  this.showTimeStartMenu = false;
                }}
                transition='scale-transition'
                close-on-content-click={false}
                offset-y
                min-width='300px'
                {...{
                  scopedSlots: {
                    activator: (scope: any) => (
                      <v-text-field
                        class={`czt-input-field ${rootClass}__input`}
                        v-model={this.timeStart}
                        label={this.$t('app.venues.booking.startTime')}
                        readonly
                        solo
                        clearable
                        {...{
                          attrs: scope.attrs,
                          on: scope.on,
                        }}
                      />
                    ),
                  },
                }}
              >
                <v-time-picker
                  v-model={this.timeStart}
                  full-width
                  format='24hr'
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols={12}>
          <v-row no-gutters>
            <v-col cols={12} md={6}>
              <v-menu
                value={
                  (this.showAdvancedForm && this.showDateEndMenu) ||
                  (!this.showAdvancedForm && this.showDateEndMenu)
                }
                onInput={() => {
                  this.showDateEndMenu = false;
                }}
                transition='scale-transition'
                offset-y
                min-width='300px'
                {...{
                  scopedSlots: {
                    activator: (scope: any) => (
                      <v-text-field
                        class={`czt-input-field ${rootClass}__input`}
                        v-model={this.dateEnd}
                        label={this.$t('app.venues.booking.endDate')}
                        readonly
                        solo
                        clearable
                        {...{
                          attrs: scope.attrs,
                          on: scope.on,
                        }}
                      />
                    ),
                  },
                }}
              >
                <v-date-picker
                  v-model={this.dateEnd}
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols={12} md={6}>
              <v-menu
                value={
                  (this.showAdvancedForm && this.showTimeEndMenu) ||
                  (!this.showAdvancedForm && this.showTimeEndMenu)
                }
                onInput={() => {
                  this.showTimeEndMenu = false;
                }}
                transition='scale-transition'
                close-on-content-click={false}
                offset-y
                min-width='300px'
                {...{
                  scopedSlots: {
                    activator: (scope: any) => (
                      <v-text-field
                        class={`czt-input-field ${rootClass}__input`}
                        v-model={this.timeEnd}
                        label={this.$t('app.venues.booking.endTime')}
                        readonly
                        solo
                        clearable
                        {...{
                          attrs: scope.attrs,
                          on: scope.on,
                        }}
                      />
                    ),
                  },
                }}
              >
                <v-time-picker
                  v-model={this.timeEnd}
                  full-width
                  format='24hr'
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols={12} class='py-0'>
          <Checkbox
            class={`${rootClass}__input`}
            v-model={this.fluidDate}
            label={this.$t('app.venues.booking.fluidDate')}
            light
          />
        </v-col>
        <v-col cols={12}>
          <InputField
            class={`${rootClass}__input`}
            v-model={this.numberOfHosts}
            clearable
            label={this.$t('app.venues.booking.capacity')}
            type='number'
            required
          />
        </v-col>
        {content}
      </v-row>
    );
  }

  protected renderAdvancedForm(): JSX.Element {
    return this.renderForm([
      <v-col cols={12}>
        <InputField
          class={`${rootClass}__input`}
          label={this.$t('app.venues.booking.name')}
          v-model={this.name}
          type='text'
          light
        />
      </v-col>,
      <v-col cols={12}>
        <InputField
          class={`${rootClass}__input`}
          label={this.$t('app.venues.booking.email')}
          v-model={this.email}
          type='email'
          ref='emailInput'
          required
          light
        />
      </v-col>,
      <v-col cols={12}>
        <InputField
          class={`${rootClass}__input`}
          label={this.$t('app.venues.booking.phone')}
          v-model={this.phone}
          type='tel'
          ref='phoneInput'
          required
          light
        />
      </v-col>,
      <v-col cols={12}>
        <Select
          class={`${rootClass}__input`}
          items={this.venueFinderStore.types}
          v-model={this.selectedType}
          label={this.$t('app.venues.booking.type')}
        />
      </v-col>,
      <v-col cols={12}>
        <InputField
          class={`${rootClass}__input`}
          label={this.$t('app.venues.booking.company')}
          v-model={this.companyName}
          type='text'
          light
        />
      </v-col>,
      <v-col cols={12}>
        <v-textarea
          class={`${rootClass}__input`}
          label={this.$t('app.venues.booking.note')}
          v-model={this.note}
          type='tel'
          light
          solo
          no-resize
          auto-grow
        />
      </v-col>,
      <v-col cols='12' class={`${rootClass}__content white--text`}>
        <i18n class='mt-2' tag='div' path='app.venues.booking.privacy'>
          <Link url={this.$t('app.venues.booking.meatspaceUrl')}>
            {this.$t('app.venues.booking.meatspace')}
          </Link>
        </i18n>
      </v-col>,
      <div>
        {this.error && (
          <v-col cols='12'>
            <v-sheet color={Colors.ACCENT} class='pa-3 white--text'>
              {this.error}
            </v-sheet>
          </v-col>
        )}
      </div>,
      <v-col class='text-center' cols='12'>
        <RecaptchaWrapper
          {...{
            scopedSlots: {
              default: (scope: any) => {
                return (
                  <Button
                    disabled={this.booking}
                    loading={this.booking}
                    {...{
                      on: scope.on,
                    }}
                  >
                    {this.$t('app.venues.booking.submit')}
                  </Button>
                );
              },
            },
          }}
          onRecaptchaCallback={this.book}
        />
      </v-col>,
    ]);
  }

  protected book(token: string) {
    this.error = '';
    if (!this.venueStore.currentId) {
      this.error = this.$t('app.venues.booking.error.noId').toString();
      return;
    }
    if (!this.email || !this.emailInput.checkValidity()) {
      this.error = this.$t('app.venues.booking.error.invalidEmail').toString();
      return;
    }
    const re = /[\d +]{9,}$/;
    if (!this.phone || !this.phone.trim() || !re.test(this.phone)) {
      this.error = this.$t('app.venues.booking.error.invalidPhone').toString();
      return;
    }
    if (!this.numberOfHosts) {
      this.error = this.$t('app.venues.booking.error.noHosts').toString();
      return;
    }
    if (!this.dateStart) {
      this.error = this.$t('app.venues.booking.error.noDateStart');
      return;
    }
    const startTime = this.timeStart?.trim()
      ? `${this.timeStart}:00`
      : '00:00:00';
    const endTime = this.timeEnd?.trim() ? `${this.timeEnd}:00` : '23:59:59';
    const firstDate = `${this.dateStart} ${startTime}`;
    const secondDate = `${
      !this.dateEnd ? this.dateStart : this.dateEnd
    } ${endTime}`;
    const lengthOfEvent = differenceInHours(secondDate, firstDate);
    if (lengthOfEvent < 0) {
      this.error = this.$t('app.venues.booking.error.shortEvent');
      return;
    }
    const payload: CreateNewBookingRequestPayload = {
      venueId: this.venueStore.currentId,
      test: process.env.NODE_ENV === 'development',
      source: window.location.hostname,
      client: {
        company: this.companyName || undefined,
        email: this.email.trim(),
        lang: VenueFinderLanguage.czech,
        name: this.name || undefined,
        phone: this.phone,
      },
      event: {
        date: `${this.dateStart} ${startTime}`,
        dateTo: this.dateEnd
          ? `${this.dateEnd} ${endTime || '00:00:00'}`
          : undefined,
        dateFlexible: this.fluidDate,
        length: lengthOfEvent,
        note: this.note || undefined,
        pax: this.numberOfHosts,
        type: this.selectedType || undefined,
      },
    };
    this.venueStore
      .createVenueBooking({ data: payload, recaptchaToken: token })
      .then((bookingCreated) => {
        if (bookingCreated) {
          this.success = this.$t('app.venues.booking.success');
        } else {
          this.error = this.$t('app.venues.booking.error.server');
        }
      });
  }

  protected handleClose() {
    this.showAdvancedForm = false;
  }
}
