import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class PlayIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--play'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path d='M4.3.4C2.5-.6 1 .3 1 2.3v15.3c0 2.1 1.5 2.9 3.3 1.9l13.4-7.7c1.8-1 1.8-2.7 0-3.7L4.3.4z' />
        </svg>
      </i>
    );
  }
}
