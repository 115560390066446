export enum Ratios {
  '2x3' = 2 / 3,
  '3x1' = 3,
  '3x2' = 3 / 2,
  '3x4' = 3 / 4,
  '1x1' = 1,
  '4x3' = 4 / 3,
  '16x9' = 16 / 9,
  '16x10' = 16 / 10,
  '21x9' = 21 / 9,
  'auto' = 0,
}

export function isAspectRatio(data: any): data is Ratios {
  for (const value in Ratios) {
    if (Ratios.hasOwnProperty(value)) {
      if (data === (Ratios as any)[value]) {
        return true;
      }
    }
  }
  return false;
}
