export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filtrovat dle incentivy',
        itinerary: 'Filtrovat dle tagu',
        region: 'Filtrovat dle regionu',
      },
    },
  },
};
