import { Component, Prop } from 'vue-property-decorator';

import { GridItem } from '~/components/organisms/gridList/GridList';
import { VueComponent } from '~/utils/vue-component';
import { Headline } from '~/components/atoms';
import { ImageWrapper, Link } from '~/components/molecules';
import { Align } from '~/components/atoms/headline/Headline';
import { ThemeRatios } from '~/utils/theme';
import { format } from '~/utils/date-fns';
import { parse } from 'date-fns';

interface SimpleGridItemInterface {
  item: GridItem;
}

const rootClass = 'czt-grid-list__item';

@Component
export default class SimpleGridItem
  extends VueComponent<SimpleGridItemInterface>
  implements SimpleGridItemInterface {
  @Prop({ required: true })
  public item!: GridItem;

  public render() {
    return (
      <v-col class={`${rootClass} ${rootClass}--simple`} cols={12}>
        <v-row>
          <v-col cols='12' md='4'>
            <Link url={this.item.link}>
              <ImageWrapper
                ratio={ThemeRatios['4x3']}
                image={this.item.image}
              />
            </Link>
          </v-col>
          <v-col cols='12' md='8'>
            {this.item.date && (
              <div class='grey--text text--darken-2 font-weight-bold mb-1'>
                {format(
                  parse(this.item.date),
                  'D. MM. YYYY',
                  this.$i18n.locale
                )}
              </div>
            )}
            <Headline
              level={3}
              align={Align.LEFT}
              class='mb-2 font-weight-bold'
            >
              <Link url={this.item.link}>{this.item.title}</Link>
            </Headline>
            <div class={`${rootClass}__content font-weight-bold`}>
              <p>
                {this.item.perex}{' '}
                <Link url={this.item.link}>
                  {this.$t('app.common.readMore')}
                </Link>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    );
  }
}
