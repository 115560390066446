import { Component, Prop } from 'vue-property-decorator';

import style from './TopLayerImage.scss';
import { Image } from '~/components/atoms';
import { VueComponent } from '~/utils/vue-component';
import { OneOfThePages } from '~/utils/views';

interface TopLayerImageInterface {
  pageData: OneOfThePages | null;
}

const rootClass = 'czt-top-layer-image';

@Component({
  style,
})
export default class TopLayerImage extends VueComponent<TopLayerImageInterface>
  implements TopLayerImageInterface {
  @Prop({ required: true })
  public pageData!: OneOfThePages | null;

  public render() {
    if (!this.pageData || !this.pageData.topLayerImage) {
      return;
    }

    return (
      <v-row no-gutters justify='center' class={rootClass}>
        <Image
          src={this.pageData.topLayerImage}
          alt={this.pageData.title || ''}
        />
      </v-row>
    );
  }
}
