import { Module, Action, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import { Dictionary } from 'vue-router/types/router';
import toBoolean from '~/utils/toBoolean';
import { createPage, OneOfThePages } from '~/utils/views';

interface SetResourceCommit {
  resource: OneOfThePages | null;
}

@Module({
  name: 'RouterModule',
  stateFactory: true,
  namespaced: true,
})
export default class RouterModule extends AbstractModule {
  public resource: OneOfThePages | null = null;

  public loading: boolean = true;

  public found: boolean = false;

  public currentGuid: string | null = null;

  public currentLocale: string | null = null;

  @Action({ rawError: true })
  public loadResourceByPath({
    guid,
    locale,
    query,
  }: {
    guid: string;
    locale: string;
    query?: Dictionary<string | (string | null)[]>;
  }): Promise<void> {
    const decodedGuid = decodeURIComponent(guid);
    if (
      this.currentGuid === decodedGuid &&
      this.currentLocale === locale &&
      this.resource
    ) {
      return Promise.resolve();
    }

    this.resourceLoading(true);
    this.resourceFound(false);
    this.setCurrentGuid(decodedGuid);
    this.setCurrentLocale(locale);
    const isPreview =
      query &&
      query.hasOwnProperty('isPreview') &&
      typeof query.isPreview === 'string'
        ? toBoolean(query.isPreview)
        : undefined;

    const promise: Promise<void> = this.$api
      .pages()
      .pagesGetPage(decodedGuid, locale, undefined, isPreview)
      .then((response) => {
        if (this.currentGuid === decodedGuid && this.currentLocale === locale) {
          this.resourceFound(!!response);
          this.setResource({
            resource: response ? createPage(response) : null,
          });
        }
      })
      .catch(() => {
        if (this.currentGuid === decodedGuid && this.currentLocale === locale) {
          this.resourceFound(false);
          this.setResource({ resource: null });
        }
      })
      .finally(() => {
        if (this.currentGuid === decodedGuid && this.currentLocale === locale) {
          this.resourceLoading(false);
        }
      });

    return promise;
  }

  @Mutation
  protected setResource(data: SetResourceCommit) {
    this.resource = data.resource;
  }

  @Mutation
  protected resourceLoading(value: boolean) {
    this.loading = value;
  }

  @Mutation
  protected resourceFound(value: boolean) {
    this.found = value;
  }

  @Mutation
  protected setCurrentGuid(value: string | null) {
    this.currentGuid = value;
  }

  @Mutation
  protected setCurrentLocale(value: string | null) {
    this.currentLocale = value;
  }
}
