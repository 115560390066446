import { Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import { defaultLocale } from '~/app/localization';
import isLocaleSupported from '~/utils/localization/isLocaleSupported';

@Module({
  name: 'LanguageModule',
  stateFactory: true,
  namespaced: true,
})
export default class LanguageModule extends AbstractModule {
  public initialLanguage: string = defaultLocale;

  @Mutation
  public setLanguage(language: string) {
    if (isLocaleSupported(language)) {
      this.initialLanguage = language;
    }
  }
}
