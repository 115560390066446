export default {
  app: {
    events: {
      title: 'Upcoming Events',
      noResults: 'No events found',
      filter: 'Filter',
      loading: 'Searching',
      placeholder: {
        dateFrom: 'Date from',
        dateTo: 'Date to',
        type: 'Type',
        theme: 'Theme',
        package: 'Package',
        foreignReps: 'Foreign representation',
      },
    },
  },
};
