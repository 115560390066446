export default {
  app: {
    newsletter: {
      bottomText:
        'By subscribing to the newsletter, you agree to our <a href="/GDPR" target="_blank">data protection policy</a>.',
      button: 'Subscribe',
      dialog: {
        headline: 'Select Your Newsletter',
        notice:
          'Your email address provided above will only be used for the purpose of our newsletter subscription and it will not be disclosed to any other subjects. We will register your email address for this purpose until the day you unsubscribe from our newsletter. You can unsubscribe at any time by clicking on the link in the message delivering the newsletter. We will keep the information about unsubscribing for a period of 3 years. After this period of time, the data will be deleted unless there is another reason to process it again. Your email address may be processed by the IT company that manages the operation and development of our websites. {0}',
        company: 'The Data Controller is {0}, {1}',
        link: {
          privacy: 'Our Privacy Policy is available here.',
          controller: 'Česká centrála cestovního ruchu – CzechTourism',
          web: 'www.czechtourism.cz',
        },
        url: {
          privacy: '~/GDPR',
          controller: 'https://czechtourism.cz',
          web: 'https://www.czechtourism.cz/',
        },
      },
      inputPlaceholder: 'Your e-mail address',
      newsletterError:
        'There seem to be no newsletters for you to subscribe to right now',
      serverError:
        'There was an error while executing the request. Please, try again',
      success:
        'You have been successfully subscribed to our newsletter. We sent you e-mail with subscription confirmation link.',
      title: 'Receive our newsletter',
      topText: 'Sign up to receive the latest information.',
      validityError: 'Your email address seems to be invalid',
    },
  },
};
