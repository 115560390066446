export default {
  app: {
    panorama: {
      loadingText: 'Loading image...',
      autorotate: 'Automatic rotation',
      zoom: 'Zoom',
      zoomOut: 'Zoom out',
      zoomIn: 'Zoom in',
      move: 'Move',
      download: 'Download',
      fullscreen: 'Fullscreen',
      menu: 'Menu',
      twoFingers: 'Use two fingers to navigate',
      ctrlZoom: 'Use ctrl + scroll to zoom the image',
      loadError: "The panorama can't be loaded",
    },
  },
};
