import deepmerge from 'deepmerge';
import Vue from 'vue';
import VueI18n, { I18nOptions, LocaleMessages } from 'vue-i18n';

import { default as i18n_cs } from './cs-CZ';
import { default as i18n_en } from './en-US';

import dateFnsCs from 'date-fns/locale/cs';
import dateFnsEn from 'date-fns/locale/en';
import { LanguageLocaleInterface } from '~/utils/localization';

Vue.use(VueI18n);

const localizations: LocaleMessages[] = [
  {
    'cs-CZ': i18n_cs,
  },
  {
    'en-US': i18n_en,
  },
];

const mergedLocalizations: LocaleMessages = deepmerge.all(
  localizations
) as LocaleMessages;

const defaultLocale = 'cs-CZ';

const supportedLocales: LanguageLocaleInterface[] = [
  {
    name: 'Čeština',
    public: true,
    slug: 'cs-CZ',
  },
  {
    name: 'English',
    public: true,
    slug: 'en-US',
  },
];

const i18nOptions: I18nOptions = {
  locale: defaultLocale,
  messages: mergedLocalizations,
  pluralizationRules: {
    // TODO: Add pluralization rules for other languages
  },
};

const dateFnsLocalizations: { [key: string]: any } = {
  'cs-CZ': dateFnsCs,
  'en-US': dateFnsEn,
};

export function createI18n() {
  return new VueI18n(i18nOptions);
}

interface RouteLocalization {
  routes: {
    [key: string]: {
      name: string;
      path: string;
    };
  };
}

export {
  defaultLocale,
  RouteLocalization,
  supportedLocales,
  dateFnsLocalizations,
};
