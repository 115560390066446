import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class LinkedInIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--social czt-icon--linkedin'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 200 200'
          version='1.0'
        >
          <defs>
            <linearGradient
              id='linearGradient3003'
              y2='425.4'
              gradientUnits='userSpaceOnUse'
              x2='-395.85'
              gradientTransform='matrix(-0.50335197,0,0,0.50335205,-148.17928,-158.80197)'
              y1='274.71'
              x1='-344.15'
            >
              <stop stop-color='#FFF' offset='0' />
              <stop stop-color='#FFF' stop-opacity='0' offset='1' />
            </linearGradient>
          </defs>
          <rect
            transform='scale(-1,1)'
            rx='30.201'
            ry='30.201'
            height='199.98'
            width='200'
            y='0.011226'
            x='-200'
            fill='#069'
          />
          <path
            opacity='0.7811159'
            d='m147.16,8.5612-94.32,0c-24.967,0-45.066,20.263-45.066,45.433v92.02c0.80814,19.647,3.9167,7.2266,9.8337-14.531,6.8768-25.287,29.273-47.388,56.547-63.952,20.817-12.642,44.119-20.715,86.533-21.483,24.054-0.43553,21.931-31.218-13.527-37.487z'
            fill='url(#linearGradient3003)'
          />
          <path
            d='m63.992,167.85,0-90.884-30.208,0,0,90.884,30.208,0zm-15.1-103.3c10.534,0,17.091-6.9789,17.091-15.7-0.19632-8.9179-6.5566-15.703-16.891-15.703-10.333,0-17.09,6.7852-17.09,15.703,0,8.7216,6.5553,15.7,16.693,15.7h0.19633z'
            fill='#FFF'
          />
          <path
            d='m80.712,167.85,30.208,0,0-50.754c0-2.7163,0.19632-5.4298,0.99398-7.3715,2.1838-5.4271,7.1542-11.048,15.499-11.048,10.931,0,15.304,8.3343,15.304,20.552v48.621h30.206v-52.112c0-27.916-14.903-40.905-34.778-40.905-16.296,0-23.451,9.1089-27.426,15.313h0.2017v-13.181h-30.208c0.39641,8.528,0,90.884,0,90.884z'
            fill='#FFF'
          />
        </svg>
      </i>
    );
  }
}
