import { VueComponent } from '~/utils/vue-component';

import common from './common';
import social from './social';
import Icon from './Icon';
import { BaseIcon } from './BaseIcon';

const icons = {
  common,
  social,
};

const mappedIcons: {
  common: { [key in keyof typeof icons.common]: typeof VueComponent };
  social: { [key in keyof typeof icons.social]: typeof VueComponent };
} = {
  common: { ...common },
  social: { ...social },
};

for (const category in mappedIcons) {
  if (!mappedIcons.hasOwnProperty(category)) {
    continue;
  }

  const categoryIcons = (mappedIcons as { [key: string]: any })[category];

  for (const iconKey in categoryIcons) {
    if (!categoryIcons.hasOwnProperty(iconKey)) {
      continue;
    }

    (mappedIcons as { [key: string]: any })[category][iconKey] = Icon.extend({
      props: {
        name: {
          default: () => {
            return [category, iconKey];
          },
        },
      },
    });
  }
}

export { icons };

export default (mappedIcons as unknown) as {
  common: { [key in keyof typeof icons.common]: typeof BaseIcon };
  social: { [key in keyof typeof icons.social]: typeof BaseIcon };
};
