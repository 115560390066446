import {
  ContentWidgetData,
  ContentWidgets,
  isContentWidgetData,
} from './types';

export interface InstagramContentWidgetData extends ContentWidgetData {
  post?: string;
  center?: string;
}

export function isInstagramContentWidgetData(
  data: any
): data is InstagramContentWidgetData {
  return isContentWidgetData(data) && data.name === ContentWidgets.INSTAGRAM;
}

export function getInstagramComponentString(
  data: InstagramContentWidgetData
): string {
  // Hack to solve different prop naming
  const postKey: string | undefined = Object.keys(data).find((index) => {
    return index.toLowerCase() === 'post';
  });
  const post = postKey ? data[postKey] : undefined;
  if (!post || typeof post !== 'string') return '';
  let postData: string = '';
  try {
    postData = decodeURIComponent((post + '').replace(/\+/g, ' '));
  } catch {
    return '';
  }
  const centerKey: string | undefined = Object.keys(data).find((index) => {
    return index.toLowerCase() === 'center';
  });
  return postData
    ? `<div class="czt-post-widget czt-post-widget--instagram${
        centerKey && data[centerKey] && data[centerKey] === 'True'
          ? ' czt-post-widget--centered'
          : ''
      }">${postData}</div>`
    : '';
}
