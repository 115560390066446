import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { Category } from '~/app/core/apiClient/api';
import { MediaBackground, ImageTile } from '~/components/molecules';
import { ImageInterface } from '~/components/atoms/image/Image';
import { Headline, Button } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { ThemeColors } from '~/utils/theme';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';

const sizes: Sizes[] = [
  {
    size: 100,
    unit: 'vw',
  },
];

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  public categories: Category[] = [];

  public loading: boolean = false;

  protected image: ImageInterface = {
    src: 'http://picsum.photos/1920/1080',
    alt: 'Wide Picture',
  };

  public prefetch() {
    this.loadingPrefetchData = true;

    return this.loadCategories().finally(() => {
      this.loadingPrefetchData = false;
    });
  }

  public getContent() {
    return (
      <MediaBackground
        image={this.image}
        overlayColor={ThemeColors.PRIMARY}
        sizes={sizes}
      >
        <v-row class='pa-0 ma-0'>
          <v-col>
            <v-row>{this.getCategoriesItems()}</v-row>
          </v-col>
          <v-col cols='3'>
            <v-row
              class='flex-column fill-height'
              justify-content='space-between'
            >
              <v-col>
                <Headline underscore align={Align.RIGHT} light level={2}>
                  Choose Your Experience
                </Headline>
              </v-col>
              <v-col cols='auto text-right'>
                <Button>See All Experiences</Button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </MediaBackground>
    );
  }
  public getSettings() {
    return [];
  }

  protected loadCategories() {
    this.loading = true;

    return this.$api
      .categories()
      .categoriesGetCategories(this.$i18n.locale)
      .then((data) => {
        this.categories = data;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected getCategoriesItems() {
    if (this.loadingPrefetchData || this.loading) {
      return [...Array(6).keys()].map((index: number) => {
        return (
          <v-col cols={4} key={index}>
            <v-skeleton-loader type='image' />
          </v-col>
        );
      });
    } else {
      return this.categories
        .map((category: Category, index: number) => {
          if (!category.categoryName) {
            return;
          }
          return (
            <v-col cols={4} key={index.toString() + category}>
              <ImageTile image={this.image}>{category.categoryName}</ImageTile>
            </v-col>
          );
        })
        .slice(0, 6);
    }
  }
}
