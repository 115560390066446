import Button from './Button';
import Checkbox from './Checkbox';
import Headline from './Headline';
import Icons from './Icons';
import Image from './Image';
import InputField from './InputField';
import List from './List';
import RadioGroup from './RadioGroup';
import Select from './Select';

export const atoms = {
  Button,
  Checkbox,
  Headline,
  Icons,
  Image,
  InputField,
  List,
  RadioGroup,
  Select,
};
