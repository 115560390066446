import { Component } from 'vue-property-decorator';

import appEnv from '~/app/core/appEnv';
import HeadManagement from '~/mixins/HeadManagement';
import { VueComponentMixin } from '~/utils/vue-component';
import WidgetRenderer from '~/components/templates/base/WidgetRenderer';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { getModule } from 'vuex-module-decorators';
import RouterModule from '~/app/core/store/modules/RouterModule';
import NotFound from '~/components/templates/common/NotFound';
import PageLoader from '~/components/templates/common/PageLoader';
import { OneOfThePages } from '~/utils/views';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';
import { getLocaleFromRouter } from '~/app/core/router';
import { CztWidgets, Widget } from '~/utils/views/widgets';
import { NewsletterFormInterface } from '~/components/templates/common/NewsletterForm';

@Component
export default class Homepage extends VueComponentMixin<{}, PrefetchComponent>(
  Prefetch,
  HeadManagement
) {
  public get found(): boolean {
    return getModule(RouterModule, this.$store).found;
  }

  public get loading(): boolean {
    return getModule(RouterModule, this.$store).loading;
  }

  public get pageData(): OneOfThePages | null {
    return getModule(RouterModule, this.$store).resource;
  }

  protected get widgets(): Widget[] {
    if (!this.pageData) {
      return [];
    }

    const widgets = [...this.pageData.widgets.main];

    return widgets;
  }

  public title() {
    return '';
  }

  public headTags(): string {
    return createSeoTags(
      '#CzechTourism',
      this.pageData?.perex || this.pageData?.title || '',
      `${appEnv.PUBLIC_WEB_URL}/${getLocaleFromRouter(this.$router)}`,
      TYPE.WEBSITE,
      this.pageData?.image
    );
  }

  public prefetch() {
    const routerModule = getModule(RouterModule, this.$store);
    return routerModule.loadResourceByPath({
      guid: 'Homepage',
      locale: this.$i18n.locale,
      query: this.$route.query || undefined,
    });
  }

  public render() {
    if (this.loading) {
      return <PageLoader />;
    }
    if (!this.found) {
      return <NotFound />;
    }
    if (!this.pageData) {
      // TODO: error page
      return;
    }

    return (
      <v-container fluid class='pa-0' key={this.pageData.guid}>
        {this.widgets.length > 0 && <WidgetRenderer widgets={this.widgets} />}
      </v-container>
    );
  }
}
