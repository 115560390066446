import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class DropdownIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--dropdown'>
        <svg
          viewBox='0 0 17 10'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path d='M8.713 10l-8-10h16l-8 10z' fill='currentColor' />
        </svg>
      </i>
    );
  }
}
