import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class PinterestIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--social czt-icon--pinterest'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 35 35'
        >
          <defs />
          <path fill='#e8071d' d='M-.006 0h35.033v35.032H-.006z' />
          <path
            fill='#fff'
            fill-rule='nonzero'
            d='M18.707 21.67c-1.123-.087-1.593-.643-2.473-1.178-.485 2.54-1.074 4.974-2.828 6.244-.54-3.837.796-6.718 1.414-9.776-1.056-1.777.128-5.357 2.356-4.476 2.742 1.086-2.376 6.612 1.06 7.304 3.587.72 5.05-6.224 2.826-8.48-3.213-3.261-9.353-.076-8.597 4.594.184 1.142 1.36 1.487.47 3.063-2.056-.456-2.67-2.078-2.59-4.24.127-3.542 3.18-6.02 6.241-6.365 3.874-.432 7.509 1.422 8.012 5.066.564 4.114-1.75 8.566-5.891 8.245z'
          />
        </svg>
      </i>
    );
  }
}
