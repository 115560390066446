import { Component } from 'vue-property-decorator';

import StatusCode from '~/utils/http/StatusCode';
import { VueComponent } from '~/utils/vue-component';
import Headline from '~/components/atoms/headline/Headline';
import Link from '~/components/molecules/link/Link';
import { NavigationItem } from '~/utils/header/menu-items';
import { Banner } from '~/components/organisms';

import teaserImage from '~/assets/images/404_not_found.jpg';

@Component
export default class NotFound extends VueComponent<{}> {
  public render() {
    const navItems: NavigationItem[] = [
      {
        label: 'app.menu.homepage',
        url: '~/',
      },
      {
        label: 'app.menu.news',
        url: '~/Novinky',
      },
      {
        label: 'app.menu.about',
        url: '~/O-nas',
      },
      {
        label: 'app.menu.contacts',
        url: '~/Kontakty',
      },
    ];

    const mainTitle = `${this.$t('app.notFound.title')}
      <div>${this.$t('app.notFound.subtitle')}</div>`;

    return (
      <v-container fluid class='pa-0'>
        <StatusCode code={404} />
        <Banner
          backgroundImage={{
            src: teaserImage,
            alt: this.$t('app.notFound.title') + '',
          }}
          title={mainTitle}
          titleLevel={1}
        >
          <Headline level={3} light>
            {this.$t('app.notFound.text')}
          </Headline>
          <div class='czt-banner__content__slots__links'>
            {navItems.map((item) => {
              if (!item.url) {
                return;
              }
              return <Link url={item.url}>{this.$t(item.label)}</Link>;
            })}
          </div>
        </Banner>
      </v-container>
    );
  }
}
