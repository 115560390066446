import { Action, Module, Mutation } from 'vuex-module-decorators';
import { ItemData } from '~/components/atoms/select/Select';
import { SafeTravelsListItem } from '~/components/templates/safeTravels/SafeTravelsList';
import AbstractModule from './AbstractModule';

@Module({
  name: 'SafeTravelsModule',
  stateFactory: true,
  namespaced: true,
})
export default class SafeTravelsModule extends AbstractModule {
  protected promiseCache: Promise<void> | null = null;
  public items: SafeTravelsListItem[] = [];
  public categories: ItemData[] = [];
  public loading: boolean = false;

  @Action({ rawError: true })
  public getSafeTravels(): Promise<void> {
    if (this.promiseCache) {
      return this.promiseCache;
    }
    this.setLoading(true);
    const promise: Promise<void> = this.$api
      .safeTravels()
      .safeTravelStampsGetItems(true)
      .then((result) => {
        const categories: string[] = [];
        const items: SafeTravelsListItem[] = result
          .filter((item) => {
            return (
              item.zobrazeniNaWebech &&
              item.schvaleno === true &&
              item.zobrazeniNaWebech.split('|').indexOf('CZT') !== -1
            );
          })
          .map((item) => {
            const cats = item.oblastCestovnihoRuchu
              ? item.oblastCestovnihoRuchu.split('|')
              : [];
            cats.forEach((cat) => {
              if (categories.indexOf(cat) === -1) {
                categories.push(cat);
              }
            });
            return {
              id: item.formItemID ? item.formItemID.toString() : 'unknown',
              name: item.nazevProvozovny || '',
              link: item.web || '',
              categories: cats.map((cat) => {
                return cat
                  .toLowerCase()
                  .split(' ')
                  .join('_');
              }),
            };
          });
        this.setCategories(
          categories.map((category) => {
            return {
              text: category,
              value: category
                .toLowerCase()
                .split(' ')
                .join('_'),
            };
          })
        );
        this.setItems(items);
      })
      .finally(() => {
        this.setLoading(false);
      });
    this.setPromise(promise);
    return promise;
  }

  @Mutation
  protected setPromise(promise: Promise<void>) {
    this.promiseCache = promise;
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setItems(items: SafeTravelsListItem[]) {
    this.items = items;
  }

  @Mutation
  protected setCategories(categories: ItemData[]) {
    this.categories = categories;
  }
}
