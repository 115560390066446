import { Component, Vue } from 'vue-property-decorator';
import { callPrefetch, ServerPrefetchComponent } from './index';

@Component
export default class ServerPrefetch extends Vue
  implements ServerPrefetchComponent {
  public loadingPrefetchData = true;

  public prefetchPromise: null | Promise<any> = null;

  public serverPrefetch() {
    this.prefetchPromise = callPrefetch(this).finally(() => {
      this.loadingPrefetchData = false;
    });

    return this.prefetchPromise;
  }
}
