import { supportedLocales } from '~/app/localization';
import { LanguageLocaleInterface } from '~/utils/localization';

export default function(locale: string): LanguageLocaleInterface | null {
  for (const supportedLocale of supportedLocales) {
    if (supportedLocale.slug === locale) {
      return supportedLocale;
    }
  }

  return null;
}
