export default function(
  cookieName: string,
  cookiesString: string
): string | null {
  const cookiesList: string[] = cookiesString.split(';');

  for (let item of cookiesList) {
    item = item.trim();
    const cookie = item.split('=');
    if (cookie[0] === cookieName) {
      const cookieValue = cookie;
      cookieValue.shift();
      return cookieValue.join('=');
    }
  }
  return null;
}
