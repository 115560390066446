import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import Checkbox from '~/components/atoms/checkbox/Checkbox';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected checked: boolean = false;
  protected readOnly: boolean = false;
  protected disabled: boolean = false;

  public getContent() {
    const label = this.checked ? 'Checkbox: checked' : 'Checkbox: unchecked';
    return [
      <div style='padding-top: 20px; display: flex; justify-content: center;'>
        <Checkbox
          label={label}
          v-model={this.checked}
          readOnly={this.readOnly}
          disabled={this.disabled}
        />
      </div>,
    ];
  }
  public getSettings() {
    return [
      <v-switch label='Read only' color='accent' v-model={this.readOnly} />,
      <v-switch label='Disabled' color='accent' v-model={this.disabled} />,
      <v-switch label='Checked' color='accent' v-model={this.checked} />,
    ];
  }
}
