import VueClamp from 'vue-clamp';
import { Component, Emit } from 'vue-property-decorator';

import { IconText } from '~/components/molecules';
import { IconPosition } from '~/components/molecules/iconText/IconText';
import { VueComponent } from '~/utils/vue-component';

import style from './TextClamp.scss';

interface TextClampInterface {
  onCollapse?: () => any;
  onExpand?: () => any;
}

const rootClass = 'czt-text-clamp';

@Component({
  components: {
    VueClamp,
  },
  style,
})
export default class TextClamp extends VueComponent<TextClampInterface> {
  protected open: boolean = false;

  public render() {
    return (
      <div class={rootClass}>
        <div
          class={`${rootClass}__wrapper${
            this.open ? ` ${rootClass}__wrapper--active` : ''
          }`}
        >
          {this.$slots.default}
        </div>
        {!this.open && (
          <IconText
            class={`${rootClass}__button czt-link`}
            icon={['common', 'ArrowDown']}
            onClick={this.buttonClick}
            iconPosition={IconPosition.APPEND}
          >
            {this.$t('app.common.showMore')}
          </IconText>
        )}
      </div>
    );
  }

  protected buttonClick() {
    if (this.open) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  @Emit('collapse')
  protected collapse() {
    this.open = false;
  }

  @Emit('expand')
  protected expand() {
    this.open = true;
  }
}
