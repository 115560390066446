import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { ImageInterface } from '~/components/atoms/image/Image';
import { Banner } from '~/components/organisms';
import { Button, Icons } from '~/components/atoms';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected backgroundImage: ImageInterface = {
    src: 'http://picsum.photos/1920/1080',
    alt: 'Wide Picture',
  };
  protected singleContent: boolean = true;
  protected socialButtons: boolean = true;
  protected superTitle: boolean = true;

  public getContent() {
    return [
      <Banner backgroundImage={this.backgroundImage} title='Things To Do'>
        {this.getMainContent()}
        {this.superTitle ? this.getSuperTitle() : undefined}
        {!this.singleContent ? this.getAsideContent() : undefined}
        {this.socialButtons ? this.getSocialButton() : undefined}
      </Banner>,
    ];
  }
  public getSettings() {
    return [
      <v-switch label='SuperTitle' color='accent' v-model={this.superTitle} />,
      <v-switch
        label='Single content'
        color='accent'
        v-model={this.singleContent}
      />,
      <v-switch
        label='Social buttons'
        color='accent'
        v-model={this.socialButtons}
      />,
    ];
  }

  protected getSuperTitle(): JSX.Element {
    return <span slot='superTitle'>PLACES TO VISIT</span>;
  }

  protected getMainContent(): JSX.Element[] {
    return [
      <p>Activites and Events in the Czech Republic</p>,
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nisi
        quam, ultricies sed turpis ac, dapibus commodo magna. Suspendisse
        potenti. Suspendisse quam dolor, ullamcorper volutpat pellentesque id,
        feugiat eu augue. Nulla nec vulputate velit, venenatis accumsan eros.
        Duis nec elit a lectus sagittis dictum et ac tortor. Fusce pellentesque
        dolor eu facilisis semper. Donec sed turpis vel magna auctor consequat.
      </p>,
      <Button>Explore</Button>,
    ];
  }

  protected getAsideContent() {
    return [
      <p slot='sideContent'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nisi
        quam, ultricies sed turpis ac, dapibus commodo magna. Suspendisse
        potenti. Suspendisse quam dolor, ullamcorper volutpat pellentesque id,
        feugiat eu augue. Nulla nec vulputate velit, venenatis accumsan eros.
        Duis nec elit a lectus sagittis dictum et ac tortor.
      </p>,
    ];
  }

  protected getSocialButton() {
    return [
      <div style='color: #FFF; white-space: nowrap;' slot='socialButtons'>
        SHARE: <Icons.social.Facebook size={2} />{' '}
        <Icons.social.Instagram size={2} /> <Icons.social.Pinterest size={2} />{' '}
        <Icons.social.Twitter size={2} />
      </div>,
    ];
  }
}
