import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class InstagramIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--social czt-icon--instagram'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 35 35'
        >
          <defs />
          <path
            fill='url(#_Radial1)'
            fill-rule='nonzero'
            d='M19.4 19.3h473.5v473.4H19.4z'
            transform='matrix(.07392 0 0 .07393 -1.44 -1.427)'
          />
          <path
            fill='#fff'
            d='M10.658 8.305H24.33c1.298 0 2.36.96 2.36 2.36v13.673c0 1.4-1.062 2.36-2.36 2.36H10.658c-1.3 0-2.361-.96-2.361-2.36V10.665c0-1.4 1.062-2.36 2.36-2.36zm11.038 2.043a.83.83 0 00-.828.828v1.983a.83.83 0 00.828.828h2.079a.83.83 0 00.828-.828v-1.983a.83.83 0 00-.828-.828h-2.079zm2.916 5.735h-1.62c.154.5.237 1.03.237 1.578 0 3.06-2.56 5.54-5.718 5.54-3.157 0-5.717-2.48-5.717-5.54 0-.548.083-1.078.236-1.578h-1.69v7.77c0 .402.33.731.732.731H23.88a.733.733 0 00.731-.73v-7.77zm-7.101-2.2c-2.04 0-3.694 1.602-3.694 3.579s1.654 3.58 3.694 3.58 3.694-1.603 3.694-3.58-1.654-3.58-3.694-3.58z'
          />
          <defs>
            <radialGradient
              id='_Radial1'
              cx='0'
              cy='0'
              r='1'
              gradientTransform='matrix(683.564 -35.8251 30.4514 581.023 27.99 449.303)'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stop-color='#fed576' />
              <stop offset='.26' stop-color='#f47133' />
              <stop offset='.61' stop-color='#bc3081' />
              <stop offset='1' stop-color='#4c63d2' />
            </radialGradient>
          </defs>
        </svg>
      </i>
    );
  }
}
