export default {
  app: {
    venues: {
      booking: {
        title: 'Non-binding inquiry',
        startDate: 'Date from *',
        startTime: 'Time from',
        endDate: 'Date to',
        endTime: 'Time to',
        continue: 'Continue',
        submit: 'Send inquiry',
        privacy:
          'Inquiries are proccessed by {0} directly to selected venue. No credit card information needed and no fees charged.',
        meatspace: 'Meatspace',
        capacity: 'Number of guests *',
        email: 'Email *',
        phone: 'Phone (with country code) *',
        company: 'Company',
        note: 'Note',
        name: 'Name and surname',
        meatspaceUrl: 'https://www.meatspace.cz/',
        type: 'Type of event',
        fluidDate: 'Date can change',
        error: {
          noId: 'There is no venue ID',
          server:
            'There was an error while processing your request, please, try again later.',
          invalidEmail: 'Please, enter a valid email.',
          noHosts: 'Please, enter the number of guests',
          noDateStart: 'Please, enter a valid start date',
          invalidPhone:
            'Please, enter a valid phone number (with country code)',
          shortEvent: 'Please, select valid dates and times',
        },
        success: 'Your inquiry was processed successfully',
      },
      detailButton: 'Venue Detail',
      detail: {
        link: 'Link to the venue',
        info: 'Information',
        subvenues: 'Subvenues',
        equipment: 'Equipment',
      },
      location: 'Location',
      maxCapacity: 'Maximum capacity',
      minCapacity: 'Minimum capacity',
      noResults: 'No venues found',
      search: 'Search venues',
      submit: 'Search',
      tags: 'Type of event',
    },
  },
};
