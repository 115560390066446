import { VueComponent } from '~/utils/vue-component';
import { BreadcrumbInterface } from '~/components/molecules/breadcrumbItem/BreadcrumbItem';
import { Component, Prop } from 'vue-property-decorator';
import { BreadcrumbItem } from '~/components/molecules';
import { Icons } from '~/components/atoms';

interface BreadcrumbsInterface {
  breadcrumbs: BreadcrumbInterface[];
}

const rootClass = 'czt-breadcrumbs';

@Component
export default class Breadcrumbs extends VueComponent<BreadcrumbsInterface>
  implements BreadcrumbsInterface {
  @Prop({ required: true })
  public breadcrumbs!: BreadcrumbInterface[];

  public render() {
    if (this.breadcrumbs.length < 1) {
      return;
    }

    return (
      <div class={rootClass}>
        <BreadcrumbItem
          title={this.$t('app.common.homepageBreadcrumb')}
          url='/'
        />
        {this.breadcrumbs.map((breadcrumb) => {
          return [
            <Icons.common.ArrowRight />,
            <BreadcrumbItem title={breadcrumb.title} url={breadcrumb.url} />,
          ];
        })}
      </div>
    );
  }
}
