import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './CookieBar.scss';
import { Button } from '~/components/atoms';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { IconText } from '~/components/molecules';
import { IconPosition } from '~/components/molecules/iconText/IconText';
import Checkbox from '~/components/atoms/checkbox/Checkbox';
import CookieSettingsModule from '~/app/core/store/modules/CookieSettingsModule';
import { getModule } from 'vuex-module-decorators';
import { IconName } from '~/components/atoms/icons/Icon';

@Component({
  style,
})
export default class CookieBar extends VueComponent<{}> {
  protected rootClass: string = 'czt-cookie-bar';

  protected settingsOpen: boolean = false;

  protected statistics: boolean = false;
  protected marketing: boolean = false;

  protected get cookieSettings(): CookieSettingsModule {
    return getModule(CookieSettingsModule, this.$store);
  }

  protected get visible(): boolean {
    return this.cookieSettings.cookieBarVisible;
  }

  protected get icon(): IconName {
    return ['common', !this.settingsOpen ? 'ArrowDown' : 'ArrowUp'];
  }

  protected get switchState(): boolean {
    return this.statistics && this.marketing;
  }

  public mounted() {
    this.cookieSettings.readCookie(document.cookie);
  }

  public render() {
    const classes: string[] = [this.rootClass];
    if (!this.visible) {
      classes.push('d-none');
    }
    return (
      <v-sheet class={classes.join(' ')} elevation={5}>
        <v-card>
          <v-card-text class={this.rootClass + '__text'}>
            <HtmlRenderer
              content={this.$t('app.cookies.cookieBarText') as string}
            />
            <v-expansion-panels
              class={`${this.rootClass}__settings`}
              active-class={`${this.rootClass}__settings--active`}
              flat
              accordion
              onChange={(val: any) => {
                this.settingsOpen = val !== undefined;
              }}
            >
              <v-expansion-panel>
                <v-expansion-panel-header hide-actions>
                  <IconText
                    class={`${this.rootClass}__settings__button`}
                    icon={this.icon}
                    iconPosition={IconPosition.APPEND}
                    iconMargin='5px'
                  >
                    {this.$t('app.cookies.settings.button')}
                  </IconText>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Checkbox
                    label={this.$t('app.cookies.settings.required')}
                    disabled
                    readOnly
                    value={true}
                  />
                  <Checkbox
                    label={this.$t('app.cookies.settings.statistics')}
                    v-model={this.statistics}
                  />
                  <Checkbox
                    label={this.$t('app.cookies.settings.marketing')}
                    v-model={this.marketing}
                  />
                  <v-switch
                    inset
                    input-value={this.switchState}
                    label={
                      this.switchState
                        ? this.$t('app.cookies.settings.reject')
                        : this.$t('app.cookies.settings.accept')
                    }
                    nativeOnClick={() => {
                      const val = !this.switchState;
                      this.marketing = val;
                      this.statistics = val;
                    }}
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class={this.rootClass + '__button'}>
              <Button onClick={this.settingsOpen ? this.save : this.acceptAll}>
                {this.settingsOpen
                  ? this.$t('app.cookies.buttons.save')
                  : this.$t('app.cookies.buttons.accept')}
              </Button>
            </div>
          </v-card-text>
        </v-card>
      </v-sheet>
    );
  }

  protected acceptAll(): void {
    this.cookieSettings.saveCookie({
      statistics: true,
      marketing: true,
    });
  }

  protected save(): void {
    this.cookieSettings.saveCookie({
      statistics: this.statistics,
      marketing: this.marketing,
    });
  }
}
