export default (name: string, value: string, expiry?: number) => {
  const cookie = `${name}=${value}`;
  if (!expiry) {
    document.cookie = cookie;
    return;
  }
  const date = new Date();
  date.setTime(date.getTime() + expiry);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}`;
};
