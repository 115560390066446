import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { Image } from '~/components/atoms';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  public showSettings = false;

  public getContent() {
    return <Image src='http://picsum.photos/1920/1080' alt='Test Image' />;
  }
}
