export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filter by incentive',
        itinerary: 'Filter by tag',
        region: 'Filter by region',
      },
    },
  },
};
