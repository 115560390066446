import nodeCrypto from 'crypto';

/* tslint:disable */
function getRandomValues(c: any) {
  if (typeof window !== 'undefined') {
    return (
      window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4))
    );
  }

  return nodeCrypto.randomFillSync(new Uint8Array(1))[0] & (15 >> (c / 4));
}
export default function() {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (c ^ getRandomValues(c)).toString(16)
  );
}
