import { Component, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import VueSlickCarousel from 'vue-slick-carousel';

import CarouselItem, {
  CarouselItemInterface,
} from '~/components/molecules/carouselItem/CarouselItem';
import { Headline, Icons } from '~/components/atoms';

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import style from './Carousel.scss';
import { CztWidgets } from '~/utils/views/widgets';

type CarouselOptions = {
  adaptiveHeight?: boolean;
  arrows?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  centerMode?: boolean;
  centerPadding?: boolean;
  cssEase?: string;
  dots?: boolean;
  dotsClass?: string;
  draggable?: boolean;
  edgeFriction?: number;
  fade?: boolean;
  focusOnSelect?: boolean;
  infinite?: boolean;
  initialSlide?: number;
  lazyLoad?: 'ondemand' | 'progressive' | false;
  pauseOnDotsHover?: boolean;
  pauseOnFocus?: boolean;
  pauseOnHover?: boolean;
  responsive?: {
    breakpoint: number;
    settings: CarouselOptions;
  }[];
  rows?: number;
  rtl?: boolean;
  slidesPerRow?: number;
  slidesToScroll?: number;
  slidesToShow?: number;
  speed?: number;
  swipe?: boolean;
  swipeToSlide?: boolean;
  touchMove?: boolean;
  touchThreshold?: number;
  useCSS?: boolean;
  useTransform?: boolean;
  variableWidth?: boolean;
  vertical?: boolean;
  waitForAnimate?: boolean;
};

export interface CarouselInterface {
  className: CztWidgets;
  isBottomSpacingCollapsed?: boolean;
  isTopSpacingCollapsed?: boolean;
  items: CarouselItemInterface[];
  options?: CarouselOptions;
  title: string;
}

export function isCarousel(data: any): data is CarouselInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.CAROUSEL &&
    typeof data.title === 'string'
  );
}

const rootClass = 'czt-carousel';

@Component({
  style,
})
export default class Carousel extends VueComponent<CarouselInterface>
  implements CarouselInterface {
  @Prop({ required: true })
  public items!: CarouselItemInterface[];

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({
    default: () => {
      // Need to split it to multiple lines for tslint
      let carouselOptions: CarouselOptions;
      carouselOptions = {
        focusOnSelect: true,
        infinite: false,
        variableWidth: true,
        swipeToSlide: true,
      };
      return carouselOptions;
    },
  })
  public carouselOptions!: CarouselOptions;

  public className = CztWidgets.CAROUSEL;

  @Ref('carousel')
  protected readonly carousel?: typeof VueSlickCarousel;

  /**
   * Flag indicating that the carousel is in the process of sliding
   * When sliding, the currentSlide should not change because the carousel
   * implementation does not allow changing the target slide during animation
   * and if this is overloaded using props, it flickers, so it sucks anyway!
   */
  protected sliding: boolean = false;

  /**
   * Slide that should be shown as the currently viewed item in the carousel
   */
  protected currentSlide: number = 0;

  protected get prevArrowClass(): string {
    const classes = [
      `${rootClass}__navigation`,
      `${rootClass}__navigation--prev`,
    ];

    if (this.currentSlide === 0) {
      classes.push(`${rootClass}__navigation--disabled`);
    }

    return classes.join(' ');
  }
  protected get nextArrowClass(): string {
    const classes = [
      `${rootClass}__navigation`,
      `${rootClass}__navigation--next`,
    ];

    // TODO: Solve arrow disabling on large screens
    if (this.currentSlide >= this.items.length - 1) {
      classes.push(`${rootClass}__navigation--disabled`);
    }

    return classes.join(' ');
  }

  public render() {
    if (this.items.length < 1) {
      return;
    }

    const rootClasses: string[] = [rootClass, 'czt-spacer'];
    if (this.isBottomSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-bottom');
    }
    if (this.isTopSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-top');
    }

    return (
      <v-sheet class={rootClasses.join(' ')}>
        <v-container>
          <Headline class={`${rootClass}__headline`} level={2} underscore>
            {this.title}
          </Headline>
        </v-container>
        <div class={`${rootClass}__wrapper`}>
          <div class={this.prevArrowClass} onClick={this.moveBackward}>
            <div class={`${rootClass}__navigation__background`} />
            <Icons.common.ArrowLeft />
          </div>
          <div class={this.nextArrowClass} onClick={this.moveForward}>
            <div class={`${rootClass}__navigation__background`} />
            <Icons.common.ArrowRight />
          </div>
          <VueSlickCarousel
            ref='carousel'
            onBeforeChange={() => {
              this.sliding = true;
            }}
            onAfterChange={(slideIndex: number) => {
              this.sliding = false;
              this.currentSlide = slideIndex;
            }}
            {...{
              props: {
                ...this.carouselOptions,
                // Hardcode the following props,
                // arrows are reimplemented due to bugs in vue carousel
                arrows: false,
              },
            }}
          >
            {this.getSlides()}
          </VueSlickCarousel>
        </div>
      </v-sheet>
    );
  }

  protected getSlides() {
    if (!this.items || (Array.isArray(this.items) && this.items.length < 1)) {
      return [...Array(3).keys()].map((index) => {
        return <v-skeleton-loader type='image' key={index} />;
      });
    }
    return this.items.map((item: CarouselItemInterface, index) => {
      return (
        <CarouselItem
          image={item.image}
          imageFilter={item.imageFilter}
          title={item.title}
          date={item.date}
          endDate={item.endDate}
          location={item.location}
          key={index + item.title}
          url={item.url}
          disabled={this.sliding}
        />
      );
    });
  }

  protected moveForward() {
    if (!this.carousel || this.sliding) {
      return;
    }
    this.currentSlide++;
    if (this.$vuetify.breakpoint.lgAndUp) {
      // Move an extra slide on large screens
      this.currentSlide++;
    }

    if (this.currentSlide > this.items.length - 1) {
      this.currentSlide = this.items.length - 1;
    }

    this.carousel.goTo(this.currentSlide);
  }

  protected moveBackward() {
    if (!this.carousel || this.sliding) {
      return;
    }
    this.currentSlide--;
    if (this.$vuetify.breakpoint.lgAndUp) {
      // Move an extra slide on large screens
      this.currentSlide--;
    }

    if (this.currentSlide < 0) {
      this.currentSlide = 0;
    }

    this.carousel.goTo(this.currentSlide);
  }
}
