import {
  BasePage,
  ArticlePage as ArticleApiPage,
  EventPage as EventApiPage,
  EventtiaEventPage as EventtiaEventApiPage,
} from '~/app/core/apiClient/api';
import { BreadcrumbInterface } from '~/components/molecules/breadcrumbItem/BreadcrumbItem';
import { format, parse, timezoneFormat } from '~/utils/date-fns';
import { createWidgets, Widget } from '~/utils/views/widgets';
import { defaultDateTimeFormat, defaultEventTimeFormat } from '../dateTime';
import ImageFilterTypeEnum = BasePage.ImageFilterTypeEnum;

export enum CztPage {
  ARTICLE = 'CZT.ArticlePage',
  CATEGORY = 'CZT.CategoryPage',
  EVENT = 'CZT.EventPage',
  EVENTTIA_EVENT = 'CZT.EventtiaEventPage',
  PAGE = 'CZT.Page',
  PLACE = 'CZT.PlacePage',
  REGION = 'CZT.RegionPage',
}

interface SharedData {
  automatedTranslationMessage: string;
  breadcrumbs: BreadcrumbInterface[];
  canonicalPath: string;
  className: CztPage;
  content: string;
  documentName: string;
  guid: string;
  hideTeaserImage: boolean;
  image?: string;
  imageFilter?: ImageFilterTypeEnum;
  perex: string;
  showAutomatedTranslationVoting: boolean;
  showRightColumn: boolean;
  subtitle?: string;
  title: string;
  topLayerImage?: string;
  url?: string;
  widgets: {
    after: Widget[];
    before: Widget[];
    main: Widget[];
    right: Widget[];
  };
}

// No extra properties for generic page
export type Page = SharedData;

export interface ArticlePage extends SharedData {
  date: Date | null;
}

export interface EventPage extends PlacePage {
  startDate: Date | null;
  endDate: Date | null;
}

export interface EventtiaEventPage extends SharedData {
  address: string;
  date: string;
  latitude: number | null;
  longitude: number | null;
  virtual: boolean;
  website: string;
}

export interface PlacePage extends SharedData {
  address: string;
  email: string;
  latitude: number | null;
  longitude: number | null;
  phone: string;
  region: {
    guid: string;
  } | null;
  webUrl: string | null;
  web2Url: string | null;
}

export interface RegionPage extends SharedData {
  region: {
    guid: string;
  } | null;
}

export type OneOfThePages =
  | Page
  | ArticlePage
  | EventPage
  | EventtiaEventPage
  | PlacePage
  | RegionPage;

function isArticleApiPage(data: any): data is ArticleApiPage {
  return data && data.className === CztPage.ARTICLE;
}

function isEventApiPage(data: any): data is EventApiPage {
  return data && data.className === CztPage.EVENT;
}

function isEventtiaEventApiPage(data: any): data is EventtiaEventApiPage {
  return data && data.className === CztPage.EVENTTIA_EVENT;
}

function enrichArticlePage(
  sharedData: SharedData,
  data: ArticleApiPage
): ArticlePage {
  let articlePage: ArticlePage;
  articlePage = {
    ...sharedData,
    className: CztPage.ARTICLE,
    date: data.date ? parse(data.date) : null,
  };
  return articlePage;
}

function enrichEventPage(
  sharedData: SharedData,
  data: EventApiPage
): EventPage {
  let eventPage: EventPage;
  const start = data.startDateTime ? parse(data.startDateTime) : null;
  const end = data.endDateTime ? parse(data.endDateTime) : '';

  eventPage = {
    ...sharedData,
    address: data.address || '',
    email: data.email || '',
    latitude: data.latitude || null,
    longitude: data.longitude || null,
    phone: data.phone || '',
    region: { guid: '' },
    webUrl: data.webUrl || null,
    web2Url: data.web2Url || null,
    className: CztPage.EVENT,
    endDate: end || null,
    startDate: start || null,
  };

  return eventPage;
}

function enrichEventtiaEventPage(
  sharedData: SharedData,
  data: EventtiaEventApiPage
): EventtiaEventPage {
  let eventPage: EventtiaEventPage;
  const dates = [];
  if (data.localStartDateTimeOffset) {
    dates.push(
      `${timezoneFormat(
        data.localStartDateTimeOffset,
        defaultEventTimeFormat,
        data.isVirtual ? data.virtualTimezone : data.timezone
      )} GMT +${
        data.isLocalStartDateTimeDST
          ? data.isVirtual
            ? data.virtualTimezoneOffsetDST
            : data.timezoneOffsetDST
          : data.isVirtual
          ? data.virtualTimezoneOffset
          : data.timezoneOffset
      }`
    );
  }
  if (data.localEndDateTimeOffset) {
    dates.push(
      `${timezoneFormat(
        data.localEndDateTimeOffset,
        defaultEventTimeFormat,
        data.isVirtual ? data.virtualTimezone : data.timezone
      )} GMT +${
        data.isLocalEndDateTimeDST
          ? data.isVirtual
            ? data.virtualTimezoneOffsetDST
            : data.timezoneOffsetDST
          : data.isVirtual
          ? data.virtualTimezoneOffset
          : data.timezoneOffset
      }`
    );
  }

  eventPage = {
    ...sharedData,
    address: data.address || '',
    latitude: data.latitude || null,
    longitude: data.longitude || null,
    className: CztPage.EVENTTIA_EVENT,
    date: dates.join(' - '),
    virtual: data.isVirtual || false,
    website: data.website || '',
  };

  return eventPage;
}

export function createPage(data: BasePage): OneOfThePages {
  const breadcrumbs: BreadcrumbInterface[] = [];

  if (data.breadcrumbs) {
    data.breadcrumbs.forEach((breadcrumb) => {
      if (
        !breadcrumb.title ||
        !breadcrumb.alternativeUrls ||
        breadcrumb.alternativeUrls.length < 1
      ) {
        return;
      }

      breadcrumbs.push({
        title: breadcrumb.title,
        url: breadcrumb.alternativeUrls[0],
      });
    });
  }

  if (data.title) {
    breadcrumbs.push({
      title: data.title,
    });
  }

  const sharedData: SharedData = {
    automatedTranslationMessage: data.automatedTranslationMessage?.trim() || '',
    breadcrumbs,
    canonicalPath:
      data.permanentPath || `/${data.nodeGuid}${data.nodeAliasPath}`,
    // We don't have views for other pages than those defined below in the switch,
    // so we will treat other pages as regular Page
    className: CztPage.PAGE,
    content: data.content || '',
    documentName: data.documentName || '',
    guid: data.nodeGuid || 'unknown',
    hideTeaserImage: !!data.hideImageOnPage,
    image: data.image,
    imageFilter: data.imageFilterType,
    perex: data.perex || '',
    showAutomatedTranslationVoting:
      data.showAutomatedTranslationVoting || false,
    showRightColumn: !!data.showRightColumn,
    subtitle: data.subtitle,
    title: data.title || '',
    topLayerImage: data.topLayerImage,
    url: data.url,
    widgets: {
      after: createWidgets(data.widgetsSharedAfter || []),
      before: createWidgets(data.widgetsSharedBefore || []),
      main: createWidgets(data.widgets || []),
      right: createWidgets(data.rightColWidgets || []),
    },
  };

  if (isEventApiPage(data)) {
    return enrichEventPage(sharedData, data);
  } else if (isArticleApiPage(data)) {
    return enrichArticlePage(sharedData, data);
  } else if (isEventtiaEventApiPage(data)) {
    return enrichEventtiaEventPage(sharedData, data);
  }

  return sharedData;
}
