import { Component, Prop } from 'vue-property-decorator';
import { VueComponent, VueComponentMixin } from '~/utils/vue-component';
import { ImageInterface } from '~/components/atoms/image/Image';
import { Banner } from '~/components/organisms';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { Headline } from '~/components/atoms';
import { ImageFilterMixin, ImageFilterMixinInterface } from '~/mixins';

export interface TeaserInterface extends ImageFilterMixinInterface {
  backgroundImage?: ImageInterface | null;
  title: string;
}

const rootClass = 'czt-teaser';

@Component
export default class Teaser
  extends VueComponentMixin<TeaserInterface, ImageFilterMixinInterface>(
    ImageFilterMixin
  )
  implements TeaserInterface {
  @Prop({ default: null })
  public backgroundImage!: ImageInterface | null;

  @Prop({ type: String })
  public title!: string;

  public render() {
    if (this.backgroundImage) {
      return (
        <Banner
          backgroundImage={this.backgroundImage}
          imageFilter={this.imageFilter}
          title={this.title}
          titleLevel={1}
        >
          {this.$slots.default}
        </Banner>
      );
    } else {
      return (
        <v-container class={`${rootClass} text-center pt-6 pb-1`}>
          <Headline level={1} underscore>
            <HtmlRenderer content={this.title} />
          </Headline>

          {this.$slots.default}
        </v-container>
      );
    }
  }
}
