import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { CarouselItemInterface } from '~/components/molecules/carouselItem/CarouselItem';
import { Carousel } from '~/components/organisms';
import { CztWidgets } from '~/utils/views/widgets';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected carouselItems: CarouselItemInterface[] = [
    {
      title: 'First test carousel item',
      image: {
        src: 'http://picsum.photos/1920/1080',
        alt: 'Wide Picture',
      },
      date: '01 Apr',
      location: { regionName: 'Bohemian Paradise' },
    },
    {
      title: 'Second test carousel item',
      image: {
        src: 'http://picsum.photos/1920/1080',
        alt: 'Wide Picture',
      },
      date: '02 Apr',
      location: { regionName: 'Brno' },
    },
    {
      title: 'Third test carousel item',
      image: {
        src: 'http://picsum.photos/1920/1080',
        alt: 'Wide Picture',
      },
      date: '03 Apr',
      location: { regionName: 'North Bohemia' },
    },
    {
      title: 'Fourth test carousel item',
      image: {
        src: 'http://picsum.photos/1920/1080',
        alt: 'Wide Picture',
      },
      date: '04 Apr',
      location: { regionName: 'Prague' },
    },
  ];

  public getContent() {
    return (
      <Carousel
        className={CztWidgets.CAROUSEL}
        items={this.carouselItems}
        title={'What to Do in Czech Republic Right Now'}
      />
    );
  }
  public getSettings() {
    return [];
  }
}
