import {
  Target,
  fixInternal,
  createInternal,
  isInternal,
  createExternal,
} from './link';

const link = {
  fixInternal,
  createInternal,
  isInternal,
  createExternal,
};

export { Target as LinkTarget, link };
