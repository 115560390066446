import { Component, Emit, Prop } from 'vue-property-decorator';
import { Colors } from '~/utils/theme/colors';
import { VueComponent } from '~/utils/vue-component';

interface CloseButtonInterface {
  light?: boolean;
  solid?: boolean;
  onClose?: () => any;
}

@Component
export default class CloseButton extends VueComponent<CloseButtonInterface> {
  @Prop({ default: false, type: Boolean })
  public light!: boolean;

  @Prop({ default: false, type: Boolean })
  public solid!: boolean;

  public render() {
    return (
      <v-btn
        small
        fab={this.solid}
        icon={!this.solid}
        onClick={this.emitClose}
        color={this.light ? Colors.TEXT_LIGHT : undefined}
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    );
  }

  @Emit('close')
  protected emitClose(): void {
    return;
  }
}
