import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { atoms } from './atoms';
import { functionalities } from './functionalities';
import { molecules } from './molecules';
import { organisms } from './organisms';
import { usage } from './usage';

interface TreeItem {
  id: string;
  name: string;
  category?: string;
  children?: TreeItem[];
}

@Component
export default class extends VueComponent<{}> {
  public activeComponent: TreeItem | null = null;

  protected showMenu: boolean = true;
  protected previews = {
    atoms,
    molecules,
    organisms,
    functionalities,
    usage,
  };

  public render() {
    return (
      <v-container fluid fill-height ma-0 pa-0>
        <v-row align='stretch' class='fill-height flex-nowrap' no-gutters>
          <v-navigation-drawer
            color='primary'
            stateless
            value={this.showMenu}
            fixed={!this.showMenu}
          >
            <v-treeview
              dark
              activatable
              open-on-click
              return-object
              color='accent'
              items={this.getItems()}
              {...{ on: { 'update:active': this.changeActive } }}
            ></v-treeview>
          </v-navigation-drawer>
          <v-btn
            color='secondary'
            fab
            dark
            small
            fixed
            bottom
            left
            onClick={() => {
              this.showMenu = !this.showMenu;
            }}
          >
            <v-icon>mdi-view-grid-outline</v-icon>
          </v-btn>
          <v-col style='overflow: hidden'>
            <v-sheet class='fill-height' width='100%'>
              {this.$createElement(this.getActiveComponent())}
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    );
  }

  protected getItems(): TreeItem[] {
    const items: TreeItem[] = [];

    for (const category in this.previews) {
      if (!this.previews.hasOwnProperty(category)) {
        continue;
      }
      items.push({
        id: category,
        name: category,
        children: this.getChildren(category),
      });
    }
    return items;
  }

  protected getChildren(category: string): TreeItem[] {
    const items: TreeItem[] = [];
    const components = (this.previews as { [key: string]: any })[category];
    for (const componentName in components) {
      if (!components.hasOwnProperty(componentName)) {
        continue;
      }

      items.push({
        id: componentName,
        name: componentName,
        category,
      });
    }
    return items;
  }

  protected changeActive(activeItem: TreeItem[]) {
    this.activeComponent = activeItem[0] || null;
  }

  protected getActiveComponent() {
    if (this.activeComponent && this.activeComponent.category) {
      const theComponent = (this.previews as {
        [key: string]: { [key: string]: any };
      })[this.activeComponent.category][this.activeComponent.name];
      return theComponent;
    }
  }
}
