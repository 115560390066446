import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class ThumbDownIcon extends VueComponent<{}> {
  public render() {
    const classes = ['czt-icon--common', 'czt-icon--thumb-down'];
    return (
      <i class={classes.join(' ')}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 16.3 17.7'
        >
          <path
            d='M.2.1H5v10.5H.2zm14.6 5.6c.7 0 1.3.6 1.3 1.4 0 .7-.6 1.4-1.3 1.4.7 0 1.3.6 1.3 1.4 0 .8-.6 1.4-1.4 1.4h-2.4c-1.3 0 1.1 1.1 1.5 4 .4 3.3-2.1 2.6-2.4-.1-.2-1.9-4.5-4.6-4.5-4.6V.1h7.9c.7 0 1.3.6 1.3 1.4s-.6 1.3-1.3 1.4c.7 0 1.3.6 1.3 1.4 0 .7-.6 1.3-1.3 1.4'
            fill='currentColor'
          />
        </svg>
      </i>
    );
  }
}
