import { Action, Module, Mutation } from 'vuex-module-decorators';
import { ItemData } from '~/components/atoms/select/Select';
import { CertifiedSubjectsListItem } from '~/components/templates/certifiedSubjects/CertifiedSubjectsList';
import AbstractModule from './AbstractModule';

@Module({
  name: 'CertifiedSubjectsModule',
  stateFactory: true,
  namespaced: true,
})
export default class CertifiedSubjectsModule extends AbstractModule {
  protected promiseCache: Promise<void> | null = null;
  public items: CertifiedSubjectsListItem[] = [];
  public regions: ItemData[] = [];
  public areas: ItemData[] = [];
  public loading: boolean = false;

  @Action({ rawError: true })
  public getCertifiedSubjects(): Promise<void> {
    if (this.promiseCache) {
      return this.promiseCache;
    }
    this.setLoading(true);
    const promise: Promise<void> = this.$api
      .certifiedSubjects()
      .certifiedSubjectsOfQualityGetItems()
      .then((result) => {
        const areas: string[] = [];
        const regions: string[] = [];
        const items: CertifiedSubjectsListItem[] = result
          .filter((item) => {
            return (
              item.area?.trim() && item.name?.trim() && item.region?.trim()
            );
          })
          .map((item, index) => {
            const rgs = item.region ? item.region.split('|') : [];
            const ars = item.area ? item.area.split('|') : [];
            ars.forEach((area) => {
              if (areas.indexOf(area) === -1) {
                areas.push(area);
              }
            });
            rgs.forEach((region) => {
              if (regions.indexOf(region) === -1) {
                regions.push(region);
              }
            });
            return {
              id: [item.name, item.area, item.region, index]
                .join('-')
                .split(' ')
                .join('_'),
              name: item.name || '',
              link: item.webUrl || '',
              areas: ars.map((cat) => {
                return {
                  text: cat,
                  value: cat
                    .toLowerCase()
                    .split(' ')
                    .join('_'),
                };
              }),
              regions: rgs.map((rg) => {
                return {
                  text: rg,
                  value: rg
                    .toLowerCase()
                    .split(' ')
                    .join('_'),
                };
              }),
            };
          });
        this.setAreas(
          areas.map((category) => {
            return {
              text: category,
              value: category
                .toLowerCase()
                .split(' ')
                .join('_'),
            };
          })
        );
        this.setRegions(
          regions.map((region) => {
            return {
              text: region,
              value: region
                .toLowerCase()
                .split(' ')
                .join('_'),
            };
          })
        );
        this.setItems(items);
      })
      .finally(() => {
        this.setLoading(false);
      });
    this.setPromise(promise);
    return promise;
  }

  @Mutation
  protected setPromise(promise: Promise<void>) {
    this.promiseCache = promise;
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setItems(items: CertifiedSubjectsListItem[]) {
    this.items = items;
  }

  @Mutation
  protected setAreas(areas: ItemData[]) {
    this.areas = areas;
  }

  @Mutation
  protected setRegions(regions: ItemData[]) {
    this.regions = regions;
  }
}
