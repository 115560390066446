export default {
  app: {
    newsletter: {
      bottomText:
        'Přihlášením k newsletteru potvrzujete podmínky používání a ochrany osobních údajů. Podrobnější informaci o zpracování osobních údajů agenturou CzechTourism naleznete na <a href="/GDPR" target="_blank">zde</a>.',
      button: 'Přihlásit',
      dialog: {
        headline: 'Vyberte Newsletter',
        notice:
          'Vaše kontaktní údaje využijeme výhradně ke komunikaci s Vámi a nebudeme je využívat ani sdílet jinak, než za tímto účelem. Zpracování bude probíhat v souladu s příslušnými právními normami o ochraně osobních údajů. Nebudeme využívat žádný software pro profilování na základě Vašeho chování nebo Vašich preferencí a nebude o Vás rozhodováno automatem. K vašim údajům získají přístup jen naši zaměstnanci a pracovníci servisních firem, které zajišťují provoz a rozvoj programového vybavení, které ke komunikaci používáme. {0}',
        company: 'Správcem osobních údajů je {0}, {1}',
        link: {
          privacy:
            'Podrobnější informaci o zpracování osobních údajů agenturou CzechTourism naleznete zde.',
          controller: 'Česká centrála cestovního ruchu – CzechTourism',
          web: 'www.czechtourism.cz',
        },
        url: {
          privacy: '~/GDPR',
          controller: 'https://czechtourism.cz',
          web: 'https://www.czechtourism.cz/',
        },
      },
      inputPlaceholder: 'Vaše e-mailová adresa',
      newsletterError: 'Zdá se, že pro vás k odběru nemáme žádné newslettery.',
      serverError: 'Došlo k chybě. Zkuste to prosím znovu.',
      success:
        'Byl jste úspěšně přihlášen k newsletteru. Potvrďte přihlášení kliknutím na odkaz v mailu, který vám byl zaslán.',
      title: 'Odebírat newsletter',
      topText: 'Přihlašte se a buďte informování o všech novinkách.',
      validityError: 'Zadali jste neplatnou e-mailovou adresu.',
    },
  },
};
