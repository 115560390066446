import { Component, Prop } from 'vue-property-decorator';
import StatusCode from '~/utils/http/StatusCode';
import { VueComponent } from '~/utils/vue-component';

interface RedirectionInterface {
  location: string;
}

/**
 * Hello mega hack, goodbye redirection issues
 * @see server/main.js - hasStatusCode and getRedirectionUrl functions
 */
@Component
export default class Redirect307 extends VueComponent<RedirectionInterface>
  implements RedirectionInterface {
  @Prop({ required: true, type: String })
  public location!: string;

  public render() {
    return (
      <div>
        <StatusCode code={307} />
        <abbr id='ssr-redirect-location' data-location={this.location} />
      </div>
    );
  }
}
