import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { MediaBackground } from '~/components/molecules';
import { CztImage } from '~/utils/atoms/image';
import { ThemeColors } from '~/utils/theme';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';
import Tabs, { TabItemInterface } from '~/components/molecules/tabs/Tabs';
import { CztWidgets } from '~/utils/views/widgets';

export interface TabsWidgetInterface {
  className: CztWidgets;
  image?: CztImage;
  isBottomSpacingCollapsed?: boolean;
  isTopSpacingCollapsed?: boolean;
  items: TabItemInterface[];
  overlayColor?: ThemeColors;
}

export function isTabs(data: any): data is TabsWidgetInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.TABS &&
    typeof data.items !== 'undefined'
  );
}

const rootClass = 'czt-tabs-widget';

const sizes: Sizes[] = [
  {
    size: 100,
    unit: 'vw',
  },
];

const pageSize = 6;

@Component
export default class TabsWidget extends VueComponent<TabsWidgetInterface>
  implements TabsWidgetInterface {
  @Prop()
  public image?: CztImage;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ required: true, type: Array })
  public items!: TabItemInterface[];

  @Prop()
  public overlayColor?: ThemeColors;

  public className = CztWidgets.TABS;

  protected itemsLimit: number = pageSize;

  public render() {
    if (this.image) {
      return (
        <div class={rootClass}>
          <MediaBackground
            image={this.image}
            sizes={sizes}
            overlayColor={
              this.image && this.image.src
                ? this.overlayColor
                  ? this.overlayColor
                  : undefined
                : undefined
            }
          >
            {this.renderContent()}
          </MediaBackground>
        </div>
      );
    } else {
      return <v-sheet class={rootClass}>{this.renderContent()}</v-sheet>;
    }
  }

  protected renderContent() {
    const containerClasses = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container class={containerClasses.join(' ')}>
        <v-row>
          <v-col class='py-0'>
            <Tabs items={this.items} light={this.image?.src ? true : false} />
          </v-col>
        </v-row>
      </v-container>
    );
  }
}
