import { RouteConfig } from 'vue-router';

import Preview from '~/views/Preview';

const config: RouteConfig = {
  component: Preview,
  path: 'Preview',
};

export default config;
