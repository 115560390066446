export default {
  app: {
    event: {
      noResult: 'Událost nebyla nalezena',
      link: 'Odkaz na akci',
      info: 'Informace',
      virtual: 'Tato akce je virtuální',
    },
  },
};
