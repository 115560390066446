import toBoolean from '../toBoolean';
import { CookieCategory } from './config';

export default function(
  categoryValue: string,
  category: CookieCategory
): boolean {
  if (categoryValue.indexOf(category) > -1) {
    return toBoolean(categoryValue.replace(category, '')) || false;
  }
  return false;
}
