import Carousel from './Carousel';
import Banner from './Banner';
import Gallery from './Gallery';
import Slider from './Slider';

export const organisms = {
  Banner,
  Carousel,
  Gallery,
  Slider,
};
