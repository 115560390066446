import { Component } from 'vue-property-decorator';
import { Headline } from '~/components/atoms';
import { Align, LineColor } from '~/components/atoms/headline/Headline';
import { ThemeColors } from '~/utils/theme';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected level: number = 3;

  protected underscore: boolean = false;

  protected light: boolean = false;

  protected align: Align = Align.CENTER;

  protected lineColor: LineColor = LineColor.RED;

  public getContent() {
    return (
      <v-sheet
        class='fill-height pa-3'
        width='100%'
        color={this.light ? ThemeColors.TEXT : ''}
      >
        <Headline
          level={this.level}
          underscore={this.underscore}
          light={this.light}
          align={this.align}
          lineColor={this.lineColor}
        >
          Headline
        </Headline>
      </v-sheet>
    );
  }

  public getSettings() {
    return [
      <v-slider
        label='Level'
        max='4'
        min='1'
        step='1'
        v-model={this.level}
        hide-details
      />,
      <v-switch label='Underline' color='accent' v-model={this.underscore} />,
      <v-switch label='Light' color='accent' v-model={this.light} />,
      <v-select
        label='Align'
        v-model={this.align}
        items={[Align.LEFT, Align.CENTER, Align.RIGHT]}
      />,
      <v-select
        label='Line color'
        v-model={this.lineColor}
        items={[LineColor.RED, LineColor.WHITE]}
      />,
    ];
  }
}
