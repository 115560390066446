import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import SliderIndicatorBase from './SliderIndicatorBase';

@Component
export default class SliderIndicatorActiveIcon extends VueComponent<{}> {
  public render() {
    return <SliderIndicatorBase active />;
  }
}
