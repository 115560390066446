import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { IconText } from '~/components/molecules';
import { IconPosition } from '~/components/molecules/iconText/IconText';

/**
 * This component should never be used outside of the HTML Renderer.
 */
@Component
export default class RightArrowText extends VueComponent<{}> {
  public render() {
    return (
      <IconText
        bordered={true}
        icon={['common', 'ArrowRight']}
        iconPosition={IconPosition.APPEND}
        iconMargin='15px'
      >
        {this.$slots.default}
      </IconText>
    );
  }
}
