export default {
  app: {
    menu: {
      about: 'About Us',
      contacts: 'Contacts',
      homepage: 'Home',
      news: 'News',
    },
  },
};
