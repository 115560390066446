import { Component, Prop } from 'vue-property-decorator';
import VClamp from 'vue-clamp';

import style from './Classic.scss';
import ImageTile from '~/components/molecules/imageTile/ImageTile';
import { GridItem } from '~/components/organisms/gridList/GridList';
import { VueComponent } from '~/utils/vue-component';
import { format } from '~/utils/date-fns';
import { parse } from 'date-fns';
import { IconText } from '..';

interface ClassicGridItemInterface {
  item: GridItem;
  compact?: boolean;
}

const rootClass = 'czt-grid-list__item';

@Component({
  components: {
    VClamp,
  },
  style,
})
export default class ClassicGridItem
  extends VueComponent<ClassicGridItemInterface>
  implements ClassicGridItemInterface {
  @Prop({ required: true })
  public item!: GridItem;

  @Prop({ type: Boolean, default: false })
  public compact!: boolean;

  protected get dateText(): JSX.Element | null {
    const dateArray: JSX.Element[] = [];

    if (this.item.date && this.item.endDate) {
      const start = format(
        parse(this.item.date),
        'MMM D YYYY',
        this.$i18n.locale
      );
      const end = format(
        parse(this.item.endDate),
        'MMM D YYYY',
        this.$i18n.locale
      );

      dateArray.push(<time datetime={this.item.date}>{start}</time>);
      if (start !== end) {
        dateArray.push(
          <span> - </span>,
          <time datetime={this.item.endDate}>{end}</time>
        );
      }
    } else if (this.item.date) {
      dateArray.push(
        <time datetime={this.item.date}>
          {format(parse(this.item.date), 'MMM D YYYY', this.$i18n.locale)}
        </time>
      );
    }

    return dateArray.length > 0 ? (
      <div class={`${rootClass}__date`}>{dateArray}</div>
    ) : null;
  }

  public render() {
    return (
      <v-hover
        scopedSlots={{
          default: ({ hover }: { hover: boolean }) => {
            const classes = [rootClass, `${rootClass}--classic`];

            if (hover) {
              classes.push(`${rootClass}--hover`);
            }

            // If grid list item width should ever be changed, sizes in ImageTile.tsx need to be adjusted too

            return (
              <v-col
                class={classes.join(' ')}
                cols={6}
                md={this.compact ? 3 : 4}
              >
                <ImageTile
                  image={this.item.image}
                  imageFilter={this.item.imageFilter}
                  link={this.item.link}
                >
                  {this.item.location && this.item.location.regionName && (
                    <div class={`${rootClass}__location`}>
                      <IconText icon={['common', 'Location']}>
                        {this.item.location.regionName}
                      </IconText>
                    </div>
                  )}
                  <v-fade-transition>
                    {(() => {
                      if (!hover || !this.item.perex) {
                        return (
                          <v-clamp tag='span' autoresize={true} max-lines={5}>
                            {this.item.title}
                          </v-clamp>
                        );
                      }
                    })()}
                  </v-fade-transition>
                  {this.dateText}
                </ImageTile>
                <v-fade-transition>
                  {hover && this.item.perex && (
                    <v-overlay absolute class={`${rootClass}__overlay`}>
                      <v-clamp
                        tag='p'
                        autoresize={true}
                        max-lines={5}
                        class={rootClass + '__overlay__content'}
                      >
                        {this.item.perex.replace(/\s*&nbsp;\s*/g, '\u00A0')}
                      </v-clamp>
                    </v-overlay>
                  )}
                </v-fade-transition>
              </v-col>
            );
          },
        }}
      />
    );
  }
}
