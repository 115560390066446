import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { InputField } from '~/components/atoms';
import { InputFieldState } from '~/components/atoms/inputField/InputField';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected inputValue: string = '';
  protected inputState: InputFieldState = InputFieldState.DEFAULT;
  protected disabled: boolean = false;
  protected readOnly: boolean = false;
  protected clearable: boolean = true;

  public getContent() {
    return [
      <div style='background-color: #333333; color: #FFFFFF; width: 400px; margin: 0 auto; padding: 50px;'>
        <InputField
          placeholder={'Enter value'}
          v-model={this.inputValue}
          state={this.inputState}
          disabled={this.disabled}
          readOnly={this.readOnly}
          clearable={this.clearable}
        />
        value: {this.inputValue}
      </div>,
    ];
  }
  public getSettings() {
    return [
      <v-select
        label='State'
        v-model={this.inputState}
        items={[
          InputFieldState.DEFAULT,
          InputFieldState.SUCCESS,
          InputFieldState.LOADING,
          InputFieldState.ERROR,
        ]}
      />,
      <v-switch label='Disabled' color='accent' v-model={this.disabled} />,
      <v-switch label='Read Only' color='accent' v-model={this.readOnly} />,
      <v-switch label='Clearable' color='accent' v-model={this.clearable} />,
    ];
  }
}
