import { Component, Emit, Prop } from 'vue-property-decorator';
import { Button, InputField, Multiselect } from '~/components/atoms';
import Select, { ItemData } from '~/components/atoms/select/Select';
import { VueComponent } from '~/utils/vue-component';

import style from './VenueFinderFilter.scss';
import { ThemeColors } from '~/utils/theme';
import { VenueFilter } from '~/app/core/store/modules/VenueFinderModule';

const rootClass = 'czt-venue-finder-filter';

export type FilterEmit = Partial<VenueFilter>;

export interface VenueFinderFilterOptions {
  locations: ItemData[];
  tags: ItemData[];
}

interface VenueFinderFilterInterface {
  filterOptions: VenueFinderFilterOptions;
  loading?: boolean;
  onFilter?: (data: FilterEmit) => void;
}

@Component({
  style,
})
export default class VenueFinderFilter
  extends VueComponent<VenueFinderFilterInterface>
  implements VenueFinderFilterInterface {
  @Prop({ required: true })
  public filterOptions!: VenueFinderFilterOptions;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  protected search: string | null = '';
  protected location: string = '';
  protected tags: string[] = [];
  protected capacityMin: number | null = null;
  protected capacityMax: number | null = null;

  public render() {
    return (
      <v-sheet class={`${rootClass} mb-3`} color={ThemeColors.PRIMARY}>
        <form onSubmit={this.emitFilter}>
          <v-col class='py-0'>
            <v-row>
              <v-col class='py-0'>
                <v-row no-gutters>
                  <v-col cols='12'>
                    <v-row>
                      <v-col cols='12' lg='8'>
                        <InputField
                          class={`${rootClass}__input`}
                          v-model={this.search}
                          clearable
                          label={this.$t('app.venues.search')}
                        />
                      </v-col>
                      <v-col cols='12' md='6' lg='4'>
                        <Multiselect
                          items={this.filterOptions.tags}
                          v-model={this.tags}
                          label={this.$t('app.venues.tags')}
                        />
                      </v-col>
                      <v-col cols='12' md='12' lg='4'>
                        <Select
                          items={this.filterOptions.locations}
                          v-model={this.location}
                          label={this.$t('app.venues.location')}
                        />
                      </v-col>
                      <v-col cols='12' md='6' lg='4'>
                        <InputField
                          class={`${rootClass}__input`}
                          v-model={this.capacityMin}
                          clearable
                          label={this.$t('app.venues.minCapacity')}
                          type='number'
                        />
                      </v-col>
                      <v-col cols='12' md='6' lg='4'>
                        <InputField
                          class={`${rootClass}__input`}
                          v-model={this.capacityMax}
                          clearable
                          label={this.$t('app.venues.maxCapacity')}
                          type='number'
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' md='auto' class='text-center'>
                <Button loading={this.loading} submit>
                  {this.$t('app.venues.submit')}
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </form>
      </v-sheet>
    );
  }

  @Emit('filter')
  protected emitFilter(e?: Event): FilterEmit {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    return {
      location: this.location || undefined,
      search: (this.search && this.search.trim()) || undefined,
      tags: this.tags.length > 0 ? this.tags : undefined,
      numberOfHostsMax: this.capacityMax || undefined,
      numberOfHostsMin: this.capacityMin || undefined,
    };
  }
}
