import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { Button } from '~/components/atoms';
import { Style } from '~/components/atoms/button/Button';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected buttonStyle: string = Style.PRIMARY;
  protected buttonLoading: boolean = false;
  protected buttonDisabled: boolean = false;

  public getContent() {
    const bgColor =
      this.buttonStyle === Style.PRIMARY_LIGHT ||
      this.buttonStyle === Style.SECONDARY_LIGHT
        ? '#000'
        : 'transparent';
    return [
      <v-sheet className='fill-height pa-3' width='100%'>
        <div
          style={`padding-top: 20px; padding-bottom: 10px; width: 100%; text-align: center; background-color: ${bgColor};`}
        >
          <Button
            buttonStyle={this.buttonStyle}
            disabled={this.buttonDisabled}
            loading={this.buttonLoading}
          >
            Button
          </Button>
        </div>
      </v-sheet>,
    ];
  }
  public getSettings() {
    const styles = [
      { value: Style.PRIMARY, text: 'Primary' },
      { value: Style.SECONDARY, text: 'Secondary' },
      { value: Style.PRIMARY_LIGHT, text: 'Primary light' },
      { value: Style.SECONDARY_LIGHT, text: 'Secondary light' },
    ];
    return [
      <v-select
        label='Button style'
        v-model={this.buttonStyle}
        items={styles}
      />,
      <v-switch label='Loading' color='accent' v-model={this.buttonLoading} />,
      <v-switch
        label='Disabled'
        color='accent'
        v-model={this.buttonDisabled}
      />,
    ];
  }
}
