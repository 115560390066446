import { Component, Emit, Prop, Ref } from 'vue-property-decorator';
import { ThemeRatios } from '~/utils/theme';
import { VueComponent } from '~/utils/vue-component';
import { VideoInterface } from '~/components/atoms/video/Video';

import style from './VideoWrapper.scss';
import { Video } from '~/components/atoms';

import Timeout = NodeJS.Timeout;
import { ImageInterface } from '~/components/atoms/image/Image';
import { ImageWrapper } from '~/components/molecules';

export interface VideoWrapperInterface {
  video: VideoInterface;
  poster?: ImageInterface;
  ratio?: ThemeRatios;
  onPlaying?: () => void;
  onPaused?: () => void;
  onEnded?: () => void;
  onMuted?: () => void;
  onUnmuted?: () => void;
}

@Component({
  style,
})
export default class VideoWrapper extends VueComponent<VideoWrapperInterface>
  implements VideoWrapperInterface {
  @Prop({ default: ThemeRatios.auto })
  public ratio!: ThemeRatios;

  @Prop({ required: true })
  public video!: VideoInterface;

  @Prop()
  public poster?: ImageInterface;

  @Ref('wrapper')
  protected wrapper!: HTMLElement;

  @Ref('posterWrapper')
  protected readonly posterWrapper?: ImageWrapper;

  @Ref('videoComponent')
  protected readonly videoComponent?: Video;

  protected resizeThrottle?: Timeout;

  protected posterActive: boolean = true;

  protected videoHeight: number = 0;

  protected get displayPoster(): boolean {
    return !!this.poster && !!this.video.autoPlay && this.posterActive;
  }

  public mounted() {
    window.addEventListener('resize', this.handleResize);
    this.setCover();
  }

  public render() {
    return (
      <div
        class={
          'czt-video-wrapper czt-video-wrapper--ratio-' +
          ThemeRatios[this.ratio]
        }
        ref='wrapper'
      >
        <Video
          videoId={this.video.videoId}
          src={this.video.src}
          modestBranding={this.video.modestBranding}
          fullScreenButton={this.video.fullScreenButton}
          autoPlay={this.video.autoPlay}
          disableKeyboard={this.video.disableKeyboard}
          loop={this.video.loop}
          height={this.videoHeight}
          class={'czt-video-wrapper__inner'}
          ref='videoComponent'
          onPlaying={this.playing}
          onEnded={this.ended}
          onMuted={this.muted}
          onPaused={this.paused}
          onUnmuted={this.unmuted}
          mute={this.video.mute}
          showControls={this.video.showControls}
        />
        {(() => {
          if (this.poster && this.video.autoPlay && this.posterActive) {
            return (
              <ImageWrapper
                image={this.poster}
                ratio={this.ratio}
                ref='posterWrapper'
              />
            );
          }
        })()}
      </div>
    );
  }

  public toggleMute() {
    if (this.videoComponent) {
      this.videoComponent.toggleMute();
    }
  }

  public togglePlayback() {
    if (this.videoComponent) {
      this.videoComponent.togglePlayback();
    }
  }

  @Emit('playing')
  protected playing() {
    this.posterActive = false;
    return;
  }

  @Emit('paused')
  protected paused() {
    this.posterActive = true;
    return;
  }

  @Emit('muted')
  protected muted() {
    return;
  }

  @Emit('unmuted')
  protected unmuted() {
    return;
  }

  @Emit('ended')
  protected ended() {
    this.posterActive = true;
    return;
  }

  protected beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  protected handleResize() {
    if (this.resizeThrottle) {
      clearTimeout(this.resizeThrottle);
    }

    this.resizeThrottle = setTimeout(() => {
      this.setCover();
    }, 50);
  }

  public setCover() {
    let clientRatio = this.ratio;
    const videoRatio = 16 / 9;

    if (this.ratio === ThemeRatios.auto) {
      clientRatio = this.wrapper.clientWidth / this.wrapper.clientHeight;
    }

    if (clientRatio < videoRatio) {
      this.videoHeight = this.wrapper.clientHeight;
    } else {
      this.videoHeight = this.wrapper.clientWidth / videoRatio;
    }

    if (this.posterWrapper) {
      this.posterWrapper.setCover();
    }

    this.$forceUpdate();
  }
}
