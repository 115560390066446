import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { Headline, Icons } from '~/components/atoms/';
import { Align } from '~/components/atoms/headline/Headline';
import Icon, { IconName } from '~/components/atoms/icons/Icon';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected size: number = 2;
  protected color: string = '#000000';
  protected genericIcon: IconName = ['common', 'ArrowLeft'];

  public getContent() {
    return [
      <v-sheet
        className='fill-height pa-3'
        width='100%'
        style={'padding-top: 10px;'}
      >
        <Headline level={4} underscore align={Align.CENTER}>
          Common icons
        </Headline>
        <div
          style={`padding-top: 10px; padding-bottom: 50px; text-align: center; width: 100%; color: ${this.color};`}
        >
          <Icons.common.ArrowDown size={this.size} />
          <Icons.common.ArrowLeft size={this.size} />
          <Icons.common.ArrowRight size={this.size} />
          <Icons.common.ArrowUp size={this.size} />
          <Icons.common.Calendar size={this.size} />
          <Icons.common.Close size={this.size} />
          <Icons.common.Dropdown size={this.size} />
          <Icons.common.Location size={this.size} />
          <Icons.common.MapPin size={this.size} />
          <Icons.common.Menu size={this.size} />
          <Icons.common.Search size={this.size} />
          <Icons.common.SliderIndicator size={this.size} />
          <Icons.common.SliderIndicatorActive size={this.size} />
          <Icons.common.Star size={this.size} />
        </div>
        <Headline level={4} underscore align={Align.CENTER}>
          Social icons
        </Headline>
        <div
          style={
            'padding-top: 10px; padding-bottom: 50px; text-align: center; width: 100%;'
          }
        >
          <Icons.social.Facebook size={this.size} />
          <Icons.social.Instagram size={this.size} />
          <Icons.social.InstagramWhite
            size={this.size}
            style={'background-color: #000;'}
          />
          <Icons.social.Pinterest size={this.size} />
          <Icons.social.Twitter size={this.size} />
          <Icons.social.LinkedIn size={this.size} />
        </div>
        <Headline level={4} underscore align={Align.CENTER}>
          Generic icon
        </Headline>
        <div
          style={`padding-top: 10px; padding-bottom: 50px; text-align: center; width: 100%; color: ${this.color};`}
        >
          <Icon name={this.genericIcon} size={this.size} />
        </div>
      </v-sheet>,
    ];
  }
  public getSettings() {
    const iconsList = [];
    for (const category in Icons) {
      if (!Icons.hasOwnProperty(category)) {
        continue;
      }
      const categoryIcons = (Icons as { [key: string]: any })[category];
      for (const iconKey in categoryIcons) {
        if (!categoryIcons.hasOwnProperty(iconKey)) {
          continue;
        }
        iconsList.push({ value: [category, iconKey], text: iconKey });
      }
    }
    return [
      <v-slider
        label='Size'
        max='4'
        min='1'
        step='1'
        v-model={this.size}
        hide-details
      />,
      <v-color-picker
        v-model={this.color}
        hide-mode-switch
        hide-inputs
        flat
        light
      />,
      <v-select
        items={iconsList}
        filled
        label='Generic icon type'
        v-model={this.genericIcon}
      />,
    ];
  }
}
