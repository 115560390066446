import { Component, Prop } from 'vue-property-decorator';
import VueMasonryWall from 'vue-masonry-wall';

import { GalleryItem } from '~/components/molecules';
import { ImageInterface } from '~/components/atoms/image/Image';
import { GalleryItemInterface } from '~/components/molecules/galleryItem/GalleryItem';
import { VueComponent } from '~/utils/vue-component';
import { CztWidgets } from '~/utils/views/widgets';
import { Image as CztImage } from '~/components/atoms';

import style from './Gallery.scss';
import Lightbox from '~/components/organisms/lightbox/Lightbox';
import { ThemeRatios } from '~/utils/theme';
import { LocaleMessage } from 'vue-i18n';

export interface GalleryInterface {
  className: CztWidgets;
  eager?: boolean;
  items: GalleryItemInterface[];
  ratio?: ThemeRatios;
  galleryStyle?: string;
  title?: string | LocaleMessage;
  useContentWidth?: boolean;
}

export function isGallery(data: any): data is GalleryInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.GALLERY &&
    typeof data.items !== 'undefined'
  );
}

@Component({
  style,
})
export default class Gallery extends VueComponent<GalleryInterface>
  implements GalleryInterface {
  @Prop({ required: true, type: Array })
  public items!: GalleryItemInterface[];

  @Prop()
  public ratio?: ThemeRatios;

  @Prop()
  public galleryStyle?: string;

  @Prop({ default: false, type: Boolean })
  public eager!: boolean;

  @Prop({ type: Boolean, default: false })
  public useContentWidth!: boolean;

  public className = CztWidgets.GALLERY;

  protected rootClass: string = 'czt-gallery';

  protected showLightbox: boolean = false;

  protected lightboxIndex: number = 0;

  protected get images(): ImageInterface[] {
    return this.items.map((galleryItem) => {
      return galleryItem.image;
    });
  }

  public render() {
    if (this.items.length < 1) {
      return;
    }
    const classes = [this.rootClass];
    if (this.ratio) {
      classes.push(`${this.rootClass}--has-ratio`);
    }
    if (this.galleryStyle === 'masonryGrid') {
      return this.renderGrid();
    }

    let gallery;
    if (this.items.length === 1) {
      gallery = (
        <div
          class={classes.join(' ')}
          style={{
            height: this.ratio ? 'auto' : '600px',
          }}
        >
          <GalleryItem
            title={this.items[0].title}
            subtitle={this.items[0].subtitle}
            image={this.items[0].image}
            imageFilter={this.items[0].imageFilter}
            ratio={this.ratio || this.items[0].ratio}
            video={this.items[0].video}
            showVideoControls={this.items[0].showVideoControls}
          />
        </div>
      );
    } else {
      gallery = this.renderSlider();
    }

    if (this.useContentWidth) {
      return (
        <v-container>
          <v-row>
            <v-col cols={12}>{gallery}</v-col>
          </v-row>
        </v-container>
      );
    }

    return gallery;
  }

  protected renderSlider() {
    const classes = [this.rootClass];
    if (this.ratio) {
      classes.push(`${this.rootClass}--has-ratio`);
    }
    return (
      <v-carousel
        class={classes.join(' ')}
        height={this.ratio ? 'auto' : '600px'}
        show-arrows-on-hover
        hide-delimiter-background
      >
        {this.items.map((item: GalleryItemInterface, key: number) => {
          return (
            <v-carousel-item key={item.title + key} eager={this.eager}>
              <GalleryItem
                image={item.image}
                imageFilter={item.imageFilter}
                ratio={this.ratio || item.ratio}
                showVideoControls={item.showVideoControls}
                subtitle={item.subtitle}
                title={item.title}
                video={item.video}
              />
            </v-carousel-item>
          );
        })}
      </v-carousel>
    );
  }

  protected renderGrid() {
    return (
      <v-container>
        <VueMasonryWall
          items={this.items}
          options={{
            padding: 12,
            width: 400,
          }}
          ssr={{
            columns: 2,
          }}
          scopedSlots={{
            default: ({
              item,
              index,
            }: {
              item: GalleryItemInterface;
              index: number;
            }) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    this.lightboxIndex = index;
                    this.showLightbox = true;
                  }}
                >
                  <v-card>
                    <CztImage
                      class={`${this.rootClass}__grid-image`}
                      src={item.image.src}
                      srcset={item.image.srcset}
                      alt={item.image.alt}
                    />
                    {item.title && <v-card-title>{item.title}</v-card-title>}
                    {item.subtitle && (
                      <v-card-subtitle
                        class={`${this.rootClass}__grid-image__subtitle`}
                      >
                        {item.subtitle}
                      </v-card-subtitle>
                    )}
                  </v-card>
                </div>
              );
            },
          }}
        />
        <Lightbox
          images={this.images}
          showIndexOnOpen={this.lightboxIndex}
          onClose={() => {
            this.showLightbox = false;
          }}
          shown={this.showLightbox}
        />
      </v-container>
    );
  }
}
