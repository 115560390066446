import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { VideoWrapper } from '~/components/molecules';
import { VideoInterface } from '~/components/atoms/video/Video';
import { ThemeRatios } from '~/utils/theme';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected video: VideoInterface = {
    videoId: 'Rvwrvy54LQo',
  };

  public getContent() {
    return [
      <v-container>
        <v-row>
          <v-col cols={3}>
            <div style='background-color: #333333; color: #FFFFFF;'>
              <div style='text-align: center; font-wight: bold;'>4:3</div>
              <VideoWrapper video={this.video} ratio={ThemeRatios['4x3']} />
            </div>
          </v-col>
          <v-col cols={3}>
            <div style='background-color: #333333; color: #FFFFFF;'>
              <div style='text-align: center; font-wight: bold;'>16:9</div>
              <VideoWrapper video={this.video} ratio={ThemeRatios['16x9']} />
            </div>
          </v-col>
          <v-col cols={3}>
            <div style='background-color: #333333; color: #FFFFFF;'>
              <div style='text-align: center; font-wight: bold;'>2:3</div>
              <VideoWrapper video={this.video} ratio={ThemeRatios['2x3']} />
            </div>
          </v-col>
        </v-row>
      </v-container>,
    ];
  }
  public getSettings() {
    return [];
  }
}
