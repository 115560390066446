import {
  CztPage,
  EventPage,
  EventtiaEventPage,
  Page,
  PlacePage,
  RegionPage,
} from '~/utils/views/index';

export function isPage(data: any): data is Page {
  return data && data.className && data.className === CztPage.PLACE;
}

export function isPlacePage(data: any): data is PlacePage {
  return data && data.className && data.className === CztPage.PLACE;
}

export function isEventPage(data: any): data is EventPage {
  return data && data.className && data.className === CztPage.EVENT;
}

export function isEventtiaEventPage(data: any): data is EventtiaEventPage {
  return data && data.className && data.className === CztPage.EVENTTIA_EVENT;
}

export function isRegionPage(data: any): data is RegionPage {
  return data && data.className && data.className === CztPage.REGION;
}
