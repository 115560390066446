import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class FacebookIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--social czt-icon--facebook'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 35 35'
        >
          <defs />
          <path fill='#004088' d='M-.016-.002h35.032V35.03H-.016z' />
          <path
            fill='#fff'
            fill-rule='nonzero'
            d='M19.584 26.498h-4.202v-7.775h-2.513v-3.73h2.513v-3.967c0-2.239 1.296-3.495 3.769-3.495h3.496v3.495h-1.65c-1.256 0-1.413.746-1.413 1.492v2.474h3.063v3.73h-3.063v7.776z'
          />
        </svg>
      </i>
    );
  }
}
