import {
  ContentWidgetData,
  ContentWidgets,
  isContentWidgetData,
} from './types';

export interface FacebookContentWidgetData extends ContentWidgetData {
  PostURL?: string;
  Center?: string;
}

export function isFacebookContentWidgetData(
  data: any
): data is FacebookContentWidgetData {
  return isContentWidgetData(data) && data.name === ContentWidgets.FACEBOOK;
}

export function getFacebookComponentString(
  data: FacebookContentWidgetData
): string {
  // Hack to solve different prop naming
  const postUrlKey: string | undefined = Object.keys(data).find((index) => {
    return index.toLowerCase() === 'posturl';
  });
  const postUrl = postUrlKey ? data[postUrlKey] : undefined;
  if (!postUrl || typeof postUrl !== 'string') return '';
  let postData: string = '';
  try {
    postData = decodeURIComponent((postUrl + '').replace(/\+/g, ' '));
  } catch {
    return '';
  }
  const centerKey: string | undefined = Object.keys(data).find((index) => {
    return index.toLowerCase() === 'center';
  });
  return postData
    ? `<div class="czt-post-widget czt-post-widget--facebook${
        centerKey && data[centerKey] && data[centerKey] === 'True'
          ? ' czt-post-widget--centered'
          : ''
      }">
  <div class="fb-post" data-href="${postData}"> </div></div>`
    : '';
}
