import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class YoutubeSimpleIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--social czt-icon--youtube-simple'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 14'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path d='M19.6 2.2c-.2-.9-.9-1.5-1.8-1.8C16.3 0 10 0 10 0S3.7 0 2.2.4C1.3.6.6 1.3.4 2.2 0 3.8 0 7 0 7s0 3.3.4 4.8c.2.9.9 1.5 1.8 1.8 1.6.4 7.8.4 7.8.4s6.3 0 7.8-.4c.9-.2 1.5-.9 1.8-1.8.4-1.5.4-4.8.4-4.8s0-3.2-.4-4.8zM8 10V4l5.2 3L8 10z' />
        </svg>
      </i>
    );
  }
}
