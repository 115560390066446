import { VenueFinderLanguage } from './enums';
import {
  MeatspaceDetailResponse,
  MeatspaceVenueDetail,
  MeatspaceError,
  MeatspaceListLocationsResponse,
  MeatspaceListTagsResponse,
  MeatspaceLocation,
  MeatspaceSearchResponse,
  MeatspaceSearchResponsePayload,
  MeatspaceVenueSearch,
  MeatspaceSetLanguageResponse,
  MeatspaceTag,
  MeatspaceType,
  MeatspaceListTypesResponse,
} from './types';

export function isMeatspaceSearchResponse(
  data: any
): data is MeatspaceSearchResponse {
  return (
    data &&
    typeof data === 'object' &&
    data.success === true &&
    typeof data.payload === 'object'
  );
}

export function isMeatspaceDetailResponse(
  data: any
): data is MeatspaceDetailResponse {
  return (
    data &&
    typeof data === 'object' &&
    data.success === true &&
    typeof data.payload === 'object'
  );
}

export function isMeatspaceError(data: any): data is MeatspaceError {
  return data && typeof data === 'object' && typeof data.message === 'string';
}

export function isMeatspaceSearchResponsePayload(
  data: any
): data is MeatspaceSearchResponsePayload {
  return (
    data &&
    typeof data === 'object' &&
    (data.offset === null || typeof data.offset === 'number') &&
    typeof data.showing === 'number' &&
    typeof data.total === 'number' &&
    data.venues !== undefined
  );
}

export function isMeatspaceVenueSearchItem(
  data: any
): data is MeatspaceVenueSearch {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.id === 'number' &&
    typeof data.path === 'string' &&
    typeof data.url === 'string' &&
    typeof data.equipment !== 'undefined' &&
    typeof data.images !== 'undefined'
  );
}

export function isMeatspaceVenueDetail(
  data: any
): data is MeatspaceVenueDetail {
  return (
    data &&
    (typeof data.address === 'string' || typeof data.text === 'string') &&
    isMeatspaceVenueSearchItem(data)
  );
}

export function isVenueFinderLanguage(data: any): data is VenueFinderLanguage {
  for (const value in VenueFinderLanguage) {
    if (VenueFinderLanguage.hasOwnProperty(value)) {
      if (data === (VenueFinderLanguage as any)[value]) {
        return true;
      }
    }
  }
  return false;
}

export function isMeatspaceSetLanguageResponse(
  data: any
): data is MeatspaceSetLanguageResponse {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.success === 'boolean' &&
    typeof data.payload === 'object' &&
    typeof data.payload.language === 'string' &&
    typeof data.payload.code === 'string' &&
    typeof data.payload.id === 'number'
  );
}

export function isMeatspaceLocation(data: any): data is MeatspaceLocation {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.id === 'number' &&
    typeof data.name === 'string' &&
    typeof data.title === 'string'
  );
}

export function isMeatspaceTag(data: any): data is MeatspaceTag {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.id === 'number' &&
    typeof data.name === 'string' &&
    typeof data.title === 'string'
  );
}

export function isMeatspaceType(data: any): data is MeatspaceType {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.id === 'number' &&
    typeof data.tag === 'string' &&
    typeof data.title === 'string'
  );
}

export function isMeatspaceListLocationsResponse(
  data: any
): data is MeatspaceListLocationsResponse {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.success === 'boolean' &&
    Array.isArray(data.payload) &&
    data.payload.some(isMeatspaceLocation)
  );
}

export function isMeatspaceListTagsResponse(
  data: any
): data is MeatspaceListTagsResponse {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.success === 'boolean' &&
    Array.isArray(data.payload) &&
    data.payload.some(isMeatspaceTag)
  );
}

export function isMeatspaceListTypesResponse(
  data: any
): data is MeatspaceListTypesResponse {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.success === 'boolean' &&
    Array.isArray(data.payload) &&
    data.payload.some(isMeatspaceType)
  );
}
