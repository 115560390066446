import { Component } from 'vue-property-decorator';
import HeadManagement from '~/mixins/HeadManagement';
import { VueComponentMixin } from '~/utils/vue-component';
import { Preview as PreviewContent } from '~/components/templates/preview';
import createSeoTags, { ROBOTS, TYPE } from '~/utils/views/createSeoTags';

@Component
export default class Preview extends VueComponentMixin(HeadManagement) {
  public title() {
    return 'Preview Page';
  }

  public headTags(): string {
    return createSeoTags(
      'Preview',
      'Testing page',
      '',
      TYPE.ARTICLE,
      '',
      ROBOTS.NOINDEX_NOFOLLOW
    );
  }

  public render() {
    return <PreviewContent />;
  }
}
