export default {
  app: {
    safeTravels: {
      search: 'Name',
      categories: 'Category',
      button: 'Search',
      table: {
        name: 'Name',
        link: 'Website',
      },
      categoryTranslations: {
        eventy: 'Events',
        kongresy_a_veletrhy: 'MICE',
        restaurace: 'Restaurant',
        ubytovací_zařízení: 'Accommodation',
        sauny: 'Sauna',
        přírodní_a_umělá_koupaliště: 'Swimming pool',
        lanová_doprava: 'Cable car',
        maloobchod: 'Shop',
        fitness_centra: 'Fitness',
        kina: 'Cinema',
        golfové_areály: 'Golf',
      },
    },
  },
};
