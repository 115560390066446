import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { CarouselItem } from '~/components/molecules';
import { ImageInterface } from '~/components/atoms/image/Image';
import { Region } from '~/app/core/apiClient/api';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected image: ImageInterface = {
    src: 'http://picsum.photos/1920/1080',
    alt: 'Wide Picture',
  };
  protected title: string = 'Carousel Item';
  protected date: string = 'Jul 6th';
  protected location: Region = { regionName: 'Central Bohemia' };
  protected showDate: boolean = true;
  protected showLocation: boolean = true;

  public getContent() {
    return [
      <v-col cols={4}>
        <CarouselItem
          image={this.image}
          title={this.title}
          date={this.showDate ? this.date : undefined}
          location={this.showLocation ? this.location : undefined}
        />
      </v-col>,
    ];
  }
  public getSettings() {
    return [
      <v-switch label='Show Location' color='accent' v-model={this.showDate} />,
      <v-switch label='Show Date' color='accent' v-model={this.showLocation} />,
    ];
  }
}
