import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class CaptionsOffIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--captions-off'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 17.9'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path d='M17.7 17.9H2.2c-1.2 0-2.2-1-2.2-2.2V2.2c0-.2 0-.3.1-.5l4.2 3.8c-.7.2-1 .6-1 1.2v4.5c0 .7.4 1.1 1.1 1.1h3.4c.7 0 1.1-.4 1.1-1.1v-1.1H7.2v.6H5V7.3h1l11.7 10.6zm2.4-15.7v13.2L16.4 12c.2-.2.4-.5.4-.9V10H15v.6h-.3l-1.9-1.8V7.3H15v.6h1.7V6.7c0-.7-.4-1.1-1.1-1.1h-3.4c-.7 0-1.1.4-1.1 1.1v.6L3.1 0h14.8c1.2 0 2.2 1 2.2 2.2z' />
        </svg>
      </i>
    );
  }
}
