export default {
  app: {
    websAndApps: {
      ceskojede: 'Česko jede',
      czechspecials: 'Czech Specials',
      kzn: 'Kudy z nudy',
      vcr: 'Visit Czech Republic',
    },
  },
};
