import { Action, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import appEnv from '../../appEnv';
import getCookieValue from '~/utils/cookies/getCookieValue';
import {
  TRANSLATIONS_VOTING_COOKIE_NAME,
  TRANSLATIONS_VOTING_DELIMITER,
  TRANSLATIONS_VOTING_EXPIRATION_TIME,
} from '~/utils/translations/cookies';
import setCookieValue from '~/utils/cookies/setCookieValue';
import {
  AutomatedTranslationVoting,
  VotingResponse,
} from '../../apiClient/api';

@Module({
  name: 'TranslationModule',
  stateFactory: true,
  namespaced: true,
})
export default class TranslationModule extends AbstractModule {
  public votedPageIds: string[] = [];

  public loading = false;

  public voting = false;

  protected votingPromise: Promise<VotingResponse> | null = null;

  protected votedPagesPromise: Promise<boolean> | null = null;

  @Action({ rawError: true })
  public loadVotedPages(cookiesString: string): Promise<boolean> {
    if (this.votedPagesPromise) {
      return this.votedPagesPromise;
    }
    this.setLoading(true);
    const promise = new Promise<boolean>((resolve, reject) => {
      const pages = getCookieValue(
        TRANSLATIONS_VOTING_COOKIE_NAME,
        cookiesString
      );
      if (pages) {
        this.setVotedPages(pages.split(TRANSLATIONS_VOTING_DELIMITER));
        this.setLoading(false);
        resolve(true);
      } else {
        this.setLoading(false);
        resolve(false);
      }
    });

    this.setVotedPagesPromise(promise);

    return promise;
  }

  @Action
  public vote(data: AutomatedTranslationVoting) {
    this.setVoting(true);

    const promise = this.$api
      .translations()
      .translationsAutomatedTranslationVoting(data, {
        headers: {
          'X-API-Key': appEnv.NEWSLETTER_API_KEY,
        },
      })
      .then((response) => {
        if (response.success) {
          this.addVotedPage(data.nodeGUID);
          setCookieValue(
            TRANSLATIONS_VOTING_COOKIE_NAME,
            this.votedPageIds.join(TRANSLATIONS_VOTING_DELIMITER),
            TRANSLATIONS_VOTING_EXPIRATION_TIME
          );
        }
        return response;
      })
      .finally(() => {
        this.setVotingPromise(null);
        this.setVoting(false);
      });

    this.setVotingPromise(promise);
    return promise;
  }

  @Mutation
  protected setVotedPages(pageIds: string[]) {
    this.votedPageIds = pageIds;
  }

  @Mutation
  protected addVotedPage(pageId: string) {
    this.votedPageIds.push(pageId);
  }

  @Mutation
  protected setVoting(state: boolean) {
    this.voting = state;
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setVotingPromise(promise: Promise<VotingResponse> | null) {
    this.votingPromise = promise;
  }

  @Mutation
  protected setVotedPagesPromise(promise: Promise<boolean> | null) {
    this.votedPagesPromise = promise;
  }
}
