declare global {
  interface Window {
    dataLayer: unknown[] | undefined;
  }
}

const src = '//www.googletagmanager.com/gtm.js?id=';

export default function(containerId: string): void {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const lastScript = document.getElementsByTagName('script');
  if (lastScript.length < 1) {
    return;
  }

  const gtmScript = document.createElement('script');
  gtmScript.async = true;
  gtmScript.src = `${src}${containerId}`;
  const lastScriptElement = lastScript[lastScript.length - 1];
  if (!lastScriptElement || !lastScriptElement.parentNode) {
    return;
  }
  lastScriptElement.parentNode.insertBefore(gtmScript, lastScriptElement);
}
