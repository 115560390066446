import { Component, Prop, Watch } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './ImageWrapper.scss';

import { getUrlParameterByName } from '~/utils/views/components';
import { Image as CztImage } from '~/components/atoms';
import { ImageInterface } from '~/components/atoms/image/Image';
import { ThemeRatios } from '~/utils/theme';

import Timeout = NodeJS.Timeout;

export interface Sizes {
  minWidth?: number;
  size: number;
  unit: string;
}

interface ImageWrapperInterface {
  image: ImageInterface;
  ratio?: ThemeRatios;
  sizes?: Sizes[];
}

enum Cover {
  WIDTH = '--cover-width',
  HEIGHT = '--cover-height',
}

const rootClass = 'czt-image-wrapper';

@Component({
  style,
})
export default class ImageWrapper extends VueComponent<ImageWrapperInterface>
  implements ImageWrapperInterface {
  @Prop({ default: ThemeRatios['16x9'] })
  public ratio!: ThemeRatios;

  @Prop({ required: true })
  public image!: ImageInterface;

  @Prop()
  public sizes?: Sizes[];

  protected cover: Cover = Cover.WIDTH;

  protected resizeThrottle?: Timeout;

  public get imageSizes(): string {
    if (!this.sizes) {
      return '';
    }

    if (this.ratio === 0) {
      // this.ratio is 0 when wrapper-ratio is unknown -> don't use srcset then
      return '';
    }

    const width = getUrlParameterByName('width', this.image.src);
    const height = getUrlParameterByName('height', this.image.src);
    const imageRatio =
      !isNaN((width as unknown) as number) &&
      !isNaN((height as unknown) as number)
        ? Number(width) / Number(height)
        : 16 / 9;

    const sizes: string[] = [];

    this.sizes.forEach((size) => {
      const adjustedSize =
        size.size * (imageRatio > this.ratio ? imageRatio / this.ratio : 1);

      const sizesMember = `${
        size.minWidth ? `(min-width: ${size.minWidth}px) ` : ''
      }${Math.ceil(adjustedSize)}${size.unit}`;
      sizes.push(sizesMember);
    });

    return sizes.join(', ');
  }

  @Watch('ratio')
  public handleListeners() {
    if (this.ratio === ThemeRatios.auto) {
      window.addEventListener('resize', this.coverHandler);
    } else {
      window.removeEventListener('resize', this.coverHandler);
    }
  }

  public mounted() {
    if (this.ratio === ThemeRatios.auto) {
      window.addEventListener('resize', this.coverHandler);
    }
  }

  public beforeDestroy() {
    if (this.ratio === ThemeRatios.auto) {
      window.removeEventListener('resize', this.coverHandler);
    }
  }

  public render() {
    const classes = [
      rootClass,
      `${rootClass}--ratio-${ThemeRatios[this.ratio]}`,
      `${rootClass}${this.cover}`,
    ];

    return (
      <div class={classes.join(' ')}>
        {(() => {
          if (!this.image || (this.image && !this.image.src)) {
            return;
          }
          return (
            <CztImage
              absolute={true}
              src={this.image.src}
              alt={this.image.alt}
              fallback={this.image.fallback}
              sizes={this.imageSizes}
              {...{ on: { loaded: this.setCover } }}
            />
          );
        })()}
      </div>
    );
  }

  @Watch('image', { deep: true })
  protected coverHandler() {
    if (this.resizeThrottle) {
      clearTimeout(this.resizeThrottle);
    }

    this.resizeThrottle = setTimeout(() => {
      this.setCover();
    }, 50);
  }

  public setCover() {
    if (!this.image || (this.image && !this.image.src)) {
      return;
    }

    let width = Number(getUrlParameterByName('width', this.image.src));
    let height = Number(getUrlParameterByName('height', this.image.src));

    if (!width || !height) {
      const imgRect = this.$el.querySelector('img')!.getBoundingClientRect();
      width = imgRect.width;
      height = imgRect.height;
    }

    this.cover =
      width &&
      height &&
      (this.ratio !== ThemeRatios.auto
        ? this.ratio
        : this.$el.clientWidth / this.$el.clientHeight) <
        width / height
        ? Cover.HEIGHT
        : Cover.WIDTH;
  }
}
