import { Component, Prop } from 'vue-property-decorator';

import { VueComponent } from '~/utils/vue-component';
import { Headline } from '~/components/atoms';
import { ImageWrapper, Link } from '~/components/molecules';
import { Align } from '~/components/atoms/headline/Headline';
import { ThemeRatios } from '~/utils/theme';
import { EventtiaListItem } from '~/components/organisms/eventtiaList/EventtiaList';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';

interface EventGridItemInterface {
  item: EventtiaListItem;
}

const rootClass = 'czt-grid-list__item';

@Component
export default class EventGridItem extends VueComponent<EventGridItemInterface>
  implements EventGridItemInterface {
  @Prop({ required: true })
  public item!: EventtiaListItem;

  public render() {
    if (!this.item.uri) {
      return;
    }

    return (
      <v-col class={`${rootClass} ${rootClass}--simple`} cols={12}>
        <v-row>
          <v-col cols='12' md='4'>
            <Link url={this.item.uri}>
              <ImageWrapper
                ratio={ThemeRatios['4x3']}
                image={this.item.image}
              />
            </Link>
          </v-col>
          <v-col cols='12' md='8'>
            {this.item.date && (
              <div class='grey--text text--darken-2 font-weight-bold mb-1'>
                {this.item.date}
              </div>
            )}
            {this.item.address && !this.item.virtual && (
              <div class='grey--text mb-1'>{this.item.address}</div>
            )}
            <Headline
              level={3}
              align={Align.LEFT}
              class='mb-2 font-weight-bold'
            >
              <Link url={this.item.uri}>{this.item.title}</Link>
            </Headline>
            <div class={`${rootClass}__content font-weight-bold`}>
              <HtmlRenderer content={this.item.description} />
              <Link url={this.item.uri}>{this.$t('app.common.readMore')}</Link>
            </div>
          </v-col>
        </v-row>
      </v-col>
    );
  }
}
