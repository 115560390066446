export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'I agree to {0}',
          link: 'personal data processing.',
          error: 'You must agree to personal data processing.',
        },
      },
      send: 'Send',
      sent: 'The form was successfully sent.',
    },
  },
};
