import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { themeImageSizes } from '~/utils/theme';

import style from './Image.scss';
import { LocaleMessage } from 'vue-i18n';

export interface ImageInterface {
  alt: string | LocaleMessage;
  src: string;
  absolute?: boolean;
  fallback?: string;
  height?: number;
  srcset?: string;
  sizes?: string;
  title?: string;
  width?: number;
  onLoaded?: () => void;
}

const rootClass = 'czt-image';

@Component({
  style,
})
export default class ImageComponent extends VueComponent<ImageInterface>
  implements ImageInterface {
  @Prop({ default: false, type: Boolean })
  public absolute!: boolean;

  @Prop({ required: true })
  public alt!: string | LocaleMessage;

  @Prop({ required: true, type: String })
  public src!: string;

  @Prop({ type: String })
  public fallback?: string;

  @Prop({ type: String })
  public srcset?: string;

  @Prop({ type: String })
  public sizes?: string;

  @Prop({ type: String })
  public title?: string;

  protected error: boolean = false;

  public get imageSrcset(): string {
    if (this.srcset) {
      return this.srcset;
    }
    const srcParts = this.src.split('?');
    const src = srcParts[0];
    const query = srcParts[1] ? srcParts[1].split('&') : null;

    if (!src) {
      return '';
    }

    const params: string[] = [];

    query?.forEach((parameter) => {
      if (
        parameter.indexOf('width=') !== 0 &&
        parameter.indexOf('height=') !== 0
      ) {
        params.push(parameter);
      }
    });

    const srcset: string[] = [];

    themeImageSizes.forEach((size) => {
      let srcsetMember = `${src}?width=${size}${
        params.length > 0 ? '&' + params.join('&') : ''
      }`;
      srcsetMember = `${srcsetMember} ${size}w`;
      srcset.push(srcsetMember);
    });

    return srcset.join(', ');
  }

  protected get imageSrc(): string {
    return this.error && this.fallback ? this.fallback : this.src;
  }

  @Emit('loaded')
  protected imgLoaded() {
    return;
  }

  protected setError() {
    this.error = true;
  }

  public render() {
    return (
      <img
        class={rootClass}
        src={this.imageSrc}
        alt={this.alt}
        title={this.title}
        srcset={this.sizes ? this.imageSrcset : undefined}
        sizes={this.sizes ? this.sizes : undefined}
        style={this.absolute ? 'position:absolute;' : ''}
        {...{
          on: {
            load: this.imgLoaded,
            error: this.setError,
          },
        }}
      />
    );
  }
}
