import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { Icons } from '~/components/atoms';
import { IconName } from '~/components/atoms/icons/Icon';
import { IconText } from '~/components/molecules';
import { IconPosition } from '~/components/molecules/iconText/IconText';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected iconName: IconName = ['common', 'Search'];
  protected iconSize: number = 1;
  protected iconPosition: IconPosition = IconPosition.PREPEND;
  public getContent() {
    return [
      <div style='padding-top: 20px; text-align: center;'>
        <IconText
          icon={this.iconName}
          iconSize={this.iconSize}
          iconPosition={this.iconPosition}
        >
          Text with icon
        </IconText>
      </div>,
    ];
  }
  public getSettings() {
    const iconsList = [];
    for (const category in Icons) {
      if (!Icons.hasOwnProperty(category)) {
        continue;
      }
      const categoryIcons = (Icons as { [key: string]: any })[category];
      for (const iconKey in categoryIcons) {
        if (!categoryIcons.hasOwnProperty(iconKey)) {
          continue;
        }
        iconsList.push({ value: [category, iconKey], text: iconKey });
      }
    }
    return [
      <v-select
        items={iconsList}
        filled
        label='Generic icon type'
        v-model={this.iconName}
      />,
      <v-slider
        label='Icon size'
        max='4'
        min='1'
        step='1'
        v-model={this.iconSize}
        hide-details
      />,
      <v-select
        items={[IconPosition.PREPEND, IconPosition.APPEND]}
        filled
        label='Icon position'
        v-model={this.iconPosition}
      />,
    ];
  }
}
