import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class TwitterIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--social czt-icon--twitter'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 35 35'
        >
          <defs />
          <path fill='#4292dd' d='M-.003 0H35.03v35.032H-.003z' />
          <path
            fill='#fff'
            fill-rule='nonzero'
            d='M28.042 11.115a8.602 8.602 0 01-2.475.678 4.323 4.323 0 001.895-2.383 8.639 8.639 0 01-2.737 1.045 4.31 4.31 0 00-7.342 3.93 12.229 12.229 0 01-8.88-4.502 4.29 4.29 0 00-.583 2.167c0 1.494.76 2.813 1.916 3.586a4.283 4.283 0 01-1.951-.54v.055a4.31 4.31 0 003.455 4.224 4.295 4.295 0 01-1.945.075 4.313 4.313 0 004.025 2.991 8.646 8.646 0 01-6.379 1.785 12.195 12.195 0 006.605 1.935c7.924 0 12.258-6.564 12.258-12.258 0-.187-.004-.373-.012-.557a8.758 8.758 0 002.15-2.23z'
          />
        </svg>
      </i>
    );
  }
}
