import { Component, Prop } from 'vue-property-decorator';

import { Region } from '~/app/core/apiClient/api';
import { ImageInterface } from '~/components/atoms/image/Image';
import { ImageFilterMixin, ImageFilterMixinInterface } from '~/mixins';
import { format, parse } from '~/utils/date-fns';
import { defaultCZTFormat, shortMonthDateFormat } from '~/utils/dateTime';
import { ThemeRatios } from '~/utils/theme';
import { VueComponentMixin } from '~/utils/vue-component';

import style from './CarouselItem.scss';
import { MediaBackground, IconText, Link } from '../';
import { Sizes } from '../imageWrapper/ImageWrapper';

export interface CarouselItemInterface extends ImageFilterMixinInterface {
  endDate?: string;
  image: ImageInterface;
  location?: Region;
  date?: string;
  disabled?: boolean;
  title: string;
  url?: string;
}

/*
 *  min width is taken from defined breakpoints
 *  width is calculated from max container width for the given breakpoint and from the number of columns it takes up in it (12 column grid)
 *  follows the carousel item width set in Carousel.scss
 */
const sizes: Sizes[] = [
  {
    minWidth: 1264,
    size: (1150 / 12) * 4,
    unit: 'px',
  },
  {
    minWidth: 1008,
    size: (1000 / 12) * 4,
    unit: 'px',
  },
  {
    minWidth: 768,
    size: (710 / 12) * 6,
    unit: 'px',
  },
  {
    size: (400 / 12) * 10,
    unit: 'px',
  },
];

const rootClass = 'czt-carousel__item';

@Component({
  style,
})
export default class CarouselItem
  extends VueComponentMixin<CarouselItemInterface, ImageFilterMixinInterface>(
    ImageFilterMixin
  )
  implements CarouselItemInterface {
  @Prop({ type: String })
  public endDate?: string;

  @Prop({ required: true })
  public image!: ImageInterface;

  @Prop()
  public location?: Region;

  @Prop({ type: String })
  public date?: string;

  @Prop({ required: true })
  public title!: string;

  @Prop({ default: '', type: String })
  public url!: string;

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  protected get dateText(): JSX.Element | null {
    const dateArray: JSX.Element[] = [];

    if (this.date && this.endDate) {
      let start = format(parse(this.date), defaultCZTFormat, this.$i18n.locale);
      let end = format(
        parse(this.endDate),
        defaultCZTFormat,
        this.$i18n.locale
      );

      if (start !== end) {
        start = format(
          parse(this.date),
          shortMonthDateFormat,
          this.$i18n.locale
        );
        end = format(
          parse(this.endDate),
          shortMonthDateFormat,
          this.$i18n.locale
        );
        dateArray.push(<time datetime={this.date}>{start}</time>);
        dateArray.push(
          <span> - </span>,
          <time datetime={this.endDate}>{end}</time>
        );
      } else {
        dateArray.push(<time datetime={this.date}>{start}</time>);
      }
    } else if (this.date) {
      dateArray.push(
        <time datetime={this.date}>
          {format(parse(this.date), defaultCZTFormat, this.$i18n.locale)}
        </time>
      );
    }

    return dateArray.length > 0 ? (
      <div class={`${rootClass}__date`}>{dateArray}</div>
    ) : null;
  }

  public render() {
    if (this.url) {
      return (
        <Link url={this.url} disabled={this.disabled}>
          {this.renderCarouselItem()}
        </Link>
      );
    } else {
      return this.renderCarouselItem();
    }
  }

  public renderCarouselItem() {
    return (
      <v-sheet class={rootClass}>
        <MediaBackground
          image={this.image}
          imageFilter={this.imageFilter}
          ratio={ThemeRatios['16x10']}
          sizes={sizes}
        >
          {(() => {
            if ((this.location && this.location.regionName) || this.date) {
              return (
                <v-row
                  class={`${rootClass}__information fill-height ma-0 pa-0 flex-nowrap`}
                  align='end'
                >
                  <v-col class='py-1'>
                    {(() => {
                      if (this.location && this.location.regionName) {
                        return (
                          <div>
                            <IconText icon={['common', 'Location']}>
                              {this.location.regionName}
                            </IconText>
                          </div>
                        );
                      }
                    })()}
                    {this.dateText ? (
                      <div>
                        <IconText icon={['common', 'Calendar']}>
                          {this.dateText}
                        </IconText>
                      </div>
                    ) : null}
                  </v-col>
                </v-row>
              );
            }
          })()}
        </MediaBackground>
        <h4 class={`${rootClass}__title`}>{this.title}</h4>
      </v-sheet>
    );
  }
}
