import VueI18n from 'vue-i18n';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { Colors } from '~/utils/theme/colors';
import { ValueProp, VueComponent } from '~/utils/vue-component';

import style from './Checkbox.scss';

interface CheckboxInterface extends ValueProp<boolean> {
  disabled?: boolean;
  readOnly?: boolean;
  label: string | VueI18n.TranslateResult;
  light?: boolean;
}
@Component({
  style,
})
export default class Checkbox extends VueComponent<CheckboxInterface>
  implements CheckboxInterface {
  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public readOnly!: boolean;

  @Prop({ default: '' })
  public label!: string | VueI18n.TranslateResult;

  @Prop({ default: false, type: Boolean })
  public value!: boolean;

  @Prop({ default: false, type: Boolean })
  public light!: boolean;

  public render() {
    return (
      <v-checkbox
        class='czt-checkbox'
        disabled={this.disabled}
        readonly={this.readOnly}
        input-value={this.value}
        onChange={this.change}
        true-value={true}
        false-value={false}
        light={this.light}
      >
        {this.label && (
          <span
            slot='label'
            style={{
              color: this.light ? Colors.TEXT_LIGHT : undefined,
            }}
          >
            {this.label}
          </span>
        )}
      </v-checkbox>
    );
  }

  @Emit('input')
  protected change(value: boolean) {
    return value;
  }
}
