import { Component, Prop } from 'vue-property-decorator';
import { Link } from '~/components/molecules';
import { NavigationMenu } from '~/components/organisms';
import { isNavigationGroup, NavigationElement } from '~/utils/navigation';
import { VueComponent } from '~/utils/vue-component';
import style from './NavigationFooterColumn.scss';
import { Image } from '~/components/atoms';

interface NavigationFooterColumnInterface {
  menu: NavigationElement;
}

const rootClass = 'czt-navigation-footer-column';

@Component({ style })
export default class NavigationFooterColumn
  extends VueComponent<NavigationFooterColumnInterface>
  implements NavigationFooterColumnInterface {
  @Prop({ required: true })
  public readonly menu!: NavigationElement;

  public render() {
    if (!isNavigationGroup(this.menu)) {
      return;
    }

    return (
      <v-col class={rootClass} cols={12} xs={12} sm={6} md={6} lg={3}>
        {this.menu.items.map((item, index) =>
          isNavigationGroup(item) ? (
            <NavigationMenu
              inline={item.inline}
              items={item.items}
              title={item.title}
              key={index}
            />
          ) : (
            <Link url={item.url} target={item.target} key={index}>
              {item.image ? (
                <Image src={item.image.src} alt={item.image.alt} />
              ) : (
                item.title
              )}
            </Link>
          )
        )}
      </v-col>
    );
  }
}
