import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class UnmuteIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--unmute'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path d='M2.8 6.9H0v6.2h2.8L8.1 17s1 .8 1 0V2.9c0-.6-.8 0-.8 0l-5.5 4zm9.3-1.2c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1 .9.9 1.3 2.1 1.3 3.3s-.4 2.4-1.3 3.3c-.3.3-.3.7 0 1 .1.1.3.2.5.2s.4-.1.5-.2c1.2-1.2 1.8-2.8 1.8-4.3-.1-1.6-.6-3.2-1.8-4.3z' />
          <path d='M14 3.4c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1C14.5 6 15.3 8 15.3 10s-.8 4-2.3 5.6c-.3.3-.3.7 0 1 .1.1.3.2.5.2s.4-.1.5-.2a9.3 9.3 0 002.7-6.6c0-2.4-.9-4.8-2.7-6.6z' />
          <path d='M16.3 1.1c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1a11 11 0 010 15.7c-.3.3-.3.7 0 1 .1.1.3.2.5.2s.4-.1.5-.2c2.4-2.4 3.7-5.6 3.7-8.8 0-3.2-1.2-6.4-3.7-8.9z' />
        </svg>
      </i>
    );
  }
}
