import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { List } from '~/components/atoms';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected listItems: string[] = [
    'First list item',
    'Second list item',
    'Third list item',
    'Fourth list item',
  ];
  public getContent() {
    return [
      <div style='padding-top: 20px;'>
        <List items={this.listItems} />
      </div>,
    ];
  }
  public getSettings() {
    return [];
  }
}
