import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { MediaBackground } from '~/components/molecules';
import { ThemeRatios, ThemeColors } from '~/utils/theme';
import { ImageInterface } from '~/components/atoms/image/Image';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';

const sizes: Sizes[] = [
  {
    size: 100,
    unit: 'vw',
  },
];

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected image: ImageInterface = {
    src: 'http://picsum.photos/1920/1080',
    alt: 'Wide Picture',
  };

  protected ratio: ThemeRatios = ThemeRatios['16x9'];

  protected overlayColor: ThemeColors = ThemeColors.PRIMARY;

  public getContent() {
    return (
      <MediaBackground
        image={this.image}
        ratio={this.ratio}
        overlayColor={this.overlayColor}
        sizes={sizes}
      >
        <v-row class='pa-0 ma-0'>
          <v-col>
            <v-skeleton-loader type='heading, paragraph' boilerplate />
          </v-col>
        </v-row>
      </MediaBackground>
    );
  }

  public getSettings() {
    return [
      <v-select items={this.getRatios()} v-model={this.ratio} />,
      <v-select items={this.getColors()} v-model={this.overlayColor} />,
    ];
  }

  protected getRatios() {
    const ratios = [];
    for (const ratio in ThemeRatios) {
      if (isNaN((ratio as unknown) as number)) {
        ratios.push({ text: ratio, value: ThemeRatios[ratio] });
      }
    }
    return ratios;
  }

  protected getColors() {
    const colors = [{ text: 'None', value: '' }];
    for (const color in ThemeColors) {
      if (color.indexOf('#') < 0) {
        colors.push({
          text: color,
          value: ThemeColors[color as keyof typeof ThemeColors],
        });
      }
    }
    return colors;
  }
}
