import { getVideoId } from '~/utils/views/components';
import {
  ContentWidgetData,
  ContentWidgets,
  isContentWidgetData,
} from './types';

export interface VideoContentWidgetData extends ContentWidgetData {
  url?: string;
  videourl?: string;
  autoplay?: string;
  mute?: string;
  showControls?: string;
  loop?: string;
}

export function isContentVideoData(data: any): data is VideoContentWidgetData {
  return (
    data &&
    typeof data === 'object' &&
    (typeof data.url === 'string' || typeof data.videourl === 'string')
  );
}

export function isVideoContentWidgetData(
  data: any
): data is VideoContentWidgetData {
  return isContentWidgetData(data) && data.name === ContentWidgets.VIDEO;
}

export function getVideoComponentString(
  videoData: VideoContentWidgetData
): string {
  if (!videoData.videourl && videoData.url) {
    videoData.videourl = videoData.url;
  }
  const videoId =
    videoData && videoData.videourl
      ? getVideoId(unescape(videoData.videourl))
      : null;

  return videoId
    ? `<video-component video-id='${videoId}' ${
        videoData.mute && videoData.mute === 'True'
          ? 'mute'
          : videoData.autoplay === 'True'
          ? 'mute'
          : ''
      } ${
        videoData.autoplay && videoData.autoplay === 'True' ? 'autoPlay' : ''
      } ${videoData.showControls ? 'showControls' : ''} ${
        videoData.loop && videoData.loop === 'True' ? 'loop' : ''
      }></video-component>`
    : '';
}
