declare global {
  interface Window {
    youtubeApiLoader?: Promise<void>;
    onYouTubeIframeAPIReady: () => void;
  }
}

export default function(): Promise<void> {
  if (!document || !window) {
    return Promise.reject(new Error('Document or Window is missing.'));
  }

  let loaded: boolean = false;

  if (window.youtubeApiLoader) {
    return window.youtubeApiLoader;
  }

  window.youtubeApiLoader = new Promise((resolve, reject) => {
    if (typeof window.YT !== 'undefined') {
      loaded = true;
      return resolve();
    }

    window.onYouTubeIframeAPIReady = () => {
      loaded = true;
      return resolve();
    };

    const head = document.querySelector('head');
    if (!head) {
      window.youtubeApiLoader = undefined;
      return reject(new Error('Head element was not found.'));
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.youtube.com/iframe_api';
    script.onerror = (e) => reject(e);

    head.appendChild(script);
    setTimeout(() => {
      if (!loaded) {
        window.youtubeApiLoader = undefined;
        reject(new Error('Youtube api failed to load in the given time'));
      }
    }, 15 * 1000);
  });

  return window.youtubeApiLoader;
}
