import CarouselItem from './CarouselItem';
import GalleryItem from './GalleryItem';
import IconText from './IconText';
import MediaBackground from './MediaBackground';
import ImageTile from './ImageTile';
import ImageWrapper from './ImageWrapper';
import Link from './Link';
import SearchItem from './SearchItem';
import Tabs from './Tabs';
import VideoWrapper from './VideoWrapper';

export const molecules = {
  CarouselItem,
  GalleryItem,
  IconText,
  MediaBackground,
  ImageTile,
  ImageWrapper,
  Link,
  SearchItem,
  Tabs,
  VideoWrapper,
};
