import { Component, Prop } from 'vue-property-decorator';
import appEnv from '~/app/core/appEnv';
import { getLocaleFromRouter } from '~/app/core/router';
import HeadManagement from '~/mixins/HeadManagement';
import { VueComponentMixin } from '~/utils/vue-component';
import { Teaser } from '~/components/organisms';
import { Headline } from '~/components/atoms';
import WidgetRenderer from '~/components/templates/base/WidgetRenderer';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import TopLayerImage from '~/components/templates/common/TopLayerImage';
import imageHeroDefault from '~/assets/images/hero_default.jpg';
import { RegionPage } from '~/utils/views';
import { sidebarColumns } from '~/utils/sidebarColumns';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';
import TranslationVoting from '~/components/templates/common/TranslationVoting';

interface RegionView {
  data: RegionPage;
}

@Component
export default class Region
  extends VueComponentMixin<RegionView, {}>(HeadManagement)
  implements RegionView {
  @Prop({ required: true })
  public data!: RegionPage;

  public headTags(): string {
    return createSeoTags(
      `${this.data.title} · #CzechTourism`,
      this.data.perex,
      `${appEnv.PUBLIC_WEB_URL}/${getLocaleFromRouter(this.$router)}${
        this.data.canonicalPath
      }`,
      TYPE.ARTICLE,
      this.data.image
    );
  }
  public render() {
    const showSideColumn =
      this.data.showRightColumn &&
      Array.isArray(this.data.widgets.right) &&
      this.data.widgets.right.length > 0;
    return (
      <v-container fluid class='pa-0' key={this.data.guid}>
        <Teaser
          backgroundImage={
            this.data.hideTeaserImage
              ? null
              : {
                  src: this.data.image ? this.data.image : imageHeroDefault,
                  alt: this.data.title + '',
                }
          }
          imageFilter={this.data.imageFilter}
          title={`${this.data.title}`}
        >
          {this.renderTeaserContent()}
        </Teaser>

        {(() => {
          if (this.data.content || this.data.perex) {
            return (
              <v-container>
                <v-row justify='center'>
                  <v-col
                    cols={12}
                    md={showSideColumn ? 7 : 12}
                    lg={8}
                    class='py-0'
                  >
                    {(() => {
                      const content: JSX.Element[] = [];
                      if (this.data.perex) {
                        content.push(
                          <div
                            class={`czt-perex ${
                              this.data.hideTeaserImage
                                ? 'czt-perex--no-top-space'
                                : ''
                            }`}
                          >
                            <HtmlRenderer content={this.data.perex} />
                          </div>
                        );
                      }
                      if (this.data.content) {
                        content.push(
                          <div class='czt-richtext'>
                            <HtmlRenderer content={this.data.content} />
                          </div>
                        );
                      }
                      if (this.data.showAutomatedTranslationVoting) {
                        content.push(
                          <TranslationVoting
                            locale={this.$i18n.locale}
                            message={this.data.automatedTranslationMessage}
                            pageId={this.data.guid}
                          />
                        );
                      }
                      return content;
                    })()}
                  </v-col>
                  {showSideColumn && (
                    <v-col {...{ attrs: sidebarColumns }} class='py-0'>
                      {this.renderSideContent()}
                    </v-col>
                  )}
                </v-row>
              </v-container>
            );
          } else if (showSideColumn) {
            return (
              <v-container>
                <v-row>
                  <v-col cols={12} class='py-0'>
                    {this.renderSideContent()}
                  </v-col>
                </v-row>
              </v-container>
            );
          }
        })()}
        {this.data.widgets.before.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.before} />
        )}
        {this.data.widgets.main.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.main} />
        )}
        {this.data.widgets.after.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.after} />
        )}
      </v-container>
    );
  }

  protected renderTeaserContent() {
    return (
      <div>
        <Headline level={3} light={!this.data.hideTeaserImage}>
          {this.data.subtitle}
        </Headline>
        <TopLayerImage pageData={this.data} />
      </div>
    );
  }

  protected renderSideContent() {
    return (
      <div class='czt-side-content'>
        <WidgetRenderer widgets={this.data.widgets.right} side />
      </div>
    );
  }
}
