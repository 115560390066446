import { Component, Watch } from 'vue-property-decorator';
import Page from './Page';
import favicons from '~/assets/favicons';
import { getModule } from 'vuex-module-decorators';
import RouterModule from '~/app/core/store/modules/RouterModule';
import { NotFound, PageLoader } from '~/components/templates/common';
import { VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import HeadManagement from '~/mixins/HeadManagement';
import Region from '~/views/Region';
import Event from '~/views/Event';
import Place from '~/views/Place';
import { OneOfThePages } from '~/utils/views';
import {
  isEventPage,
  isEventtiaEventPage,
  isPlacePage,
  isRegionPage,
} from '~/utils/views/typeguards';
import { getPathWithoutLocale } from '~/app/core/router';
import EventtiaDetail from './EventtiaDetail';

@Component
export default class DynamicResource extends VueComponentMixin<
  {},
  PrefetchComponent
>(Prefetch, HeadManagement) {
  protected component: JSX.Element | null = null;

  protected get routerModule(): RouterModule {
    return getModule(RouterModule, this.$store);
  }

  protected get loading(): boolean {
    return this.routerModule.loading;
  }

  protected get resource(): OneOfThePages | null {
    return this.routerModule.resource;
  }

  public title() {
    return this.resource?.title;
  }

  public favicons() {
    return favicons;
  }

  public prefetch() {
    const path = this.$route.meta.friendlyUrl
      ? getPathWithoutLocale(this.$router)
      : this.$route.params.guid;

    return this.routerModule.loadResourceByPath({
      guid: path,
      locale: this.$i18n.locale,
      query: this.$route.query || undefined,
    });
  }

  public render() {
    if (!this.component) {
      return;
    }

    return this.component;
  }

  @Watch('loading', { immediate: true })
  protected setComponent() {
    if (this.routerModule.loading) {
      this.component = <PageLoader />;
      return;
    }

    if (this.resource === null || !this.routerModule.found) {
      this.component = <NotFound />;
      return;
    }
    if (isRegionPage(this.resource)) {
      this.component = <Region data={this.resource} key={this.resource.guid} />;
    } else if (isEventPage(this.resource)) {
      this.component = <Event data={this.resource} key={this.resource.guid} />;
    } else if (isPlacePage(this.resource)) {
      this.component = <Place data={this.resource} key={this.resource.guid} />;
    } else if (isEventtiaEventPage(this.resource)) {
      this.component = (
        <EventtiaDetail data={this.resource} key={this.resource.guid} />
      );
    } else {
      this.component = <Page data={this.resource} key={this.resource.guid} />;
    }
  }
}
