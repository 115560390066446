import { Component, Prop, Emit } from 'vue-property-decorator';
import { ValueProp, VueComponent } from '~/utils/vue-component';
import Icon, { IconName } from '~/components/atoms/icons/Icon';
import { Colors } from '~/utils/theme/colors';

import style from './Select.scss';
import { LocaleMessage } from 'vue-i18n';

export interface ItemData {
  value: string | number;
  text: string;
}

interface SelectInterface extends ValueProp<string | number> {
  placeholder?: string | LocaleMessage;
  label?: string | LocaleMessage;
  disabled?: boolean;
  readonly?: boolean;
  items?: ItemData[];
  iconName?: IconName;
  iconSize?: number;
  iconColor?: Colors;
  loading?: boolean;
}

@Component({
  style,
})
export default class Select extends VueComponent<SelectInterface>
  implements SelectInterface {
  @Prop({ default: '', type: String })
  public placeholder!: string | LocaleMessage;

  @Prop({ default: '', type: String })
  public label!: string | LocaleMessage;

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public readonly!: boolean;

  @Prop({ default: () => [], type: Array })
  public items!: ItemData[];

  @Prop()
  public iconName?: IconName;

  @Prop({ default: 1, type: Number })
  public iconSize!: number;

  @Prop({ default: Colors.PRIMARY, type: String })
  public iconColor!: Colors;

  @Prop({ required: true })
  public value!: string | number;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  public render() {
    const icon = this.iconName ? (
      <Icon
        name={this.iconName}
        size={this.iconSize}
        style={`color: ${this.iconColor} !important`}
        slot={'prepend-inner'}
      />
    ) : null;
    return (
      <v-autocomplete
        class='czt-select'
        placeholder={this.placeholder}
        label={this.label}
        disabled={this.disabled}
        readonly={this.readonly}
        items={this.items}
        value={this.value}
        onChange={this.change}
        solo
        hide-no-data
        clearable={!this.readonly}
        loading={this.loading}
      >
        {icon}
      </v-autocomplete>
    );
  }

  @Emit('input')
  protected change(value: string | number) {
    return value;
  }
}
