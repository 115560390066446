import { Component, Prop } from 'vue-property-decorator';
import appEnv from '~/app/core/appEnv';
import HeadManagement from '~/mixins/HeadManagement';
import { VueComponentMixin } from '~/utils/vue-component';
import { Teaser } from '~/components/organisms';
import { Headline } from '~/components/atoms';
import WidgetRenderer from '~/components/templates/base/WidgetRenderer';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { isPlacePage } from '~/utils/views/typeguards';
import { Link, IconText } from '~/components/molecules';
import { Align } from '~/components/atoms/headline/Headline';
import TopLayerImage from '~/components/templates/common/TopLayerImage';
import { LinkTarget } from '~/utils/molecules';
import imageHeroDefault from '~/assets/images/hero_default.jpg';
import { PlacePage } from '~/utils/views';
import { isSlider } from '~/components/organisms/slider/Slider';
import { isGallery } from '~/components/organisms/gallery/Gallery';
import { createGalleryFromSlider, Widget } from '~/utils/views/widgets';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';
import { getLocaleFromRouter } from '~/app/core/router';
import { sidebarColumns } from '~/utils/sidebarColumns';
import TranslationVoting from '~/components/templates/common/TranslationVoting';

interface PlaceView {
  data: PlacePage;
}

@Component
export default class Place
  extends VueComponentMixin<PlaceView, {}>(HeadManagement)
  implements PlaceView {
  @Prop({ required: true })
  public data!: PlacePage;

  public headTags(): string {
    return createSeoTags(
      `${this.data.title} · #CzechTourism`,
      this.data.perex,
      `${appEnv.PUBLIC_WEB_URL}/${getLocaleFromRouter(this.$router)}${
        this.data.canonicalPath
      }`,
      TYPE.ARTICLE,
      this.data.image
    );
  }

  public render() {
    // Destructure the array so we get a new clone, because we cannot modify the prop
    // TODO: JSON serialization Quickfix... referenced get screwed up when we touch the items inside
    const widgets: Widget[] = JSON.parse(
      JSON.stringify(this.data.widgets.main)
    );

    // Get the first widget from the array so we can print the slider as top content
    let galleryIndex: number | null = null;
    let firstSlider =
      widgets.length > 0
        ? widgets.find((widget, index) => {
            if (isSlider(widget)) {
              galleryIndex = index;
              return true;
            }
            return false;
          })
        : null;

    let image: string | undefined = this.data.image;

    if (firstSlider) {
      if (isSlider(firstSlider) && firstSlider.items.length > 0) {
        firstSlider = createGalleryFromSlider(firstSlider);
      }

      if (isGallery(firstSlider) && firstSlider.items.length > 0) {
        // Remove the first item
        const firstGalleryItem = firstSlider.items.shift();
        if (firstGalleryItem) {
          // Use the first item image
          image = firstGalleryItem.image.src;
        }
      }
      widgets[0] = firstSlider;
    }

    return (
      <v-container fluid class='pa-0' key={this.data.guid}>
        <Teaser
          backgroundImage={
            this.data.hideTeaserImage
              ? null
              : {
                  src: image ? image : imageHeroDefault,
                  alt: `${this.data.title}`,
                }
          }
          imageFilter={this.data.imageFilter}
          title={`${this.data.title}`}
        >
          {this.renderTeaserContent()}
        </Teaser>

        {this.renderContent()}
        {this.data.widgets.before.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.before} />
        )}
        {widgets.length > 0 && <WidgetRenderer widgets={widgets} />}
        {this.data.widgets.after.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.after} />
        )}
      </v-container>
    );
  }

  protected renderTeaserContent() {
    return (
      <div>
        {this.data.subtitle && (
          <Headline level={3} light={!this.data.hideTeaserImage}>
            <HtmlRenderer content={this.data.subtitle} />
          </Headline>
        )}
        <TopLayerImage pageData={this.data} />
      </div>
    );
  }

  protected renderContent() {
    if (this.data && (this.data.content || this.data.perex)) {
      const showSideColumn =
        isPlacePage(this.data) &&
        (this.data.address || this.data.webUrl || this.data.web2Url);
      return (
        <v-container>
          <v-row justify='center'>
            <v-col cols={12} md={showSideColumn ? 7 : 12} lg={8} class='py-0'>
              {(() => {
                const content: JSX.Element[] = [];
                if (this.data.perex) {
                  content.push(
                    <div
                      class={`czt-perex ${
                        this.data.hideTeaserImage
                          ? 'czt-perex--no-top-space'
                          : ''
                      }`}
                    >
                      <HtmlRenderer content={this.data.perex} />
                    </div>
                  );
                }
                if (this.data.content) {
                  content.push(
                    <div class='czt-richtext'>
                      <HtmlRenderer content={this.data.content} />
                    </div>
                  );
                }
                if (this.data.showAutomatedTranslationVoting) {
                  content.push(
                    <TranslationVoting
                      locale={this.$i18n.locale}
                      message={this.data.automatedTranslationMessage}
                      pageId={this.data.guid}
                    />
                  );
                }
                return content;
              })()}
            </v-col>
            {showSideColumn && this.renderSideContent()}
          </v-row>
        </v-container>
      );
    }
  }

  protected renderSideContent() {
    return (
      <v-col {...{ attrs: sidebarColumns }}>
        <div class='czt-side-content'>
          {(() => {
            if (this.data.address) {
              return [
                <Headline underscore level={4} align={Align.LEFT}>
                  {this.$t('app.place.address')}
                </Headline>,
                <HtmlRenderer content={this.data.address} class='pb-0' side />,
                <br />,
              ];
            }
          })()}
          {(() => {
            if (this.data.email) {
              return (
                <div>
                  <Link url={`mailto:${this.data.email}`}>
                    {this.data.email}
                  </Link>
                </div>
              );
            }
          })()}
          {(() => {
            if (this.data.phone) {
              return (
                <div>
                  <Link url={`tel:${this.data.phone}`}>{this.data.phone}</Link>
                </div>
              );
            }
          })()}
          {(() => {
            if (this.data.webUrl || this.data.web2Url) {
              const links: JSX.Element[] = [];
              if (this.data.webUrl) {
                links.push(
                  <div>
                    <Link url={this.data.webUrl}>{this.data.webUrl}</Link>
                  </div>
                );
              }
              if (this.data.web2Url) {
                links.push(
                  <div>
                    <Link url={this.data.web2Url}>{this.data.web2Url}</Link>
                  </div>
                );
              }
              return links;
            }
          })()}
          {(() => {
            if (this.data.latitude && this.data.longitude) {
              return [
                <div class='pt-2'>
                  <Link
                    url={`https://www.google.com/maps/search/?api=1&query=${this.data.latitude},${this.data.longitude}`}
                    target={LinkTarget.BLANK}
                  >
                    <IconText icon={['common', 'MapPin']}>
                      {this.$t('app.place.showOnExternalMaps')}
                    </IconText>
                  </Link>
                </div>,
                <br />,
              ];
            }
          })()}
          {this.data.showRightColumn &&
            Array.isArray(this.data.widgets.right) &&
            this.data.widgets.right.length > 0 && (
              <WidgetRenderer widgets={this.data.widgets.right} side />
            )}
        </div>
      </v-col>
    );
  }
}
