import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import NavigationModule from '~/app/core/store/modules/NavigationModule';
import { Footer, Header } from '~/components/templates/common';
import CookieBar from '~/components/templates/common/CookieBar';
import Redirect307 from '~/utils/http/Redirect307';
import { getCurrentLocale } from '~/utils/localization';

@Component
export default class App extends Vue {
  protected get temporaryRedirect(): string | null {
    if (
      this.$router.currentRoute.redirectedFrom &&
      typeof this.$router.currentRoute.query.temporaryRedirect === 'string'
    ) {
      return this.$router.currentRoute.query.temporaryRedirect;
    }

    return null;
  }

  protected get navigationModule(): NavigationModule {
    return getModule(NavigationModule, this.$store);
  }

  public mounted() {
    if (typeof document !== 'undefined') {
      document.addEventListener('copy', this.copyToClipboard);
    }
  }

  public render() {
    if (this.temporaryRedirect) {
      return <Redirect307 location={this.temporaryRedirect} />;
    }

    const currentLocale = getCurrentLocale(this.$i18n.locale);

    return (
      <v-app
        id='app'
        class={currentLocale ? `lang--${currentLocale.slug.toLowerCase()}` : ''}
      >
        <Header />
        <v-main>
          <router-view />
        </v-main>
        <Footer />
        <CookieBar />
      </v-app>
    );
  }

  public beforeDestroy() {
    if (typeof document !== 'undefined') {
      document.removeEventListener('copy', this.copyToClipboard);
    }
  }

  /**
   * Reload navigation when locale changes
   */
  @Watch('$i18n.locale')
  protected onLocaleUpdate() {
    this.navigationModule.load({
      force: true,
      locale: this.$i18n.locale,
      query: this.$route.query,
    });
  }

  protected copyToClipboard(event: ClipboardEvent) {
    if (typeof document !== 'undefined') {
      const selection = document.getSelection()?.toString();
      if (selection && selection.split(' ').length > 10) {
        event.clipboardData?.setData(
          'text/plain',
          `${selection}\n${this.$t('app.common.source')}: ${document.location}`
        );
        event.preventDefault();
      }
    }
  }
}
