import { PageTag as ApiPageTag } from '~/app/core/apiClient/api';

export interface PageTag {
  name: string;
  id: string;
}

export function isPageTag(data: any): data is PageTag {
  return (
    !!data &&
    typeof data === 'object' &&
    data.hasOwnProperty('name') &&
    data.hasOwnProperty('id')
  );
}

export function createPageTag(data: ApiPageTag): PageTag | null {
  if (!data.pageTagGuid || !data.pageTagName) {
    return null;
  }
  return {
    name: data.pageTagName,
    id: data.pageTagGuid,
  };
}
