import { Component, Prop } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import HeadManagement from '~/mixins/HeadManagement';
import { Banner, Gallery, GridList } from '~/components/organisms';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { getModule } from 'vuex-module-decorators';
import { IconText, Link } from '~/components/molecules';
import { Button, Headline, List } from '~/components/atoms';
import { ExtendedVenueDetail } from '~/utils/venueFinder/types';
import LanguageModule from '~/app/core/store/modules/LanguageModule';
import { BasePage, GridWidget } from '~/app/core/apiClient/api';
import imageHeroDefault from '~/assets/images/hero_default.jpg';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { sidebarColumns } from '~/utils/sidebarColumns';
import { Align } from '~/components/atoms/headline/Headline';
import { LinkTarget } from '~/utils/molecules';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';
import { CztWidgets } from '~/utils/views/widgets';
import { GalleryItemInterface } from '~/components/molecules/galleryItem/GalleryItem';
import { createImage } from '~/utils/views/components';
import VenueReservationForm from './../venueFinder/VenueReservationForm';
import { GridItem } from '~/components/organisms/gridList/GridList';
import Showdown from 'showdown';

import { GalleryWidget } from '~/app/core/apiClient/api';

import WidgetStyleEnum = GalleryWidget.WidgetStyleEnum;

import style from './VenueDetail.scss';

interface VenueDetailInterface {
  venue: ExtendedVenueDetail;
}

const rootClass = 'czt-venue-detail';

@Component({
  style,
})
export default class VenueDetail
  extends VueComponentMixin<VenueDetailInterface, PrefetchComponent>(
    HeadManagement,
    Prefetch
  )
  implements VenueDetailInterface {
  @Prop({ required: true })
  public venue!: ExtendedVenueDetail;

  protected showdown = new Showdown.Converter({
    ellipsis: true,
    omitExtraWLInCodeBlocks: true,
    simpleLineBreaks: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tables: true,
  });

  protected get languageModule(): LanguageModule {
    return getModule(LanguageModule, this.$store);
  }

  public headTags(): string {
    return createSeoTags(
      `${this.venue.title} · #VisitCzechRepublic`,
      this.venue.perex ? this.venue.perex : '',
      this.venue.url,
      TYPE.ARTICLE,
      this.venue.images[0]
    );
  }

  public title(): string {
    return this.venue.title;
  }

  protected get bannerImage(): string {
    return this.venue.images[0]
      ? `https://meatapi.com/mapi/v4/system/image?image=${this.venue.images[0]}`
      : imageHeroDefault;
  }

  protected get gallerySlides(): GalleryItemInterface[] {
    const items = [...this.venue.images];
    items.shift();
    return items.map((image) => {
      return {
        hideTitle: true,
        image: createImage(
          `https://meatapi.com/mapi/v4/system/image?image=${image}`,
          this.venue.title
        ),
        title: this.venue.title,
      };
    });
  }

  protected get subvenues(): GridItem[] {
    if (this.venue.subvenues.length < 1) {
      return [];
    }
    return this.venue.subvenues.map((subvenue) => {
      return {
        description: subvenue.perex,
        perex: subvenue.perex,
        image: createImage(
          subvenue.images.length > 0
            ? `https://meatapi.com/mapi/v4/system/image?image=${subvenue.images[0]}`
            : imageHeroDefault,
          subvenue.title
        ),
        link: `~/venue/${subvenue.id}`,
        linkText: this.$t('app.venues.detailButton').toString(),
        title: subvenue.title,
      };
    });
  }

  public render() {
    const classes = [rootClass, 'pa-0'];
    const title = this.venue.title;

    return (
      <v-container fluid class={classes.join(' ')} key={this.venue.id}>
        <Banner
          backgroundImage={{
            src: this.bannerImage,
            alt: `${title}`,
          }}
          imageFilter={BasePage.ImageFilterTypeEnum.Softdarken}
          title={`${title}`}
          titleLevel={1}
        ></Banner>
        {this.renderContent()}
        {this.subvenues.length > 0 && (
          <GridList
            className={CztWidgets.GRID}
            guid={this.venue.id.toString()}
            items={this.subvenues}
            title={this.$t('app.venues.detail.subvenues')}
            showFlags={false}
            type={GridWidget.WidgetStyleEnum.Classic}
          />
        )}
        <Gallery
          className={CztWidgets.GALLERY}
          items={this.gallerySlides}
          eager
          galleryStyle='slider'
        />
      </v-container>
    );
  }

  protected renderContent(): JSX.Element | undefined {
    if (!this.venue) {
      return;
    }

    const perex = this.venue.perex
      ? this.showdown.makeHtml(this.venue.perex.replace(/<br\s*[\/]?>/g, '\n'))
      : '';
    const text = this.venue.text
      ? this.showdown.makeHtml(this.venue.text.replace(/<br\s*[\/]?>/g, '\n'))
      : '';

    return (
      <v-container class='czt-spacer'>
        <v-row justify='center'>
          <v-col cols={12} md={7} lg={8}>
            {(() => {
              if (perex) {
                return (
                  <div class='czt-perex'>
                    <HtmlRenderer content={perex} whitespaces />
                  </div>
                );
              }
            })()}
            {(() => {
              if (text) {
                return <HtmlRenderer content={text} whitespaces />;
              }
            })()}
          </v-col>
          {this.renderSideContent()}
        </v-row>
      </v-container>
    );
  }

  protected renderSideContent(): JSX.Element | undefined {
    if (!this.venue) {
      return;
    }
    return (
      <v-col {...{ attrs: sidebarColumns }}>
        <div class='czt-side-content'>
          {(() => {
            if (this.venue.equipment.length) {
              return [
                <Headline underscore level={3} align={Align.LEFT}>
                  {this.$t('app.venues.detail.equipment')}
                </Headline>,
                <List items={this.venue.equipment} />,
                <br />,
              ];
            }
          })()}
          <Headline underscore level={3} align={Align.LEFT}>
            {this.$t('app.venues.detail.info')}
          </Headline>
          {(() => {
            if (this.venue.address) {
              return [
                <HtmlRenderer content={this.venue.address} side />,
                <br />,
              ];
            }
          })()}
          {(() => {
            if (this.venue.gps && this.venue.gps.lat && this.venue.gps.long) {
              return [
                <div class='text-center'>
                  <Link
                    url={`https://www.google.com/maps/search/?api=1&query=${this.venue.gps.lat},${this.venue.gps.long}`}
                    target={LinkTarget.BLANK}
                  >
                    <IconText icon={['common', 'MapPin']}>
                      {this.$t('app.place.showOnExternalMaps')}
                    </IconText>
                  </Link>
                </div>,
                <br />,
              ];
            }
          })()}
          {(() => {
            if (this.venue.url && this.venue.url.trim() !== '') {
              return [
                <div class='text-center'>
                  <Button url={this.venue.url} target={LinkTarget.BLANK}>
                    {this.$t('app.venues.detail.link')}
                  </Button>
                </div>,
                <br />,
              ];
            }
          })()}
          <div>
            <VenueReservationForm />
          </div>
        </div>
      </v-col>
    );
  }
}
