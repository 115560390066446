import { Action, Module, Mutation } from 'vuex-module-decorators';
import appEnv from '../../appEnv';

import AbstractModule from './AbstractModule';

export interface ReCaptchaRenderParameters {
  sitekey: string;
  badge?: 'bottomright' | 'bottomleft' | 'inline';
  size?: 'invisible';
  tabindex?: number;
  callback?: (token: string) => void;
  'expired-callback'?: () => {};
  'error-callback'?: () => {};
  isolated?: boolean;
}

export interface ReCaptcha {
  execute(widgetId?: string | number): void;
  ready(cb: (params: any) => unknown): void;
  render(
    container: Element | string,
    parameters: ReCaptchaRenderParameters
  ): string | number;
  reset(widgetId?: string | number): void;
  getResponse(widgetId?: string | number): string | null;
}

declare global {
  var grecaptcha: ReCaptcha | undefined;
}

@Module({
  name: 'RecaptchaModule',
  stateFactory: true,
  namespaced: true,
})
export default class RecaptchaModule extends AbstractModule {
  public recaptchaLoaded: boolean = false;

  public recaptchaPromise: Promise<boolean> | null = null;

  @Action({ rawError: true })
  public loadRecaptcha(): Promise<boolean> {
    if (this.recaptchaPromise) {
      return this.recaptchaPromise;
    }
    const promise = new Promise<boolean>((resolve, reject) => {
      if (this.recaptchaLoaded) {
        resolve(true);
        return;
      }
      if (typeof document === 'undefined') {
        reject();
        return;
      }
      const lastScript = document.getElementsByTagName('script');
      if (lastScript.length < 1) {
        reject();
        return;
      }
      const lastScriptElement = lastScript[lastScript.length - 1];
      if (!lastScriptElement || !lastScriptElement.parentNode) {
        reject();
        return;
      }

      const recaptchaScript = document.createElement('script');
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      recaptchaScript.src =
        'https://www.google.com/recaptcha/api.js?render=explicit';
      recaptchaScript.onload = () => {
        this.setRecaptchaLoaded(true);
        resolve(true);
      };
      lastScriptElement.parentNode.insertBefore(
        recaptchaScript,
        lastScriptElement
      );
    }).finally(() => {
      this.setRecaptchaPromise(null);
    });
    this.setRecaptchaPromise(promise);
    return promise;
  }

  @Mutation
  protected setRecaptchaLoaded(state: boolean) {
    this.recaptchaLoaded = state;
  }

  @Mutation
  protected setRecaptchaPromise(promise: Promise<boolean> | null) {
    this.recaptchaPromise = promise;
  }
}
