import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';

import appEnv from '~/app/core/appEnv';
import LanguageModule from '~/app/core/store/modules/LanguageModule';
import { defaultLocale, supportedLocales } from '~/app/localization';
import routes from './routes';

Vue.use(Router);

export function getLocaleFromRouter(router: Router): string {
  const { matched } = router.currentRoute;
  for (const routeRecord of matched) {
    if (routeRecord.meta.locale) {
      return routeRecord.meta.locale;
    }
  }

  return defaultLocale;
}

export function getPathWithoutLocale(router: Router): string {
  const locale = getLocaleFromRouter(router);
  const path = router.currentRoute.path;

  const regex = new RegExp(`^/${locale}`, 'g');
  return path.replace(regex, '');
}

// Generate language routes
const languageRoutes = supportedLocales.map(
  (locale): RouteConfig => {
    const languageConfig: RouteConfig = {
      path: `/${locale.slug}`,
      component: {
        render(c) {
          return c('router-view');
        },
      },
      meta: {
        locale: locale.slug,
      },
    };

    languageConfig.children = [];

    for (const route of routes) {
      languageConfig.children.push(route);
    }

    return languageConfig;
  }
);

export default function(store: Store<any>) {
  return new Router({
    mode: 'history',
    linkExactActiveClass: 'active',
    base: '/',
    routes: [
      ...languageRoutes,
      {
        path: '*',
        redirect: () => {
          const path = `/${getModule(LanguageModule, store).initialLanguage}`;
          // We need a callback here as the initial language is replaced only after the app is created
          return {
            path,
            query: {
              temporaryRedirect: appEnv.PUBLIC_WEB_URL + path,
            },
          };
        },
      },
    ],
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        if (document) {
          const anchor = document.querySelector(to.hash) as HTMLElement;
          if (anchor) {
            const scrollTo = Math.round(
              anchor.getBoundingClientRect().top + window.scrollY
            );
            return window.scrollTo({ top: scrollTo, behavior: 'smooth' });
          }
        }

        return {
          selector: to.hash,
        };
      }

      return savedPosition
        ? savedPosition
        : { x: 0, y: from.meta.scrollY || 0 };
    },
  });
}
