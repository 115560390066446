import { Component, Prop } from 'vue-property-decorator';
import { LinkTarget, link } from '~/utils/molecules';
import { VueComponent } from '~/utils/vue-component';

import style from './Button.scss';

export interface ButtonInterface {
  buttonStyle?: string;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  onClick?: () => void;
  submit?: boolean;
  thin?: boolean;
  target?: LinkTarget;
  url?: string;
}

export enum Style {
  PRIMARY = '',
  PRIMARY_LIGHT = 'czt-button--light',
  SECONDARY = 'czt-button--secondary',
  SECONDARY_LIGHT = 'czt-button--secondary czt-button--light',
}

const rootClass = 'czt-button';

@Component({
  style,
})
export default class Button extends VueComponent<ButtonInterface>
  implements ButtonInterface {
  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  @Prop({ default: Style.PRIMARY, type: String })
  public buttonStyle!: Style;

  @Prop({ default: false, type: Boolean })
  public submit!: boolean;

  @Prop({ default: false, type: Boolean })
  public thin!: boolean;

  @Prop({ default: '', type: String })
  public url!: string;

  @Prop({ default: '', type: String })
  public target!: LinkTarget;

  protected get href(): string | undefined {
    return link.isInternal(this.url, this.$store) ? undefined : this.url;
  }

  protected get to(): string | undefined {
    return link.isInternal(this.url, this.$store)
      ? link.createInternal(this.url, this.$i18n.locale)
      : undefined;
  }

  public render() {
    const classes = [rootClass, this.buttonStyle];
    if (this.thin) {
      classes.push(rootClass + '--thin');
    }
    return (
      <v-btn
        class={classes.join(' ')}
        disabled={this.disabled}
        loading={this.loading}
        href={this.href}
        to={this.to}
        target={this.target}
        onClick={() => this.$emit('click')}
        type={this.submit ? 'submit' : 'button'}
      >
        {this.$slots.default}
      </v-btn>
    );
  }
}
