import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class CalendarIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--calendar'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 20 20'
        >
          <defs />
          <path
            fill='currentColor'
            d='M12.824 2.336v-.64a.697.697 0 011.391 0v.64h.677a2.47 2.47 0 012.468 2.468v10.424a2.47 2.47 0 01-2.468 2.468H4.468A2.469 2.469 0 012 15.228V4.804a2.469 2.469 0 012.468-2.468h.676v-.64a.697.697 0 011.391 0v.64h6.29zm3.144 4.75H3.391v8.142c0 .594.482 1.077 1.077 1.077h10.424c.594 0 1.076-.483 1.076-1.077V7.087zM5.514 13.123a.955.955 0 010 1.909.954.954 0 010-1.91zm2.88 0a.955.955 0 010 1.909.954.954 0 010-1.91zm2.88 0a.955.955 0 010 1.909.954.954 0 010-1.91zm-5.76-2.56a.955.955 0 010 1.909.954.954 0 010-1.91zm2.88 0a.955.955 0 010 1.909.954.954 0 010-1.91zm2.88 0a.955.955 0 010 1.909.954.954 0 010-1.91zm2.88 0a.955.955 0 010 1.909.954.954 0 010-1.91zm-5.76-2.56a.955.955 0 010 1.909.954.954 0 010-1.91zm2.88 0a.955.955 0 010 1.909.954.954 0 010-1.91zm2.88 0a.955.955 0 010 1.909.954.954 0 010-1.91zm.061-4.275v.529a.696.696 0 11-1.39 0v-.53h-6.29v.53a.696.696 0 11-1.39 0v-.53h-.677c-.595 0-1.077.484-1.077 1.078v.892h12.577v-.892c0-.594-.482-1.077-1.076-1.077h-.677z'
          />
        </svg>
      </i>
    );
  }
}
