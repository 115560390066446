import { Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';

@Module({
  name: 'DomainModule',
  stateFactory: true,
  namespaced: true,
})
export default class DomainModule extends AbstractModule {
  public hostname: string | null = null;

  public canonicalHostname: string | null = null;

  @Mutation
  public setHostname(hostname: string | null) {
    if (!hostname) {
      this.hostname = null;
      this.canonicalHostname = null;
      return;
    }

    const portPosition = hostname.indexOf(':');
    if (portPosition > 0) {
      // Strip port from the domain
      hostname = hostname.substr(0, portPosition);
    }

    this.hostname = hostname;

    if (hostname.indexOf('www.') === 0) {
      this.canonicalHostname = hostname.substr(4);
    } else {
      this.canonicalHostname = hostname;
    }
  }
}
