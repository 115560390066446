import { Component, Prop, Emit } from 'vue-property-decorator';
import { ValueProp, VueComponent } from '~/utils/vue-component';

import style from './RadioGroup.scss';

export interface RadioItem {
  value: string | number;
  text: string;
  disabled?: boolean;
}

interface RadioGroupInterface extends ValueProp<string | number> {
  items: RadioItem[];
  disabled?: boolean;
  readOnly?: boolean;
}

@Component({
  style,
})
export default class RadioGroup extends VueComponent<RadioGroupInterface>
  implements RadioGroupInterface {
  @Prop({ required: true, type: Array })
  public items!: RadioItem[];

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public readOnly!: boolean;

  @Prop({ default: '' })
  public value!: string | number;

  public render() {
    return (
      <v-radio-group
        class='czt-radio-group'
        disabled={this.disabled}
        readonly={this.readOnly}
        value={this.value}
        onChange={this.change}
      >
        {this.getItems()}
      </v-radio-group>
    );
  }

  protected getItems(): JSX.Element[] {
    return this.items.map((item: RadioItem, key) => {
      return (
        <v-radio
          key={key.toString() + item.value}
          label={item.text}
          value={item.value}
          disabled={item.disabled}
        />
      );
    });
  }

  @Emit('input')
  protected change(value: string | number) {
    return value;
  }
}
