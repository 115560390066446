export default {
  app: {
    venues: {
      booking: {
        title: 'Nezávazná poptávka',
        startDate: 'Datum od *',
        startTime: 'Čas od',
        endDate: 'Datum do',
        endTime: 'Čas do',
        continue: 'Pokračovat',
        submit: 'Odeslat poptávku',
        privacy:
          'Poptávky jsou zpracovány společností {0} a zaslány přímo do vybraného prostoru. Nejsou potřeba žádné informace o kreditní kartě a žádné poplatky.',
        meatspace: 'Meatspace',
        capacity: 'Počet hostů *',
        email: 'Email *',
        phone: 'Telefon (s předvolbou) *',
        company: 'Společnost',
        note: 'Poznámka',
        name: 'Jméno a příjmení',
        meatspaceUrl: 'https://www.meatspace.cz/',
        type: 'Typ události',
        fluidDate: 'Datum se může změnit',
        error: {
          noId: 'Chybí ID prostoru',
          server:
            'Při zpracování vašeho požadavku nastala chyba, prosím, zkuste to později.',
          invalidEmail: 'Prosím, vyplntě validní email',
          noHosts: 'Prosím, vyplňte počet hostů',
          noDateStart: 'Prosím, vyplňte datum začátku události',
          invalidPhone:
            'Prosím, vyplňte validní telefonní číslo (s předvolbou země)',
          shortEvent: 'Prosím, vyberte validní data a časy',
        },
        success: 'Vaše poptávka byla úspěšně zpracována',
      },
      detailButton: 'Venue Detail',
      detail: {
        link: 'Odkaz na stránku prostoru',
        info: 'Informace',
        subvenues: 'Podprostory',
        equipment: 'Vybavení',
      },
      location: 'Lokalita',
      maxCapacity: 'Maximální kapacita',
      minCapacity: 'Minimální kapacita',
      noResults: 'Nenalezen žádný prostor',
      search: 'Hledat prostory',
      submit: 'Hledat',
      tags: 'Typ události',
    },
  },
};
