import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { RadioItem } from '~/components/atoms/radioGroup/RadioGroup';
import { RadioGroup } from '~/components/atoms';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected radioGroupItems: RadioItem[] = [
    { value: 1, text: 'RadioGroup item 1' },
    { value: 2, text: 'RadioGroup item 2' },
    { value: 3, text: 'RadioGroup item 3 (disabled)', disabled: true },
    { value: 4, text: 'RadioGroup item 4' },
  ];
  protected disabled: boolean = false;
  protected readOnly: boolean = false;
  protected selectedValue: number = 1;

  public getContent() {
    return [
      <div style='padding-top: 20px; padding-left: 20px;'>
        <RadioGroup
          items={this.radioGroupItems}
          disabled={this.disabled}
          readOnly={this.readOnly}
          v-model={this.selectedValue}
        />
        Selected value: {this.selectedValue}
      </div>,
    ];
  }
  public getSettings() {
    return [
      <v-switch label='Read only' color='accent' v-model={this.readOnly} />,
      <v-switch label='Disabled' color='accent' v-model={this.disabled} />,
    ];
  }
}
