import { Locale } from 'vue-i18n';
import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import DomainModule from '~/app/core/store/modules/DomainModule';

export enum Target {
  BLANK = '_blank',
  SELF = '_self',
  TOP = '_top',
  PARENT = '_parent',
}

export function isTargetEnum(data: any): data is Target {
  for (const value in Target) {
    if (Target.hasOwnProperty(value)) {
      if (data === (Target as any)[value]) {
        return true;
      }
    }
  }

  return false;
}

export function fixInternal(url: string): string {
  return url.indexOf('~') === 0 ? url.substr(1) : url;
}

export function createInternal(url: string, locale: Locale): string {
  return '/' + locale + fixInternal(url);
}

export function createExternal(url: string) {
  const regex = /(http(?:s)?\:\/\/|mailto\:|tel\:)/gi;
  const matches = regex.exec(url);
  return matches && matches[1] ? url : `https://${url}`;
}

export function isInternal(link: string, store: Store<any>): boolean {
  // Strip whitespace
  const url = link.trim();

  // If it's absolute, then it's definitely internal
  if (url.indexOf('/') === 0 || url.indexOf('~/') === 0) {
    return true;
  }

  return false;
}
