import { Component, Prop } from 'vue-property-decorator';

import style from './Brochure.scss';
import { GridItem } from '~/components/organisms/gridList/GridList';
import { VueComponent } from '~/utils/vue-component';
import { Image } from '~/components/atoms';
import { ImageWrapper } from '~/components/molecules';
import { Link } from '~/components/molecules';
import { ThemeRatios } from '~/utils/theme';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';

interface BrochureGridItemInterface {
  imageRatio?: ThemeRatios;
  item: GridItem;
}

const rootClass = 'czt-grid-list__item';

@Component({
  style,
})
export default class BrochureGridItem
  extends VueComponent<BrochureGridItemInterface>
  implements BrochureGridItemInterface {
  @Prop()
  public imageRatio?: ThemeRatios;

  @Prop({ required: true })
  public item!: GridItem;

  public render() {
    const sizes: Sizes[] = [
      {
        minWidth: 1008,
        size: 401,
        unit: 'px',
      },
      {
        minWidth: 768,
        size: 377,
        unit: 'px',
      },
      {
        size: 512,
        unit: 'px',
      },
    ];

    return (
      <v-col
        class={`${rootClass} ${rootClass}--brochure text-center`}
        cols={6}
        md={4}
        lg={3}
      >
        <Link url={this.item.link}>
          {(() => {
            if (this.imageRatio) {
              return (
                <ImageWrapper
                  image={this.item.image}
                  ratio={this.imageRatio}
                  sizes={sizes}
                />
              );
            } else {
              return (
                <Image
                  src={this.item.image.src}
                  alt={this.item.title}
                  sizes='(min-width: 1008px) 370px, (min-width: 768px) 33vw, 50vw'
                />
              );
            }
          })()}
          <div class={`${rootClass}__brochure-title`}>{this.item.title}</div>
        </Link>
      </v-col>
    );
  }
}
