import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class MuteIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--mute'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path d='M3.3 6.4H0v7.2h3.3l6.1 4.5s1.1 1 1.1 0V1.7c0-.7-1 0-1 0L3.3 6.4zm15.8 7.1c-.1 0-.3-.1-.4-.1l-5.9-5.9a.6.6 0 010-.8c.2-.2.6-.2.8 0l5.9 5.9c.2.2.2.6 0 .8-.2 0-.3.1-.4.1z' />
          <path d='M19.1 13.9c-.2 0-.5-.1-.7-.3l-5.9-5.9c-.4-.4-.4-1 0-1.3.4-.4 1-.4 1.3 0l5.9 5.9c.4.4.4 1 0 1.3-.2.2-.4.3-.6.3zm-5.9-7c-.1 0-.1 0-.1.1-.1.1-.1.2 0 .3l5.9 5.9c.1.1.1.1.3 0 .1-.1.1-.2 0-.3L13.4 7l-.2-.1z' />
          <path d='M13.2 13.5c-.1 0-.3-.1-.4-.1a.6.6 0 010-.8l5.9-5.9c.2-.2.6-.2.8 0 .2.2.2.6 0 .8l-5.9 5.9c-.1 0-.3.1-.4.1z' />
          <path d='M13.2 13.9c-.2 0-.5-.1-.7-.3-.1-.1-.3-.4-.3-.7s.1-.5.3-.7l5.9-5.9c.4-.4 1-.4 1.3 0 .4.4.4 1 0 1.3l-5.9 5.9c-.1.3-.4.4-.6.4zm5.9-7c-.1 0-.1 0-.1.1l-6 5.8-.1.1s0 .1.1.1c.1.1.2.1.3 0l5.9-5.9c.1-.1.1-.2 0-.3l-.1.1z' />
        </svg>
      </i>
    );
  }
}
