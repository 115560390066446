import ClientPrefetch from './ClientPrefetch';
import ServerPrefetch from './ServerPrefetch';

export function callPrefetch(vm: PrefetchComponent): Promise<any> {
  const { prefetch } = vm;
  let promise = Promise.resolve();

  if (typeof prefetch === 'function') {
    promise = prefetch();
    if (promise.constructor.name !== 'Promise') {
      promise = Promise.resolve();
    }
  }

  return promise;
}

interface PrefetchComponent {
  loadingPrefetchData: boolean;
  prefetch?(): Promise<any>;
}

interface ServerPrefetchComponent extends PrefetchComponent {
  prefetchPromise: null | Promise<any>;
}

function isPrefetchComponent(data: any): data is PrefetchComponent {
  return typeof data.loadingPrefetchData !== 'undefined';
}

function isServerPrefetchComponent(data: any): data is ServerPrefetchComponent {
  return typeof data.prefetchPromise !== 'undefined';
}

const Prefetch =
  process.env.VUE_ENV === 'server' ? ServerPrefetch : ClientPrefetch;

export {
  isPrefetchComponent,
  isServerPrefetchComponent,
  Prefetch,
  PrefetchComponent,
  ServerPrefetchComponent,
};
