export default {
  app: {
    safeTravels: {
      search: 'Název',
      categories: 'Kategorie',
      button: 'Hledat',
      table: {
        name: 'Název provozovny',
        link: 'Webové stránky',
      },
    },
  },
};
