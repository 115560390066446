import { Component, Prop } from 'vue-property-decorator';

import { Headline } from '~/components/atoms';
import { Align, LineColor } from '~/components/atoms/headline/Headline';
import { ImageInterface } from '~/components/atoms/image/Image';
import { VideoInterface } from '~/components/atoms/video/Video';
import { MediaBackground } from '~/components/molecules';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';
import { ImageFilterMixin, ImageFilterMixinInterface } from '~/mixins';
import { sidebarColumns } from '~/utils/sidebarColumns';
import { ThemeRatios } from '~/utils/theme';
import { VueComponentMixin } from '~/utils/vue-component';

import style from './Banner.scss';

export interface BannerInterface extends ImageFilterMixinInterface {
  backgroundImage: ImageInterface;
  title: string;
  ratio?: ThemeRatios;
  showVideoControls?: boolean;
  slim?: boolean;
  slots?: {
    superTitle?: JSX.Element[] | JSX.Element | string | null | undefined;
    sideContent?: JSX.Element[] | JSX.Element | string | null | undefined;
    socialButtons?: JSX.Element[] | JSX.Element | string | null | undefined;
  };
  titleLevel?: number;
  titleInColumn?: boolean;
  video?: VideoInterface;
}

const sizes: Sizes[] = [
  {
    size: 100,
    unit: 'vw',
  },
];

@Component({
  style,
})
export default class Banner
  extends VueComponentMixin<BannerInterface, ImageFilterMixinInterface>(
    ImageFilterMixin
  )
  implements BannerInterface {
  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({ required: true })
  public backgroundImage!: ImageInterface;

  @Prop({ default: 2, type: Number })
  public titleLevel?: number;

  @Prop()
  public video?: VideoInterface;

  @Prop({ default: false, type: Boolean })
  public showVideoControls!: boolean;

  @Prop()
  public slim?: boolean;

  @Prop({ default: false, type: Boolean })
  public titleInColumn?: boolean;

  @Prop()
  public ratio?: ThemeRatios;

  protected rootClass: string = 'czt-banner';

  public render() {
    const columnClasses: string[] = [
      `${this.rootClass}__content__column`,
      'pt-0 py-md-0',
    ];
    if (!this.$slots.sideContent) {
      columnClasses.push(`${this.rootClass}__content__column--single`);
    }
    return (
      <v-card
        class={`${this.rootClass} ${
          this.slim ? `${this.rootClass}--slim` : ''
        }`}
        flat
        tile
      >
        <MediaBackground
          image={this.backgroundImage}
          imageFilter={this.imageFilter}
          video={this.video}
          sizes={sizes}
          ratio={this.ratio}
          showVideoControls={this.showVideoControls}
        >
          <v-container class={`${this.rootClass}__content`}>
            <v-row
              class={`${this.rootClass}__content__row`}
              align='center'
              justify='center'
            >
              <v-col class={`${this.rootClass}__content__row__col`}>
                {(() => {
                  if (!this.titleInColumn) {
                    return [
                      <v-row>
                        <v-col class='py-0'>
                          {this.getSuperTitle()}
                          {this.getTitle()}
                        </v-col>
                      </v-row>,
                      <v-row>
                        <v-col class={columnClasses.join(' ')}>
                          <div class={`${this.rootClass}__content__slots`}>
                            {this.$slots.default}
                          </div>
                        </v-col>
                        {this.getSideContent()}
                      </v-row>,
                    ];
                  } else {
                    return (
                      <v-row>
                        <v-col class={columnClasses.join(' ')}>
                          {this.getSuperTitle()}
                          {this.getTitle()}
                          <div class={`${this.rootClass}__content__slots`}>
                            {this.$slots.default}
                          </div>
                        </v-col>
                        {this.getSideContent()}
                      </v-row>
                    );
                  }
                })()}
              </v-col>
            </v-row>
            {this.getSocialButtons()}
          </v-container>
        </MediaBackground>
      </v-card>
    );
  }

  protected getTitle() {
    const headAlign = this.$slots.sideContent ? Align.LEFT : Align.CENTER;
    return (
      this.title.length > 0 && (
        <Headline
          underscore
          light
          align={headAlign}
          level={this.titleLevel}
          lineColor={LineColor.WHITE}
        >
          <HtmlRenderer
            content={this.title}
            light={
              this.backgroundImage.src || this.video?.videoId ? true : false
            }
          />
        </Headline>
      )
    );
  }

  protected getSuperTitle(): JSX.Element | void {
    const superClasses: string[] = [`${this.rootClass}__supertitle`];
    if (!this.$slots.sideContent) {
      superClasses.push(`${this.rootClass}__supertitle--centered`);
    }
    if (this.$slots.superTitle) {
      return <div class={superClasses.join(' ')}>{this.$slots.superTitle}</div>;
    }
  }
  protected getSideContent(): JSX.Element | void {
    if (this.$slots.sideContent) {
      return (
        <v-col
          class={`${this.rootClass}__content__column py-md-0`}
          {...{ attrs: sidebarColumns }}
        >
          {this.$slots.sideContent}
        </v-col>
      );
    }
    return;
  }

  protected getSocialButtons(): JSX.Element | void {
    if (this.$slots.socialButtons) {
      return (
        <div class={`${this.rootClass}__social-buttons`}>
          {this.$slots.socialButtons}
        </div>
      );
    }
    return;
  }
}
