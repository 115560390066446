export enum Colors {
  ANCHOR = '#178FCF',
  ANCHOR_HOVER = '#116B9B',
  TEXT = '#404040',
  TEXT_LIGHT = '#FFFFFF',
  HEADINGS = '#000000',
  PRIMARY = '#163F6E',
  ACCENT = '#FF0018',
  SECONDARY = '#178FCF',
}
