import { Action, Module, Mutation } from 'vuex-module-decorators';
import boolToNumber from '~/utils/boolToNumber';
import {
  COOKIE_NAME,
  COOKIE_DELIMITER,
  CookieCategory,
  COOKIES_ACCEPTED_EXPIRATION_TIME,
  COOKIES_REJECTED_EXPIRATION_TIME,
} from '~/utils/cookies/config';
import cookieCategoryValue from '~/utils/cookies/cookieCategoryValue';
import cztCookieExists from '~/utils/cookies/cookieExists';
import getCookieValue from '~/utils/cookies/getCookieValue';
import gtm from '~/utils/gtm';
import appEnv from '../../appEnv';

import AbstractModule from './AbstractModule';

export interface CookieSettings {
  statistics: boolean;
  marketing: boolean;
}

@Module({
  name: 'CookieSettingsModule',
  stateFactory: true,
  namespaced: true,
})
export default class CookieSettingsModule extends AbstractModule {
  protected gtmLoaded: boolean = false;

  public cookieBarVisible: boolean = false;
  public cookieExists: boolean = false;

  public statistics: boolean = false;
  public marketing: boolean = false;

  protected cookiePromise: Promise<boolean> | null = null;

  @Action({ rawError: true })
  public readCookie(cookiesString: string): Promise<boolean> {
    if (this.cookiePromise) {
      return this.cookiePromise;
    }
    const promise = new Promise<boolean>((resolve, reject) => {
      if (cztCookieExists(COOKIE_NAME, cookiesString)) {
        this.setCookieExists(true);
        this.setCookieBarVisible(false);
        const value = getCookieValue(COOKIE_NAME, cookiesString);
        if (value) {
          const categoryValues = value.split(COOKIE_DELIMITER);
          categoryValues.forEach((item) => {
            const category = item[0];
            switch (category) {
              case CookieCategory.STATISTICS:
                this.setStatistics(
                  cookieCategoryValue(item, CookieCategory.STATISTICS)
                );
                break;
              case CookieCategory.MARKETING:
                this.setMarketing(
                  cookieCategoryValue(item, CookieCategory.MARKETING)
                );
                break;
            }
          });
        }
        resolve(true);
      } else {
        this.setCookieExists(false);
        this.setCookieBarVisible(true);
        resolve(false);
      }
    });

    this.setPromise(promise);

    return promise;
  }

  @Action({ rawError: true })
  public loadGTM(): Promise<void> {
    if (!this.gtmLoaded && this.statistics) {
      gtm(appEnv.GTM_CONTAINER);
      this.setGTMLoaded(true);
    }
    return Promise.resolve();
  }

  @Action({ rawError: true })
  public saveCookie(cookieSettings: CookieSettings): Promise<void> {
    const date = new Date();
    const expiry: number =
      cookieSettings.marketing && cookieSettings.statistics
        ? COOKIES_ACCEPTED_EXPIRATION_TIME
        : COOKIES_REJECTED_EXPIRATION_TIME;
    date.setTime(date.getTime() + expiry);
    const cookie = [
      `${CookieCategory.REQUIRED}1`,
      `${CookieCategory.STATISTICS}${boolToNumber(cookieSettings.statistics)}`,
      `${CookieCategory.MARKETING}${boolToNumber(cookieSettings.marketing)}`,
    ].join(COOKIE_DELIMITER);
    document.cookie = `${COOKIE_NAME}=${cookie}; expires=${date.toUTCString()}`;
    this.setCookieExists(true);
    return this.setCookieSettings(cookieSettings).then((shouldReload) => {
      this.setCookieBarVisible(false);
      if (shouldReload) {
        window.location.reload();
        return Promise.resolve();
      }
      this.loadGTM();
      return Promise.resolve();
    });
  }

  @Action({ rawError: true })
  protected setCookieSettings(
    cookieSettings: CookieSettings
  ): Promise<boolean> {
    let reload = false;
    if (
      (!cookieSettings.statistics && this.statistics) ||
      (!cookieSettings.marketing && this.marketing)
    ) {
      reload = true;
    }
    this.setStatistics(cookieSettings.statistics);
    this.setMarketing(cookieSettings.marketing);
    return Promise.resolve(reload);
  }

  @Mutation
  public setCookieBarVisible(state: boolean) {
    this.cookieBarVisible = state;
  }

  @Mutation
  public setCookieExists(state: boolean) {
    this.cookieExists = state;
  }

  @Mutation
  protected setStatistics(state: boolean) {
    this.statistics = state;
  }

  @Mutation
  protected setMarketing(state: boolean) {
    this.marketing = state;
  }

  @Mutation
  protected setPromise(promise: Promise<boolean>) {
    this.cookiePromise = promise;
  }

  @Mutation
  protected setGTMLoaded(state: boolean) {
    this.gtmLoaded = state;
  }
}
