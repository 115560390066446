import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { LocaleMessage } from 'vue-i18n';
import { ThemeRatios } from '~/utils/theme';
import { ImageWrapper } from '..';
import { ImageInterface } from '~/components/atoms/image/Image';
import { Link } from '~/components/molecules';

import style from './SearchItem.scss';

export interface DateRange {
  start: string;
  end?: string;
}

function isDateRange(data: any): data is DateRange {
  if (data.end && typeof data.end !== 'string') {
    return false;
  }

  return typeof data.start === 'string';
}

export interface SearchItemInterface {
  dates?: DateRange;
  guid?: string;
  image: ImageInterface;
  location?: string;
  path: string;
  subtitle?: string;
  text?: string;
  title?: string;
  type?: string;
}

const rootClass = 'czt-search-item';

@Component({
  style,
})
export default class SearchItem extends VueComponent<SearchItemInterface>
  implements SearchItemInterface {
  @Prop({ validator: isDateRange })
  public dates?: DateRange;

  @Prop({ required: true })
  public image!: ImageInterface;

  @Prop({ type: String })
  public location?: string;

  @Prop({ required: true, type: String })
  public path!: string;

  @Prop({ type: String })
  public title?: string;

  @Prop({ type: String })
  public subtitle?: string;

  @Prop({ type: String })
  public text?: string;

  @Prop({ default: 'page' })
  public type!: string;

  protected get itemTitle(): string | LocaleMessage {
    if (this.title) {
      return this.title;
    }

    return this.$t(`app.common.pageTypes.${this.type}`);
  }

  public render() {
    return (
      <Link url={this.path} class={rootClass}>
        <v-card class={`${rootClass}__card`} elevation='0'>
          <v-row class={`${rootClass}__group`} justify='center'>
            <v-col class={`${rootClass}__content`} cols='12' md='12'>
              <div class={`${rootClass}__title`}>
                <h4 class='mb-1'>{this.itemTitle}</h4>
              </div>
              {this.subtitle && (
                <div class={`${rootClass}__subtitle`}>
                  <h4 class='mb-2'>{this.subtitle}</h4>
                </div>
              )}
              {this.text && (
                <div class={`${rootClass}__description`}>
                  <HtmlRenderer content={this.text} />
                </div>
              )}
            </v-col>
          </v-row>
          <v-divider />
        </v-card>
      </Link>
    );
  }
}
