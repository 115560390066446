import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import { Icons, Select } from '~/components/atoms';
import { IconName } from '~/components/atoms/icons/Icon';
import { ItemData } from '~/components/atoms/select/Select';

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected iconName: IconName = ['common', 'Search'];
  protected items: ItemData[] = [
    { value: 1, text: 'First item' },
    { value: 2, text: 'Second item' },
    { value: 3, text: 'Third item' },
    { value: 4, text: 'Fourth item' },
  ];
  protected selectedValue: string | number = this.items[0].value;
  protected disabled: boolean = false;
  protected readOnly: boolean = false;
  public getContent() {
    return [
      <div style='padding-top: 20px; text-align: center;'>
        <div style='width: 300px; margin: 0 auto;'>
          <Select
            iconName={this.iconName}
            items={this.items}
            v-model={this.selectedValue}
            placeholder='Select value'
            disabled={this.disabled}
            readonly={this.readOnly}
          />
        </div>
      </div>,
    ];
  }
  public getSettings() {
    const iconsList = [];
    for (const category in Icons) {
      if (!Icons.hasOwnProperty(category)) {
        continue;
      }
      const categoryIcons = (Icons as { [key: string]: any })[category];
      for (const iconKey in categoryIcons) {
        if (!categoryIcons.hasOwnProperty(iconKey)) {
          continue;
        }
        iconsList.push({ value: [category, iconKey], text: iconKey });
      }
    }
    return [
      <v-select
        items={iconsList}
        filled
        label='Generic icon type'
        v-model={this.iconName}
      />,
      <v-switch label='Disabled' color='accent' v-model={this.disabled} />,
      <v-switch label='Read only' color='accent' v-model={this.readOnly} />,
    ];
  }
}
