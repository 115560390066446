import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class MapPinIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--common czt-icon--map-pin'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 36 36'
        >
          <defs />
          <path
            fill='currentColor'
            d='M18.2 1.247c7.402 0 13.411 6.009 13.411 13.41 0 6.989-2.952 9.664-13.41 20.425C6.959 23.492 4.79 22.058 4.79 14.657c0-7.401 6.008-13.41 13.41-13.41z'
          />
        </svg>
      </i>
    );
  }
}
