import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import HeadManagement from '~/mixins/HeadManagement';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { getModule } from 'vuex-module-decorators';
import NavigationModule from '~/app/core/store/modules/NavigationModule';
import VenueModule from '~/app/core/store/modules/VenueModule';
import { ExtendedVenueDetail } from '~/utils/venueFinder/types';
import { NotFound, PageLoader } from '~/components/templates/common';
import VenueDetail from '~/components/templates/common/VenueDetail';

@Component
export default class Venue extends VueComponentMixin<{}, PrefetchComponent>(
  HeadManagement,
  Prefetch
) {
  protected get venueModule(): VenueModule {
    return getModule(VenueModule, this.$store);
  }

  protected get venue(): ExtendedVenueDetail | null {
    return this.venueModule.resource;
  }

  protected get venueId(): number {
    const id = parseInt(this.$route.params.id, 10);
    return id && !isNaN(id) ? id : 0;
  }

  public prefetch() {
    const venueModule = this.venueModule || getModule(VenueModule, this.$store);
    return venueModule
      .loadVenueById({
        id: this.venueId,
      })
      .then(() => {
        return getModule(NavigationModule, this.$store).load({
          locale: this.$i18n.locale,
          query: this.$route.query,
        });
      });
  }

  public render() {
    if (this.venueModule.loading) {
      return <PageLoader />;
    }
    if (this.venue === null) {
      return <NotFound />;
    }
    return <VenueDetail venue={this.venue} />;
  }
}
