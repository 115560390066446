import { Component, Prop } from 'vue-property-decorator';
import { Button, Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { VueComponentMixin } from '~/utils/vue-component';
import { CztWidgets } from '~/utils/views/widgets';

import style from './VenueFinder.scss';
import { GridItem } from '~/components/organisms/gridList/GridList';
import VenueFinderModule from '~/app/core/store/modules/VenueFinderModule';
import { getModule } from 'vuex-module-decorators';
import VenueFinderItem from '../venueFinder/VenueFinderItem';
import VenueFinderFilter from '../venueFinder/VenueFinderFilter';

export type VenueListItem = GridItem;

export interface VenueFinderInterface {
  className: CztWidgets;
  guid: string;
  isBottomSpacingCollapsed?: boolean;
  isTopSpacingCollapsed?: boolean;
  title: string;
}

export function isVenueFinder(data: any): data is VenueFinderInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.VENUE_FINDER &&
    typeof data.guid === 'string'
  );
}

const rootClass = 'czt-venue-finder';

@Component({
  style,
})
export default class VenueFinder
  extends VueComponentMixin<VenueFinderInterface, PrefetchComponent>(Prefetch)
  implements VenueFinderInterface {
  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({ required: true, type: String })
  public guid!: string;

  @Prop()
  public anchorId?: string;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  public className = CztWidgets.VENUE_FINDER;

  protected get venueFinderStore(): VenueFinderModule {
    return getModule(VenueFinderModule, this.$store);
  }

  public prefetch() {
    const venueFinderStore =
      this.venueFinderStore || getModule(VenueFinderModule, this.$store);
    return Promise.allSettled([
      venueFinderStore.search(),
      venueFinderStore.getOptions(),
    ]);
  }

  public render() {
    return <v-sheet class={rootClass}>{this.renderContent()}</v-sheet>;
  }

  protected renderContent(): JSX.Element {
    const containerClasses = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container id={this.anchorId} class={containerClasses.join(' ')}>
        <v-row>{this.renderHeadline()}</v-row>
        <VenueFinderFilter
          onFilter={this.venueFinderStore.search}
          filterOptions={{
            locations: this.venueFinderStore.locations,
            tags: this.venueFinderStore.types,
          }}
          loading={this.venueFinderStore.loading}
        />
        <v-row class='ma-0 pa-0'>
          <v-col class='py-0'>
            <v-row class={`${rootClass}__container`}>
              {this.venueFinderStore.venues.length < 1 ? (
                this.venueFinderStore.loading ? (
                  this.renderPlaceholder()
                ) : (
                  <v-col cols='12' class='text-center py-5'>
                    {this.$t('app.venues.noResults')}
                  </v-col>
                )
              ) : (
                this.renderVenueItems()
              )}
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class='text-center pt-3'>
              <Button
                disabled={!this.venueFinderStore.hasNextPage}
                onClick={this.venueFinderStore.loadMore}
                loading={this.venueFinderStore.loading}
              >
                {this.$t('app.common.loadMore')}
              </Button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    );
  }

  protected renderHeadline(): JSX.Element | undefined {
    return (
      <v-col class='py-0'>
        <Headline underscore align={Align.LEFT} level={2}>
          {this.title}
        </Headline>
      </v-col>
    );
  }

  protected renderVenueItems(): JSX.Element[] {
    return this.venueFinderStore.venues.map((venue) => {
      return <VenueFinderItem key={venue.id.toString()} venue={venue} />;
    });
  }

  protected renderPlaceholder(): JSX.Element[] {
    return [...Array(this.venueFinderStore.limit)].map(() => (
      <v-col cols={6} md={4}>
        <v-responsive aspect-ratio={1} height='100%'>
          <v-skeleton-loader type='image' />
        </v-responsive>
      </v-col>
    ));
  }
}
