import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

interface StatusCodeInterface {
  code: number;
}

/**
 * Hello mega hack, goodbye status code problems
 * @see server/main.js - hasStatusCode function
 */
@Component
export default class StatusCode extends VueComponent<StatusCodeInterface>
  implements StatusCodeInterface {
  @Prop({ required: true, type: Number })
  public code!: number;

  public render() {
    return <abbr id='ssr-status-code' data-code={this.code} />;
  }
}
