import { Component, Prop } from 'vue-property-decorator';
import { Breadcrumbs, Teaser } from '~/components/organisms';
import HeadManagement from '~/mixins/HeadManagement';
import { VueComponentMixin } from '~/utils/vue-component';
import { BasePage } from '~/app/core/apiClient/api';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { sidebarColumns } from '~/utils/sidebarColumns';
import { Button, Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { IconText, Link } from '~/components/molecules';
import { LinkTarget } from '~/utils/molecules';
import { EventtiaEventPage } from '~/utils/views';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';
import appEnv from '~/app/core/appEnv';
import { getLocaleFromRouter } from '~/app/core/router';
import TranslationVoting from '~/components/templates/common/TranslationVoting';
interface EventtiaEventView {
  data: EventtiaEventPage;
}
@Component
export default class EventtiaDetail
  extends VueComponentMixin<EventtiaEventView, {}>(HeadManagement)
  implements EventtiaEventView {
  @Prop({ required: true })
  public data!: EventtiaEventPage;

  public headTags(): string {
    return createSeoTags(
      `${this.data.title} · #CzechTourism`,
      this.data.perex,
      `${appEnv.PUBLIC_WEB_URL}/${getLocaleFromRouter(this.$router)}${
        this.data.canonicalPath
      }`,
      TYPE.ARTICLE,
      this.data.image
    );
  }

  public render() {
    return (
      <v-container fluid class='pa-0' key={this.data ? this.data.guid : 0}>
        <Teaser
          backgroundImage={
            this.data.image && !this.data.hideTeaserImage
              ? { src: this.data.image, alt: this.data.title }
              : null
          }
          imageFilter={BasePage.ImageFilterTypeEnum.Softdarken}
          title={`${this.data.title}`}
        >
          {this.renderTeaserContent()}
        </Teaser>

        <v-container class='py-1'>
          <v-row justify='center'>
            <v-col cols={12} md={12} lg={8} class='py-0'>
              <Breadcrumbs breadcrumbs={this.data.breadcrumbs} />
            </v-col>
          </v-row>
        </v-container>

        {this.renderContent()}
      </v-container>
    );
  }

  protected renderTeaserContent(): JSX.Element | undefined {
    if (!this.data || !this.data.date) {
      return;
    }
    return <div class='czt-date'>{this.data.date}</div>;
  }

  protected renderContent(): JSX.Element | undefined {
    if (!this.data) {
      return;
    }
    return (
      <v-container class='czt-spacer'>
        <v-row justify='center'>
          <v-col cols={12} md={7} lg={8}>
            {(() => {
              if (this.data.content) {
                return (
                  <div class='czt-richtext'>
                    <HtmlRenderer content={this.data.content} />
                  </div>
                );
              }
            })()}
            {(() => {
              if (this.data.showAutomatedTranslationVoting) {
                return (
                  <TranslationVoting
                    locale={this.$i18n.locale}
                    message={this.data.automatedTranslationMessage}
                    pageId={this.data.guid}
                  />
                );
              }
            })()}
          </v-col>
          {this.renderSideContent()}
        </v-row>
      </v-container>
    );
  }

  protected renderSideContent(): JSX.Element | undefined {
    if (!this.data) {
      return;
    }
    return (
      <v-col {...{ attrs: sidebarColumns }}>
        <div class='czt-side-content pt-0'>
          <Headline underscore level={3} class='pb-1' align={Align.LEFT}>
            {this.$t('app.event.info')}
          </Headline>
          {(() => {
            if (this.data.virtual) {
              return [<div>{this.$t('app.event.virtual')}</div>, <br />];
            }
            if (this.data.address && !this.data.virtual) {
              return [
                <HtmlRenderer content={this.data.address} class='pb-1' side />,
                <br />,
              ];
            }
          })()}
          {(() => {
            if (
              this.data.latitude &&
              this.data.longitude &&
              !this.data.virtual
            ) {
              return [
                <div class='pt-2'>
                  <Link
                    url={`https://www.google.com/maps/search/?api=1&query=${this.data.latitude},${this.data.longitude}`}
                    target={LinkTarget.BLANK}
                  >
                    <IconText icon={['common', 'MapPin']}>
                      {this.$t('app.place.showOnExternalMaps')}
                    </IconText>
                  </Link>
                </div>,
                <br />,
              ];
            }
          })()}
          {(() => {
            if (this.data.website && this.data.website.trim() !== '') {
              return (
                <div>
                  <Button url={this.data.website}>
                    {this.$t('app.event.link')}
                  </Button>
                </div>
              );
            }
          })()}
        </div>
      </v-col>
    );
  }
}
