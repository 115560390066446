import { Component } from 'vue-property-decorator';

import { GalleryWidget } from '~/app/core/apiClient/api';
import { Gallery } from '~/components/organisms';
import { GalleryItemInterface } from '~/components/molecules/galleryItem/GalleryItem';
import { CztWidgets } from '~/utils/views/widgets';
import { VueComponentMixin } from '~/utils/vue-component';

import ComponentBase, { ComponentBaseType } from '../ComponentBase';
import WidgetStyleEnum = GalleryWidget.WidgetStyleEnum;

@Component
export default class extends VueComponentMixin<{}, ComponentBaseType>(
  ComponentBase
) {
  protected galleryItems: GalleryItemInterface[] = [
    {
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      subtitle: 'First',
      image: {
        src: 'http://picsum.photos/1920/1080',
        alt: 'Wide Picture',
      },
    },
    {
      title: 'Quisque accumsan mauris leo, sed bibendum elit blandit eget.',
      subtitle: 'Second title',
      image: {
        src: 'http://picsum.photos/1920/1080',
        alt: 'Wide Picture',
      },
    },
    {
      title:
        'Aliquam erat volutpat. Donec sollicitudin libero eu faucibus finibus.',
      subtitle: 'Third title',
      image: {
        src: 'http://picsum.photos/1920/1080',
        alt: 'Wide Picture',
      },
    },
    {
      title: 'Donec sollicitudin libero eu faucibus finibus.',
      subtitle: 'Fourth title',
      image: {
        src: 'http://picsum.photos/1920/1080',
        alt: 'Wide Picture',
      },
    },
  ];

  public getContent() {
    return [
      <Gallery
        className={CztWidgets.GALLERY}
        items={this.galleryItems}
        galleryStyle='slider'
      />,
    ];
  }
  public getSettings() {
    return [];
  }
}
